import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { truncateAddress } from '@/utils/formatters'

type AddressCellProps = {
  value: string
  truncate?: number
}

const AddressCell: FC<AddressCellProps> = ({ value, truncate }) => {
  // TODO: add tooltip with full address or copy button?
  return (
    <Typography variant="body3" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
      {truncate ? truncateAddress(value, truncate) : value}
    </Typography>
  )
}

export default AddressCell
