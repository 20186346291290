import { FC, useCallback, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { AdminRoles } from '@keo/shared-types'
import { AddAdminUserParams } from '@/types'
import { FormInput } from '@/components'
import { addAdminUser } from '@/api/admin-users'
import Modal from './components/Modal'
import Actions from './components/Actions'
import SelectFilter from '../forms/SelectFilter'
import { useUser } from '@/utils/session'

const AddAdminUserSchema = yup.object({
  name: yup.string().trim().required(),
  email: yup.string().trim().email().required()
})

type AddAdminUserProps = {
  onClose: () => void
  onSubmit: () => void
}

type EligibleAdminRoles = AdminRoles.PROCESSOR_ADMIN | AdminRoles.LENDER_ADMIN;

const adminUserRoles = [ AdminRoles.PROCESSOR_ADMIN, AdminRoles.PROCESSOR, AdminRoles.LENDER_ADMIN, AdminRoles.LENDER ]
const lenderUserRoles = [ AdminRoles.LENDER_ADMIN, AdminRoles.LENDER ]

const roleOptionMapper: Record<EligibleAdminRoles, AdminRoles[]> = {
  [ AdminRoles.PROCESSOR_ADMIN ]: adminUserRoles,
  [ AdminRoles.LENDER_ADMIN ]: lenderUserRoles,
}

const AddAdminUser: FC<AddAdminUserProps> = ({ onClose, onSubmit }) => {
  const { adminRole } = useUser()
  const { t } = useTranslation([ 'common', 'admin-users' ])
  const { enqueueSnackbar } = useSnackbar()
  const [ error, setError ] = useState(false)
  const methods = useForm<AddAdminUserParams>({
    resolver: yupResolver(AddAdminUserSchema),
    defaultValues: {
      name: '',
      email: '',
      role: AdminRoles.PROCESSOR_ADMIN
    }
  })

  const roleOptions = useMemo(() => {
    return roleOptionMapper[ adminRole as EligibleAdminRoles ].map((role) => ({
      label: t(`admin-users:role.${role}`),
      value: role
    }))
  }, [ adminRole ])

  const { mutate } = useMutation(addAdminUser, {
    onSuccess: (d) => {
      enqueueSnackbar(t('modal.add.success', { email: d.email, ns: 'admin-users' }), { variant: 'success' })
      onSubmit()
      onClose()
    },
    onError: () => {
      setError(true)
    }
  })

  const handleAddAdminUser = useCallback((values: AddAdminUserParams) => {
    setError(false)
    mutate(values)
  }, [])

  const handleCancel = useCallback(() => {
    setError(false)
    onClose()
  }, [])

  return (
    <Modal
      open
      onClose={onClose}
      title={t('modal.add.title', { ns: 'admin-users' })}
      maxWidth="xs"
      actions={
        <Actions
          onCancel={handleCancel}
          onCancelLabel={t('cancel')}
          onSubmit={methods.handleSubmit(handleAddAdminUser)}
          onSubmitLabel={t('save')}
        />
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleAddAdminUser)}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <FormInput control={methods.control} name="name" label={t('name')}/>
            </Grid>
            <Grid item>
              <FormInput control={methods.control} name="email" label={t('email')}/>
            </Grid>
            <Grid item>
              <SelectFilter
                label={t('role')}
                fieldName="role"
                options={roleOptions}
                size="medium"
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {error && (
        <Box mt={1}>
          <Typography color="error" variant="caption">{t('modal.add.error', { ns: 'admin-users' })}</Typography>
        </Box>
      )}
    </Modal>
  )
}

export default AddAdminUser
