import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import WidgetSkeleton from './WidgetSkeleton'

type WidgetContentProps = {
  children: ReactNode
  hasData?: boolean
  height?: number
  isLoading?: boolean
}

const WidgetContent: FC<WidgetContentProps> = ({ children, hasData, height, isLoading }) => {
  const { t } = useTranslation('common')
  if (isLoading) {
    return <WidgetSkeleton height={height} />
  }

  if (!hasData) {
    return (
      <Typography
        textAlign="center"
        variant="body3"
        color="GrayText"
        sx={{ display: 'block', py: 3 }}>
        {t('dataNotAvailable')}
      </Typography>
    )
  }

  return <>{children}</>
}

export default WidgetContent