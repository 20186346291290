import { useContext, useEffect } from 'react'
import { RouteObject, useNavigate } from 'react-router-dom'
import loadable from '@loadable/component'
import {
  Env,
  getAccountOutLink,
  getApplicationOutLink,
  getAssetOutLink,
  getTransactionOutLink
} from '@keo/frontend/utils/url'
import { AdminRoles } from '@keo/shared-types'
import { useUser } from '@/utils/session'
import { NavigationGuardContext, NavigationGuardContextData } from '@/components/NavigationGuardProvider'

export enum urls {
  ACCOUNT = '/accounts/:id',
  ACCOUNTS = '/accounts',
  DASHBOARD = '/',
  INVOICES = '/invoices',
  LOGIN = '/auth/login',
  RESET_PASSWORD = '/auth/reset-password',
  SET_PASSWORD = '/auth/set-password',
  SETTINGS = '/settings',
  TRANSACTIONS = '/transactions',
  WALLET = '/wallets/:id',
  WALLETS = '/wallets',
  // Settings subpages
  ADMIN_USERS = '/settings/admin-users',
  API_KEYS = '/settings/api-keys',
  AUDIT_LOGS = '/settings/audit-logs',
  SYSTEM = '/settings/system',
  PAYBACK_STRATEGIES = '/settings/payback-strategies',
  PROCESSING_FEE_STRATEGIES = '/settings/processing-fee-strategies',
  WEBHOOKS = '/settings/webhooks',
  GENERAL_SETTINGS = '/settings/general',
  LOANS_OOS_REPORT = '/settings/loans-oos-report',
  LENDING_POOL_MANAGEMENT = '/settings/lending-pool-management',
  LOANS = '/loans',
  REPORTS = '/reports',
  NOT_FOUND = '/not-found',
}

const Login = loadable(() => import('@/containers/auth/Login'))
const ResetPassword = loadable(() => import('@/containers/auth/ResetPasswordContainer'))
const SetPassword = loadable(() => import('@/containers/auth/SetPasswordContainer'))
const ProtectedRoute = loadable(() => import('@/components/Protected/ProtectedRoute'))
const Dashboard = loadable(() => import('@/containers/dashboard/Dashboard'))
const Wallets = loadable(() => import('@/containers/wallets/Wallets'))
const Wallet = loadable(() => import('@/containers/wallets/Wallet'))
const Accounts = loadable(() => import('@/containers/accounts/Accounts'))
const Account = loadable(() => import('@/containers/accounts/Account'))
const Invoices = loadable(() => import('@/containers/invoices/Invoices'))
const Transactions = loadable(() => import('@/containers/Transactions'))
const Settings = loadable(() => import('@/containers/settings/Settings'))
const ApiKeys = loadable(() => import('@/components/ApiKeysTable'))
const AuditLogs = loadable(() => import('@/components/AuditLogs'))
const System = loadable(() => import('@/components/System'))
const PaybackStrategies = loadable(() => import('@/components/PaybackStrategies'))
const ProcessingFeeStrategies = loadable(() => import('@/components/ProcessingFeeStrategies'))
const AdminUsers = loadable(() => import('@/containers/admin-users/AdminUsers'))
const NotFoundPage = loadable(() => import('@/containers/NotFoundPage'))
const Webhooks = loadable(() => import('@/components/Webhooks'))
const GeneralSettingsForm = loadable(() => import('@/components/GeneralSettingsForm'))
const NavigationGuardProvider = loadable(() => import('@/components/NavigationGuardProvider'))
const LoansOOSReport = loadable(() => import('@/components/LoansOOSReport/LoansOOSReport'))
const Loans = loadable(() => import('@/containers/Loans'))
const LendingPoolManagement = loadable(() => import('@/components/LendingPoolManagement/LendingPoolManagement'))
const Reports = loadable(() => import('@/containers/Reports'))

const publicRoutes: RouteObject[] = [
  {
    element: <Login />,
    path: urls.LOGIN,
  },
  {
    element: <ResetPassword />,
    path: urls.RESET_PASSWORD,
  },
  {
    element: <SetPassword />,
    path: urls.SET_PASSWORD,
  },
]

const privateRoutes: RouteObject[] = [
  {
    element: <NavigationGuardProvider />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          {
            element: <Dashboard />,
            path: urls.DASHBOARD,
            index: true,
          },
          {
            path: urls.WALLETS,
            children: [
              {
                element: <Wallet />,
                path : urls.WALLET,
              },
              {
                element: <Wallets />,
                index: true,
              },
            ],
          },
          {
            path: urls.ACCOUNTS,
            children: [
              {
                element: <Account />,
                path: urls.ACCOUNT,
              },
              {
                element: <Accounts />,
                index: true,
              },
            ],
          },
          {
            path: urls.INVOICES,
            element: <Invoices />
          },
          {
            path: urls.LOANS,
            element: <Loans />
          },
          {
            path: urls.TRANSACTIONS,
            element: <Transactions />
          },
          {
            path: urls.REPORTS,
            element: <Reports />
          },
          {
            path: urls.SETTINGS,
            element: <Settings />,
            children: [
              {
                element: <ApiKeys />,
                path : urls.API_KEYS,
              },
              {
                element: <System />,
                path : urls.SYSTEM,
              },
              {
                element: <AdminUsers />,
                path : urls.ADMIN_USERS,
              },
              {
                element: <PaybackStrategies />,
                path : urls.PAYBACK_STRATEGIES,
              },
              {
                element: <ProcessingFeeStrategies />,
                path : urls.PROCESSING_FEE_STRATEGIES,
              },
              {
                element: <Webhooks />,
                path : urls.WEBHOOKS,
              },
              {
                element: <GeneralSettingsForm />,
                path : urls.GENERAL_SETTINGS,
              },
              {
                element: <AuditLogs />,
                path: urls.AUDIT_LOGS,
              },
              {
                element: <LoansOOSReport />,
                path : urls.LOANS_OOS_REPORT,
              },
              {
                element: <LendingPoolManagement />,
                path : urls.LENDING_POOL_MANAGEMENT,
              },
            ]
          },
        ],
      }
    ],

  },
]

const notFoundRoute: RouteObject = {
  element: <NotFoundPage />,
  path: '*',
}

export const routes = [ ...publicRoutes, ...privateRoutes, notFoundRoute ]

type AppHeaderLink = {
  url: urls,
  label: string
  strict?: boolean
  role?: AdminRoles[]
}

export const APP_HEADER_LINKS: Array<AppHeaderLink> = [
  { url: urls.DASHBOARD, label: 'dashboard', strict: true },
  { url: urls.REPORTS, label: 'reports', role: [ AdminRoles.LENDER_ADMIN, AdminRoles.LENDER ] },
  { url: urls.WALLETS, label: 'wallets', role: [ AdminRoles.PROCESSOR_ADMIN, AdminRoles.PROCESSOR ] },
  { url: urls.ACCOUNTS, label: 'accounts', role: [ AdminRoles.PROCESSOR_ADMIN, AdminRoles.PROCESSOR ] },
  { url: urls.LOANS, label: 'loans', role: [ AdminRoles.LENDER, AdminRoles.LENDER_ADMIN ] },
  { url: urls.INVOICES, label: 'invoices', role: [ AdminRoles.PROCESSOR_ADMIN, AdminRoles.PROCESSOR ] },
  { url: urls.TRANSACTIONS, label: 'transactions', role: [ AdminRoles.PROCESSOR_ADMIN, AdminRoles.PROCESSOR ] },
  { url: urls.SETTINGS, label: 'settings', role: [ AdminRoles.PROCESSOR_ADMIN, AdminRoles.LENDER_ADMIN ] },
]

export const INDEX_ROUTE = urls.DASHBOARD

export function getWalletUrl(id: number | string) {
  return `${urls.WALLETS}/${id}`
}

export function getAccountUrl(id: number | string) {
  return `${urls.ACCOUNTS}/${id}`
}

export function getTransactionLink(txId: string): string {
  const env = process.env.REACT_APP_ENV as Env
  return getTransactionOutLink(env, txId)
}

export function getApplicationLink(appId: number): string {
  const env = process.env.REACT_APP_ENV as Env
  return getApplicationOutLink(env, appId)
}

export function getAccountLink(accountAddress: string): string {
  const env = process.env.REACT_APP_ENV as Env
  return getAccountOutLink(env, accountAddress)
}

export function getAssetLink(assetId: number): string {
  const env = process.env.REACT_APP_ENV as Env
  return getAssetOutLink(env, assetId)
}

export function useRoleProtection(eligibleRoles?: AdminRoles[]) {
  const { adminRole } = useUser()
  const navigate = useNavigate()

  // Not protected with role
  if (!eligibleRoles) {
    return
  }

  // User owns eligible role
  if (eligibleRoles.includes(adminRole)) {
    return
  }

  // User does not own eligible role, redirect to 404
  navigate(urls.NOT_FOUND, { replace: true })
}

export function useConfirmNavigationModal(): NavigationGuardContextData {
  return useContext(NavigationGuardContext)
}

export function useProtectUnsavedForm(isDirty: boolean) {
  const { setShouldShow } = useConfirmNavigationModal()

  useEffect(() => {
    setShouldShow(isDirty)
  }, [ isDirty ])
}
