import { Account, ISettings, TargetAccountsSettings, TargetSettings } from '@keo/shared-types'
import { TFunction } from 'react-i18next'

export const getStrategyName = (
  account: Account,
  settingType: TargetAccountsSettings,
  t: TFunction
) => {
  const setting = account
    .accountsSettings
    ?.find(({ type }) => type === settingType)

  const strategy = (setting?.appSetting as ISettings<
    TargetSettings.PROCESSING_FEE_STRATEGIES | TargetSettings.PAYBACK_STRATEGIES
  >)?.value?.name

  const isDefault = setting?.isDefault

  return account.accountsSettings && setting?.appSetting
    ? `${strategy} ${isDefault ? `(${t('defaultText')})` : ''}`
    : ''
}
