import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Control, useController } from 'react-hook-form'
import { Box, IconButton, InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material'
import { ValidationError } from '@/types'
import { Clear } from '@mui/icons-material'

type TFormInputProps = TextFieldProps & {
  control: Control<any>
  hideLabel?: boolean
  name: string
  onChange?: () => void
  onClear?: () => void
}

const FormInput: FC<TFormInputProps> = ({ control, hideLabel, name, onChange, onClear, ...props }) => {
  const { t } = useTranslation('errorMessages')
  const { helperText, value, label, InputProps, ...rest } = props

  const { endAdornment, ...restInputProps } = InputProps || {}
  const { field, fieldState } = useController({
    control,
    defaultValue: props.value,
    name
  })

  let errorMessage: string | React.ReactNode | undefined

  if (fieldState.error?.message) {
    if (typeof fieldState.error?.message === 'string') {
      errorMessage = t(fieldState.error.message)
    } else {
      const errorObj: ValidationError = fieldState.error.message
      errorMessage = t(errorObj.key, { label, ...errorObj.values })
    }
  }

  return (
    <>
      {
        !hideLabel &&
        <Box mb={1} ml={2}>
          <Typography variant="caption" color="text.secondary">{label}</Typography>
        </Box>
      }
      <TextField
        error={!!fieldState.error}
        helperText={
          <Box mt={0.5}>
            <Typography variant="caption">
              {errorMessage ? errorMessage : helperText}
            </Typography>
          </Box>
        }
        onChange={onChange ? (e) => {
          field.onChange(e)
          onChange()
        } : field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        name={name}
        inputRef={field.ref}
        InputProps={{
          endAdornment: (
            <>
              {
                (!!onClear && field.value) &&
                <InputAdornment position="end">
                  <IconButton type="button" onClick={onClear}>
                    <Clear/>
                  </IconButton>
                </InputAdornment>
              }
              {endAdornment}
            </>
          ),
          ...restInputProps
        }}
        {...rest}
      />
    </>
  )
}

export default FormInput
