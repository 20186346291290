import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Cancel, WatchLater } from '@mui/icons-material'
import { IconProps, Tooltip } from '@mui/material'
import { TransactionStatus } from '@keo/shared-types'

type TransactionStatusIconProps = {
  status: TransactionStatus
}

const icons = {
  [ TransactionStatus.PENDING ]: WatchLater,
  [ TransactionStatus.FAILED ]: Cancel,
}

const iconColors = {
  [ TransactionStatus.PENDING ]: 'info',
  [ TransactionStatus.FAILED ]: 'error',
}

const TransactionStatusIcon: FC<TransactionStatusIconProps> = ({ status }) => {
  const { t } = useTranslation('transactions')

  if (status === TransactionStatus.COMPLETED) {
    return null
  }

  const tooltipTitle = useMemo(() => t(`tx${status}`), [ status ])
  const Icon = useMemo(() => icons[ status ], [ status ])

  if (!Icon) {
    return null
  }

  return (
    <Tooltip title={tooltipTitle}>
      <Icon
        color={iconColors[ status ] as IconProps['color']}
        fontSize="small"
      />
    </Tooltip>
  )
}

export default TransactionStatusIcon
