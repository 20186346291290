"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CircleNotificationType = void 0;
var CircleNotificationType;
(function (CircleNotificationType) {
    CircleNotificationType["Payments"] = "payments";
    CircleNotificationType["Payouts"] = "payouts";
    CircleNotificationType["Settlements"] = "settlements";
    CircleNotificationType["Cards"] = "cards";
    CircleNotificationType["Ach"] = "ach";
    CircleNotificationType["Wire"] = "wire";
    CircleNotificationType["Transfers"] = "transfers";
    CircleNotificationType["Chargebacks"] = "chargebacks";
    CircleNotificationType["Returns"] = "returns";
})(CircleNotificationType = exports.CircleNotificationType || (exports.CircleNotificationType = {}));
