"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionalBooleanFilter = exports.RangeFilter = void 0;
var RangeFilter;
(function (RangeFilter) {
    RangeFilter["MONTH"] = "month";
    RangeFilter["QUARTER"] = "quarter";
    RangeFilter["YEAR"] = "year";
})(RangeFilter = exports.RangeFilter || (exports.RangeFilter = {}));
var OptionalBooleanFilter;
(function (OptionalBooleanFilter) {
    OptionalBooleanFilter["ALL"] = "";
    OptionalBooleanFilter["YES"] = "yes";
    OptionalBooleanFilter["NO"] = "no";
})(OptionalBooleanFilter = exports.OptionalBooleanFilter || (exports.OptionalBooleanFilter = {}));
