"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CircleAddressType = void 0;
var CircleAddressType;
(function (CircleAddressType) {
    CircleAddressType["Card"] = "card";
    CircleAddressType["Ach"] = "ach";
    CircleAddressType["Wire"] = "wire";
    CircleAddressType["Blockchain"] = "blockchain";
    CircleAddressType["Wallet"] = "wallet";
})(CircleAddressType = exports.CircleAddressType || (exports.CircleAddressType = {}));
