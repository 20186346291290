import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { InvoicesApprovalStatus, InvoicesValidationStatus } from '@keo/shared-types'
import SelectFilter from '@/components/forms/SelectFilter'

const InvoicesFilters: FC = () => {
  const { t } = useTranslation([ 'common', 'invoices' ])
  const typeOptions = useMemo(() => [
    { value: InvoicesApprovalStatus.APPROVED, label: t(`invoices:status.${InvoicesApprovalStatus.APPROVED}`) },
    { value: InvoicesApprovalStatus.CREATED, label: t('invoices:status.pendingSupplier') },
    { value: InvoicesApprovalStatus.LOCKED, label: t('invoices:status.locked') },
    { value: InvoicesApprovalStatus.PENDING, label: t('invoices:status.pendingBuyer') },
    { value: InvoicesApprovalStatus.REJECTED, label: t('invoices:status.deniedSupplier') },
    { value: InvoicesApprovalStatus.DENIED, label: t(`invoices:status.deniedBuyer`) },
  ], [ t ])

  const validationOptions = useMemo(() => [
    { value: InvoicesValidationStatus.BUYER_ELIGIBLE, label: t(`invoices:validationStatus.${InvoicesValidationStatus.BUYER_ELIGIBLE}`) },
    { value: InvoicesValidationStatus.CREATED, label: t(`invoices:validationStatus.${InvoicesValidationStatus.CREATED}`) },
    { value: InvoicesValidationStatus.FRAUD_VALIDATION_DETECTED, label: t(`invoices:validationStatus.${InvoicesValidationStatus.FRAUD_VALIDATION_DETECTED}`) },
    { value: InvoicesValidationStatus.FRAUD_VALIDATION_FAILED_ON_CALL, label: t(`invoices:validationStatus.${InvoicesValidationStatus.FRAUD_VALIDATION_FAILED_ON_CALL}`) },
    { value: InvoicesValidationStatus.FRAUD_VALIDATION_IN_PROGRESS, label: t(`invoices:validationStatus.${InvoicesValidationStatus.FRAUD_VALIDATION_IN_PROGRESS}`) },
    { value: InvoicesValidationStatus.SUPPLIER_ELIGIBLE, label: t(`invoices:validationStatus.${InvoicesValidationStatus.SUPPLIER_ELIGIBLE}`) },
    { value: InvoicesValidationStatus.SUPPLIER_WHITELIST, label: t(`invoices:validationStatus.${InvoicesValidationStatus.SUPPLIER_WHITELIST}`) },
    { value: InvoicesValidationStatus.VALID, label: t(`invoices:validationStatus.${InvoicesValidationStatus.VALID}`) },
  ], [ t ])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <SelectFilter
          label={t('invoices:validationStatus.label')}
          fieldName="validationStatus"
          multiple
          options={validationOptions}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <SelectFilter
          label={t('status')}
          fieldName="approvalStatus"
          multiple
          options={typeOptions}
        />
      </Grid>
    </Grid>
  )
}

export default InvoicesFilters
