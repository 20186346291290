"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanHistoryAction = void 0;
var LoanHistoryAction;
(function (LoanHistoryAction) {
    LoanHistoryAction["LOAN_CREATED"] = "LOAN_CREATED";
    LoanHistoryAction["LOAN_UPDATED"] = "LOAN_UPDATED";
    LoanHistoryAction["LOAN_REJECTED"] = "LOAN_REJECTED";
    LoanHistoryAction["LOAN_CLOSED"] = "LOAN_CLOSED";
})(LoanHistoryAction = exports.LoanHistoryAction || (exports.LoanHistoryAction = {}));
