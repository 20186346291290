import {
  Account,
  AccountInitialisedFilter,
  AccountType, AdminRoles,
  ApiKeyScope,
  ApiPagination,
  CallbackUrlFields,
  Invoice,
  ITransaction,
  ListAuditLogsParams,
  Loan,
  PaginatedResult,
  Rating,
  TargetAccountsSettings,
  TargetSettings,
  Wallet
} from '@keo/shared-types'
import {
  OnFilterAccountsParams,
  OnFilterAdminUsersParams,
  OnFilterAuditLogsParams,
  OnFilterLoansParams,
  OnFilterTransactionsParams,
  OnFilterWalletsParams
} from './forms'

export * from './session'
export * from './forms'


export type GetWalletsParams = {
  pagination: ApiPagination
  filters: OnFilterWalletsParams
  sorting?: string
}

export type GetWalletRequestParams = {
  populateWalletDetails?: boolean
}

export type GetWalletsRequestParams = ApiPagination & GetWalletRequestParams & {
  accountType?: AccountType
  rating?: Rating
  creditLineAmountFrom?: number
  creditLineAmountTo?: number
  searchTerm?: string
  sorting?: string
}

export type GetWalletsResponse = PaginatedResult<Wallet>

export type GetAccountsParams = {
  pagination: ApiPagination
  filters: OnFilterAccountsParams
  sorting?: string
}

export type GetAccountsRequestParams = ApiPagination & {
  accountType?: AccountType
  rating?: Rating
  initialised?: AccountInitialisedFilter
  searchTerm?: string
  populateAccountSettings?: TargetAccountsSettings[]
  sorting?: string
}

export type GetAccountsResponse = PaginatedResult<Account>

export type AdminUser = {
  id: number
  name: string
  email: string
  role: AdminRoles
  lastActive: number
}

export type GetAdminUsersParams = {
  pagination: ApiPagination
  filters: OnFilterAdminUsersParams
  sorting?: string
}

export type GetAdminUsersRequestParams = ApiPagination & {
  searchTerm?: string
}

export type GetAdminUsersResponse = PaginatedResult<AdminUser>

export enum WalletModalAction {
  BAN = 'ban',
  SUSPEND = 'suspend',
  UNSUSPEND = 'unsuspend',
  UPDATE_CREDIT_LINE = 'updateCreditLine',
}

export enum WalletTabs {
  LOANS = 'loans',
  TRANSACTIONS = 'transactions'
}

export enum AccountTabs {
  LOANS = 'loans',
  TRANSACTIONS = 'transactions',
  WALLETS = 'wallets',
  PROCESSING_FEE_STRATEGIES = 'processingFeeStrategies',
}

export type GetLoansRequestParams = ApiPagination & {
  amountFrom?: number
  amountTo?: number
  createdDateFrom?: string | number
  createdDateTo?: string | number
  dueDateFrom?: string | number
  dueDateTo?: string | number
  loanPayBackStatuses?: string
  sorting?: string
}

export type GetAuditLogsRequestParams = ApiPagination & Partial<Record<keyof ListAuditLogsParams, any>> & {
  dateFrom?: string | number
  dateTo?: string | number
  sorting?: string
}

export type GetAuditLogsParams = {
  pagination: ApiPagination
  filters: OnFilterAuditLogsParams
  sorting?: string,
}

export type GetLoansParams = {
  pagination: ApiPagination
  filters: OnFilterLoansParams
  sorting?: string
}

export type GetLoansResponse = PaginatedResult<Loan>

export type ApiKey = {
  id: string
  label: string
  scope: ApiKeyScope
}

export type GetApiKeysResponse = ApiKey[]

export type AddApiKeyResponse = ApiKey & {
  key: string
}

export type GetInvoicesResponse = PaginatedResult<Invoice>

export type GetInvoicesRequestParams = ApiPagination & {
  approvalStatus?: string
  searchTerm?: string
  validationStatus?: string
}

export type GetTransactionsResponse = PaginatedResult<ITransaction>

export type GetTransactionsRequestParams = ApiPagination & Omit<OnFilterTransactionsParams, 'status'> & {
  status?: string
}

export type GetCallbackUrlsResponse = PaginatedResult<CallbackUrlFields>

export type CallbackUrlPayload = {
  type: TargetSettings.CALLBACK_URL
  callbackUrlData: CallbackUrlFields
}

export type GetAccountSettingsParams = {
  accountId?: string
  appliedToId?: string
  appSettingId?: number
  type?: TargetAccountsSettings
}
