import React, { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import '@/utils/api'
import '@/utils/i18n'
import './sentry.setup'
import './global.css'
import { GlobalLoader } from '@/components'

const container = document.getElementById('root')
const root = createRoot(container as Element)
root.render(
  <StrictMode>
    <Suspense fallback={<GlobalLoader/>}>
      <App/>
    </Suspense>
  </StrictMode>
)
