import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

const envMapper: Record<string, string> = {
  prod: 'production',
  staging: 'staging',
  dev: 'development',
  uat: 'uat'
}

export const APP_VERSION = process.env.npm_package_version

const IS_DEV = process.env.NODE_ENV === 'development'

const ENABLE_REPLAYS = process.env.REACT_APP_SENTRY_ENABLE_REPLAYS === 'true'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: APP_VERSION || '',
    environment: envMapper[ process.env.REACT_APP_ENV || 'dev' ],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      ...(ENABLE_REPLAYS ? [ new Sentry.Replay() ] : [])
    ],
    // 1.0 = 100% of traces will be sent to Sentry
    // change 0 to 1.0 to send all traces to Sentry (for testing purposes)
    tracesSampleRate: IS_DEV ? 0 : 0.1,

    ...(ENABLE_REPLAYS && {
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: IS_DEV ? 1.0 : 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0
    })
  })
}
