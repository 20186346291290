import React, { FC } from 'react'
import { Box } from '@mui/material'
import { DeleteIconButton, EditIconButton } from '@/components'

type RowActionsProps = {
  onEditClick: () => void
  onDeleteClick: () => void
}

const RowActions:FC<RowActionsProps> = ({ onEditClick, onDeleteClick }) => {
  return <Box display="flex">
    <Box mr={1}>
      <EditIconButton onClick={onEditClick}/>
    </Box>
    <DeleteIconButton onClick={onDeleteClick}/>
  </Box>
}

export default RowActions
