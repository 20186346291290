"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CirclePaymentStatus = exports.CircleObjectType = void 0;
var CircleObjectType;
(function (CircleObjectType) {
    CircleObjectType["Payment"] = "payment";
    CircleObjectType["Cancel"] = "cancel";
    CircleObjectType["Refund"] = "refund";
})(CircleObjectType = exports.CircleObjectType || (exports.CircleObjectType = {}));
var CirclePaymentStatus;
(function (CirclePaymentStatus) {
    CirclePaymentStatus["Pending"] = "pending";
    CirclePaymentStatus["Confirmed"] = "confirmed";
    CirclePaymentStatus["Paid"] = "paid";
    CirclePaymentStatus["Failed"] = "failed";
})(CirclePaymentStatus = exports.CirclePaymentStatus || (exports.CirclePaymentStatus = {}));
