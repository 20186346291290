import { FC, ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'

type PageTitleProps = {
  title: string
  meta?: ReactNode
}

const PageTitle:FC<PageTitleProps> = ({ meta, title }) => {
  return (
    <Stack mb={3} direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h4" color="text">{title}</Typography>
      {meta && meta}
    </Stack>
  )
}

export default PageTitle
