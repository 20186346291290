import React, { FC, useCallback } from 'react'
import { Button, Menu, MenuItem, useTheme } from '@mui/material'
import { Person, KeyboardArrowDown } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSession } from '@keo/frontend/utils/session'
import { logout } from '@/api/session'
import { urls } from '@/utils/routes'
import { useExternalWallet } from '@/components/hooks/useExternalWallet'

const AccountMenu: FC = () => {
  const { t } = useTranslation('app-header')
  const theme = useTheme()
  const { invalidateSession } = useSession()
  const navigate = useNavigate()
  const { isConnected, disconnect } = useExternalWallet()
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleLogout = useCallback(async () => {
    setAnchorEl(null)
    try {
      await logout()
      invalidateSession()

      if(isConnected) {
        disconnect()
      }

      navigate(urls.LOGIN)
    } catch (err) {
      console.error(err)
    }
  }, [ isConnected ])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <Button
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown sx={{ color: theme.palette.background.default }}/>}
      >
        <Person sx={{ color: theme.palette.background.default }}/>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleLogout} disableRipple>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default AccountMenu
