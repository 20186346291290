import React, { FC, useCallback } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { EditAdminUserParams } from '@/types'
import Modal from '@/components/modals/components/Modal'
import { FormInput } from '@/components'
import Actions from '@/components/modals/components/Actions'
import { EditModalProps } from './types'

const EditAdminUserSchema = yup.object({
  name: yup.string().trim().notRequired(),
  email: yup.string().trim().email().notRequired()
})

type EditAdminUserProps = EditModalProps<EditAdminUserParams>

const EditAdminUser: FC<EditAdminUserProps> = ({ onClose, onSubmit, initialValues, error = false }) => {
  const { t } = useTranslation([ 'common', 'admin-users' ])
  const methods = useForm<EditAdminUserParams>({
    resolver: yupResolver(EditAdminUserSchema),
    defaultValues: {
      name: initialValues.name || '',
      email: initialValues.email || ''
    }
  })

  const handleEditAdminUser = useCallback(({ name, email }: EditAdminUserParams) => {
    onSubmit({
      name,
      email
    })
  }, [])

  return (
    <Modal
      open
      onClose={onClose}
      title={t('modal.edit.title', { ns: 'admin-users' })}
      maxWidth="xs"
      actions={
        <Actions
          onCancel={onClose}
          onCancelLabel={t('cancel')}
          onSubmit={methods.handleSubmit(handleEditAdminUser)}
          onSubmitLabel={t('save')}
        />
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleEditAdminUser)}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <FormInput control={methods.control} name="name" label={t('name')}/>
            </Grid>
            <Grid item>
              <FormInput control={methods.control} name="email" label={t('email')}/>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {error && (
        <Box mt={1}>
          <Typography color="error" variant="caption">{t('modal.edit.error', { ns: 'admin-users' })}</Typography>
        </Box>
      )}
    </Modal>
  )
}

export default EditAdminUser
