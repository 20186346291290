declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
    body4: true
  }
}

export {}

export const fontConfig = {
  h1: {
    fontSize: 32,
    letterSpacing: 1,
    fontWeight: '700',
    lineHeight: '125%'
  },
  h2: {
    fontSize: 28,
    letterSpacing: 0.8,
    fontWeight: '700',
    lineHeight: '125%'
  },
  h3: {
    fontSize: 24,
    letterSpacing: 0.6,
    fontWeight: '700',
    lineHeight: '125%'
  },
  h4: {
    fontSize: 22,
    letterSpacing: 0.4,
    fontWeight: '700',
    lineHeight: '125%'
  },
  h5: {
    fontSize: 20,
    letterSpacing: 0.2,
    fontWeight: '400',
    lineHeight: '150%'
  },
  h6: {
    fontSize: 20,
    fontWeight: '700',
    lineHeight: '120%'
  },
  subtitle1: {
    fontWeight: '400',
    lineHeight: '150%',
    fontSize: 18
  },
  subtitle2: {
    fontWeight: '700',
    lineHeight: '150%',
    fontSize: 18
  },
  body1: {
    fontWeight: '400',
    lineHeight: '150%',
    fontSize: 16,
  },
  body2: {
    fontWeight: '700',
    lineHeight: '150%',
    fontSize: 16,
  },
  body3: {
    fontWeight: '400',
    lineHeight: '130%',
    fontSize: 14,
    letterSpacing: 0.8
  },
  body4: {
    fontWeight: '700',
    lineHeight: '130%',
    fontSize: 14,
    letterSpacing: 0.8
  },
  button: {
    fontWeight: '600',
    lineHeight: '100%',
    fontSize: 16,
    letterSpacing: 0.8
  },
  caption: {
    fontWeight: '600',
    letterSpacing: 0.4,
    lineHeight: '100%',
    fontSize: 12
  },
  caption2: {
    fontWeight: '400',
    letterSpacing: 0.4,
    lineHeight: '100%',
    fontSize: 12
  },
}
