import React, { FC } from 'react'
import { Delete } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@keo/frontend/components/Button'

type DeleteIconButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  tooltip?: string
}

const DeleteIconButton: FC<DeleteIconButtonProps> = ({ onClick, tooltip }) => {
  const { t } = useTranslation('common')
  return (
    <IconButton onClick={onClick} tooltip={tooltip || t('delete')} sx={{ '&:hover': { color: 'error.main' } }}>
      <Delete/>
    </IconButton>
  )
}

export default DeleteIconButton
