import { useQuery } from 'react-query'
import { getTransactions } from '@/api/transactions'
import { OnFilterTransactionsParams } from '@/types'
import { ApiQueryKeys } from '@/api/hooks/queries'
import { TransactionStatus } from '@keo/shared-types'

type UseTransactionsParams = {
  page: number
  perPage: number
  filters: OnFilterTransactionsParams
  sorting?: string
}

export const useTransactions = ({ page, perPage, filters, sorting }: UseTransactionsParams) => {
  return useQuery(
    [ ApiQueryKeys.TRANSACTIONS, filters, sorting, perPage, page ],
    () => getTransactions({
      pagination: {
        page,
        perPage
      },
      filters,
      sorting
    }),
    { keepPreviousData: true }
  )
}

export const useLatestTransactions = ({ itemsToShow }: { itemsToShow: number }) => {
  return useQuery(
    [ ApiQueryKeys.TRANSACTIONS, ApiQueryKeys.LATEST, itemsToShow ],
    () => getTransactions({
      pagination: {
        page: 1,
        perPage: itemsToShow
      },
      filters: { status: [ TransactionStatus.COMPLETED ] },
    })
  )
}
