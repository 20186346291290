import { FC } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { APP_HEADER_HEIGHT } from '@/utils/constants'

const SystemUnavailable: FC = () => {
  const { t } = useTranslation('error', { keyPrefix: 'systemUnavailable' })
  return (
    <Container
      maxWidth="sm" 
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img alt="Workeo logo" src="/logos/workeo_1024.png" height={100}/>
        <Box my={5}>
          <Typography variant="h4" component="h2" gutterBottom>
            {t('title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('message')}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          style={{ marginTop: '20px' }}>
          {t('action')}
        </Button>
      </Box>
    </Container>
  )
}

export default SystemUnavailable
