import React, { FC, useMemo } from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CurrencyRangeFilter } from '@/components/forms/RangeFilter'
import SelectFilter from '@/components/forms/SelectFilter'
import { AccountType, OptionalBooleanFilter, Rating } from '@keo/shared-types'

type FiltersProps = {
  accountType: AccountType
}


const Filters: FC<FiltersProps> = ({ accountType }) => {
  const { t } = useTranslation([ 'common', 'wallets' ])

  const booleanOptions = useMemo(() => [
    { value: OptionalBooleanFilter.YES, label: t('yes') },
    { value: OptionalBooleanFilter.NO, label: t('no') }
  ], [ t ])

  const ratingOptions = useMemo(() => [
    ...Object.values(Rating).map((rating) => ({ value: rating, label: rating }))
  ], [])

  return (
    <Grid container spacing={3}>
      {/* TODO: uncomment once we have a solution for filtering by balance
       <Grid item>
        <CurrencyRangeFilter
          label={t('balance')}
          fromField="balance.from"
          fromPlaceholder={t('from')}
          toField="balance.to"
          toPlaceholder={t('to')}
        />
      </Grid> */}
      <Grid item>
        <SelectFilter
          label={t('whitelisted')}
          fieldName="whitelisted"
          options={booleanOptions}
          allOptionText={t('all')}
        />
      </Grid>
      {accountType === AccountType.BUYER && (
        <>
          <Grid item>
            <CurrencyRangeFilter
              label={t('table.creditLine', { ns: 'wallets' })}
              fromField="creditLineAmountFrom"
              fromPlaceholder={t('from')}
              toField="creditLineAmountTo"
              toPlaceholder={t('to')}
            />
          </Grid>
          <Grid item>
            <SelectFilter
              label={t('rating')}
              fieldName="rating"
              options={ratingOptions}
              allOptionText={t('all')}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default Filters
