import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { pick } from 'lodash'
import { GridSortItem } from '@mui/x-data-grid'
import { DEFAULT_PAGE_SIZE } from '@/utils/constants'
import { parseDateExcludingTime } from '@/utils/l10n'
import { isUndefinedOrEmpty, parseSortItem, stringifySortItem } from '@/utils/parsers'

type UseDataGridOptionsParams<T> = {
  initialPage?: number
  initialPerPage?: number
  initialFilterValues?: T
  shouldUpdateUrl?: boolean
  initialSort?: GridSortItem
  arrayFilters?: Array<keyof T>
  removeFalsy?: string[] | boolean
}

export function serializeData(params: Record<string, unknown>, removeFalsy?: (keyof typeof params)[] | boolean) {
  const serializedData:  Record<string, string> = {}
  for (const key in params) {
    const value = params[ key ]

    if(typeof removeFalsy === 'boolean' && removeFalsy && !value) {
      continue
    }

    if(Array.isArray(removeFalsy) && removeFalsy.includes(key) && !value) {
      continue
    }

    // Skip undefined or empty keys
    if (isUndefinedOrEmpty(value)) {
      continue
    }

    if (Array.isArray(value) && value.length === 0) {
      continue
    }

    if(value instanceof Date) {
      serializedData[ key ] = parseDateExcludingTime(value as unknown as string)
      continue
    }

    serializedData[ key ] = (value as any).toString()
  }
  return serializedData
}


export function useDataGridOptions<T>({
  initialPage = 1,
  initialPerPage = DEFAULT_PAGE_SIZE,
  initialFilterValues = {} as T,
  initialSort,
  arrayFilters = [],
  shouldUpdateUrl = true,
  removeFalsy = false,
}: UseDataGridOptionsParams<T>) {
  const [ searchParams, setSearchParams ] = useSearchParams()

  const searchParamsObj = useMemo(() => {
    if(!shouldUpdateUrl) {
      return {
        page: initialPage,
        perPage: initialPerPage,
        sorting: stringifySortItem(initialSort),
      }
    }

    const validParams = [
      ...Object.keys(initialFilterValues as Record<keyof T, unknown>),
      'page',
      'perPage',
      'sorting',
    ]

    const result = pick(Object.fromEntries(searchParams), validParams) as Record<keyof T | 'page' | 'perPage' | 'sorting', unknown>

    arrayFilters?.forEach((filter) => {
      if(result[ filter ]) {
        result[ filter ] = (result[ filter ] as string).split(',')
      } else {
        delete result[ filter ]
      }
    })

    return result
  }, [ searchParams ])

  const { page: currentPage, perPage: currentPerPage, sorting: currentSorting, ...filtersObj } = searchParamsObj

  const [ page, setPage ] = useState(Number(currentPage) || initialPage)
  const [ perPage, setPerPage ] = useState(Number(currentPerPage) || initialPerPage)
  const [ filters, setFilters ] = useState<T>( { ...initialFilterValues, ...filtersObj })
  const [ sorting, setSorting ] = useState<GridSortItem | undefined>(parseSortItem(currentSorting as string) || initialSort)

  useEffect(() => {
    if(!shouldUpdateUrl) {
      return
    }
    const params = { page, perPage, ...initialFilterValues, ...filters, sorting: stringifySortItem(sorting) }
    const result = serializeData(params, removeFalsy)
    setSearchParams(new URLSearchParams(result), { replace: true })
  }, [ page, perPage, JSON.stringify(filters), JSON.stringify(initialFilterValues), shouldUpdateUrl, sorting ])

  const handlePerPageChange = useCallback((newPerPage: number) => {
    setPage(1)
    setPerPage(newPerPage)
  }, [])

  const handleSort = useCallback((sort: GridSortItem) => {
    setPage(1)
    setSorting(sort)
  }, [])

  return {
    page,
    perPage,
    filters,
    sorting,
    setPage,
    setPerPage: handlePerPageChange,
    setFilters,
    handleSort,
  }
}
