import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ValidationError } from '@/types'
import { DATE_FORMAT_DISPLAY } from '@/utils/constants'

type DateInputProps = {
  name: string
  placeholder?: string
  onFieldChange?: () => void
}

const DateInput: FC<DateInputProps> = ({ name, onFieldChange, placeholder }) => {
  const { t } = useTranslation('errorMessages')
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => {
        let errorMessage: string | React.ReactNode | undefined

        if (fieldState.error?.message) {
          if (typeof fieldState.error?.message === 'string') {
            errorMessage = t(fieldState.error.message)
          } else {
            const errorObj: ValidationError = fieldState.error.message
            errorMessage = t(errorObj.key, errorObj.values)
          }
        }

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputFormat={DATE_FORMAT_DISPLAY}
              value={value === '' ? null : value} // need to set null so the input gets cleared, otherwise it keeps previous value
              onChange={(date) => {
                onChange(date)
                onFieldChange?.()
              }}
              renderInput={({ inputProps, ...otherProps }) => (
                <TextField
                  size="small"
                  {...otherProps}
                  error={!!fieldState.error}
                  helperText={errorMessage}
                  inputProps={{
                    ...inputProps,
                    placeholder
                  }}
                />
              )}
            />
          </LocalizationProvider>
        )
      }}
    />
  )
}

export default DateInput
