import React, { FC, useMemo } from 'react'
import { Alert, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Error } from '@mui/icons-material'
import { AccountType, Wallet } from '@keo/shared-types'
import { useLocale } from '@/utils/l10n'
import DetailsItem from '@/components/Details/DetailsItem'
import { getAccountUrl } from '@/utils/routes'
import { BooleanIcon } from '@/components'

type WalletInfoProps = {
  wallet?: Wallet
  isLoading: boolean
}

const WalletInfo: FC<WalletInfoProps> = ({ wallet, isLoading }) => {
  const { t } = useTranslation([ 'common', 'wallets' ])
  const { parseCurrencyAmount, parseDate } = useLocale()
  const noWallet = useMemo(() => !wallet && !isLoading, [ wallet, isLoading ])
  const hasMissedLoanDueDate = useMemo(() => !!wallet?.missedDueDatesCount, [ wallet ])
  const isBuyer = useMemo(() => wallet?.account?.type === AccountType.BUYER, [ wallet?.account?.type ])

  const balance = useMemo<number | undefined>(() => {
    if (isBuyer)
      return wallet?.balances.creditAvailable
    else {
      return wallet?.balances.currencyToken
    }
  }, [ isBuyer, wallet?.id, wallet?.balances ])

  const accountUrl = useMemo(() => wallet?.account ? getAccountUrl(wallet.account.id) : '', [ wallet?.account?.id ])

  if (noWallet) {
    return null
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} {...isBuyer && { sm: 6 }}>
        <Stack gap={3}>
          <DetailsItem label={t('type')} value={t(wallet?.account?.type || '')} isLoading={isLoading}/>
          <DetailsItem label={t('accountId')} value={wallet?.account?.id} isLoading={isLoading} variant="link" href={accountUrl}/>
          <DetailsItem label={t('walletAddress')} value={wallet?.address} isLoading={isLoading}/>
          <DetailsItem label={t('whitelisted')} value={<BooleanIcon value={!!wallet?.whitelisted} />} isLoading={isLoading}/>
          <DetailsItem
              label={t('walletBalance')}
              value={parseCurrencyAmount(balance || 0)}
              isLoading={isLoading}
              variant="bold"
            />
        </Stack>
      </Grid>
      {isBuyer && (
        <Grid item xs={12} sm={6}>
          <Stack gap={3}>
            <DetailsItem
              label={t('creditLine')}
              value={parseCurrencyAmount(Number(wallet!.account!.creditLineAmount) || 0)}
              isLoading={isLoading}
              variant="bold"
            />
            <DetailsItem label={t('rating')} value={wallet?.account?.rating} isLoading={isLoading}/>
            <DetailsItem
              label={t('createdDate')}
              value={wallet ? parseDate(wallet.createdAt) : '-'}
              isLoading={isLoading}
            />
            {hasMissedLoanDueDate && (
              <Alert severity="error" icon={<Error/>} sx={{ justifyContent: 'center', width: '100%' }}>
                <Typography variant="body4">{t('details.missedLoanDueDate', { ns: 'wallets' })}</Typography>
              </Alert>
            )}
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}

export default WalletInfo
