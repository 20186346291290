import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import { ApiErrorCode, FeatureFlags, FeatureFlagsFields, ISettings, TargetSettings } from '@keo/shared-types'
import {
  addProcessingFeeStrategy,
  getWithdrawAutomationSetting,
  getProcessingFeeStrategies,
  updateProcessingFeeStrategy,
  getSupplierApprovalAutomationSetting,
  updateWithdrawAutomationSetting,
  updateSupplierApprovalAutomationSetting,
  getAutomationsSummary,
  enableAutomations,
  updateFeatureFlagsSetting,
  getFeatureFlagsSetting,
  UpdateFeatureFlagsSettingParams,
} from '@/api/settings'
import { ApiQueryKeys } from '@/api/hooks/queries'
import { useConfig } from '@/utils/appContext'

export function useProcessingFees() {
  return useQuery(
    [ ApiQueryKeys.PROCESSING_FEE_STRATEGIES ],
    () => getProcessingFeeStrategies({ page: 1, perPage: 100 }),
    { keepPreviousData: true }
  )
}

type UseAddProcessingFeeStrategy = {
  onSuccess: (createdStrategyId: number) => void
}

export function useAddProcessingFeeStrategy(params: UseAddProcessingFeeStrategy) {
  const { t } = useTranslation('settings')
  const { onSuccess } = params
  return useMutation(addProcessingFeeStrategy, {
    onError: (err: any) => {
      const errCode = err.response.data.code
      if (errCode === ApiErrorCode.SETTINGS_STRATEGY_ALREADY_EXISTS) {
        enqueueSnackbar(t('processingFeeStrategies.messages.addErrorExists'), { variant: 'error' })
        return
      }
      enqueueSnackbar(t('processingFeeStrategies.messages.addError'), { variant: 'error' })
    },
    onSuccess: (res) => {
      enqueueSnackbar(t('processingFeeStrategies.messages.addSuccess'), { variant: 'success' })
      onSuccess(res.id)
    }
  })
}

type UseEditProcessingFeeStrategy = {
  onSuccess: () => void
}

export function useUpdateProcessingFeeStrategy(params: UseEditProcessingFeeStrategy) {
  const { t } = useTranslation('settings')
  const { onSuccess } = params
  return useMutation(updateProcessingFeeStrategy, {
    onError: () => {
      enqueueSnackbar(t('processingFeeStrategies.messages.editError'), { variant: 'error' })
    },
    onSuccess: () => {
      enqueueSnackbar(t('processingFeeStrategies.messages.editSuccess'), { variant: 'success' })
      onSuccess()
    }
  })
}

type UseAutomationParams<T> = {
  onSuccess: (data: T) => void
}

export function useWithdrawAutomation({ onSuccess }: UseAutomationParams<ISettings<TargetSettings.AUTOMATE_WITHDRAWAL>>) {
  return useQuery(
    [ ApiQueryKeys.WITHDRAW_AUTOMATION ],
    () => getWithdrawAutomationSetting(),
    { onSuccess }
  )
}

export function useSupplierApprovalAutomation({ onSuccess }: UseAutomationParams<ISettings<TargetSettings.AUTOMATE_SUPPLIER_INVOICE_APPROVAL>>) {
  return useQuery(
    [ ApiQueryKeys.SUPPLIER_APPROVAL_AUTOMATION ],
    () => getSupplierApprovalAutomationSetting(),
    { onSuccess }
  )
}

type UseUpdateAutomationParams = {
  onSuccess?: () => void
}

export function useUpdateWithdrawAutomation({ onSuccess }: UseUpdateAutomationParams) {
  const { t } = useTranslation('settings', { keyPrefix: 'general.automations.withdrawAutomation' })
  return useMutation(updateWithdrawAutomationSetting, {
    onError: () => {
      enqueueSnackbar(t('error'), { variant: 'error' })
    },
    onSuccess: () => {
      enqueueSnackbar(t('success'), { variant: 'success' })
      onSuccess?.()
    }
  })
}

export function useUpdateSupplierApprovalAutomation({ onSuccess }: UseUpdateAutomationParams) {
  const { t } = useTranslation('settings', { keyPrefix: 'general.automations.supplierApprovalAutomation' })
  return useMutation(updateSupplierApprovalAutomationSetting, {
    onError: () => {
      enqueueSnackbar(t('error'), { variant: 'error' })
    },
    onSuccess: () => {
      enqueueSnackbar(t('success'), { variant: 'success' })
      onSuccess?.()
    }
  })
}

export function useAutomationsSummary() {
  return useQuery(
    [ ApiQueryKeys.AUTOMATIONS_SUMMARY ],
    () => getAutomationsSummary(),
    { keepPreviousData: true }
  )
}

type UseEnableAutomations = {
  onSuccess?: () => void
  onError?: () => void
}

export function useEnableAutomations({ onSuccess, onError }: UseEnableAutomations  = {}) {
  const { t } = useTranslation('settings', { keyPrefix: 'general.automations.status' })
  return useMutation(enableAutomations, {
    onError: (_, shouldEnable) => {
      enqueueSnackbar(t(`error${shouldEnable ? 'Enabled' : 'Disabled'}`), { variant: 'error' })
      onError?.()
    },
    onSuccess: (d) => {
      const isCurrentlyEnabled = d.isEnabled
      enqueueSnackbar(t(`success${isCurrentlyEnabled ? 'Enabled' : 'Disabled'}`), { variant: 'success' })
      onSuccess?.()
    }
  })
}

export function useFeatureFlags() {
  const config = useConfig()
  const { t } = useTranslation('settings', { keyPrefix: 'system.featureManagement' })
  const { data: featureFlagSetting, isLoading } = useQuery([ ApiQueryKeys.FEATURES_STATUS ], getFeatureFlagsSetting)
  const queryClient = useQueryClient()

  const updateFeatureFlagMutation = useMutation(updateFeatureFlagsSetting, {
    onError: (_err, variables) => {
      const updatedFeature = Object.keys(variables.features)[ 0 ]
      enqueueSnackbar(t('message.error', { feature: t(`features.${updatedFeature}.label`) }),{ variant: 'error' })
    },
    onSuccess: (res, variables) => {
      const updatedFeature = Object.keys(variables.features)[ 0 ]
      enqueueSnackbar(t('messages.success', { feature: t(`features.${updatedFeature}.label`) }), { variant: 'success' })
      queryClient.setQueryData([ ApiQueryKeys.FEATURES_STATUS ], res)
      // Update the feature flag in the config
      queryClient.setQueryData([ ApiQueryKeys.CONFIG, config.appContext ], {
        ...config,
        features: res.value,
      }, {
        updatedAt: Date.now(),
      })
    }
  })

  const updateFeatureFlag = async (features: FeatureFlags, value: boolean) => {
    updateFeatureFlagMutation.mutate({
      id: featureFlagSetting?.id!,
      features: { [ features ]: value },
    })
  }

  return {
    features: featureFlagSetting?.value,
    isPending: updateFeatureFlagMutation.isLoading || isLoading,
    updateFeatureFlag,
  }
}
