import { FC, useCallback, useState } from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { copyToClipboard } from '@/utils/web'

type CopyButtonProps = IconButtonProps & {
  value: number | string
}

const CopyButton: FC<CopyButtonProps> = ({ value, ...props }) => {
  const { t } = useTranslation('common')
  const [ copied, setCopied ] = useState(false)

  const handleCopy = useCallback(() => {
    copyToClipboard(value)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }, [])

  return (
    <Tooltip
      open={copied}
      title={t('copied')}
    >
      <IconButton
        size="small"
        onClick={handleCopy}
        {...props}
      >
        <ContentCopy fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default CopyButton