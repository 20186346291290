"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeepLinkScreen = exports.NotificationPriority = void 0;
var NotificationPriority;
(function (NotificationPriority) {
    NotificationPriority[NotificationPriority["ALWAYS_SHOW"] = 1] = "ALWAYS_SHOW";
    NotificationPriority[NotificationPriority["SHOW_IN_BACKGROUND"] = 2] = "SHOW_IN_BACKGROUND";
})(NotificationPriority = exports.NotificationPriority || (exports.NotificationPriority = {}));
var DeepLinkScreen;
(function (DeepLinkScreen) {
    DeepLinkScreen["INVOICE"] = "Invoice";
    DeepLinkScreen["INVOICES"] = "Invoices";
    DeepLinkScreen["LOAN"] = "Loan";
    DeepLinkScreen["LOANS"] = "Loans";
    DeepLinkScreen["TRANSACTIONS"] = "Transactions";
})(DeepLinkScreen = exports.DeepLinkScreen || (exports.DeepLinkScreen = {}));
