import type {} from '@mui/x-data-grid/themeAugmentation'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { Components, Theme } from '@mui/material'
import { fontConfig } from '@/styles/typography'

export function getComponentDefaults(theme: Theme): Components<Omit<Theme, 'components'>> {
  return {
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.main,
          '& .MuiAlert-icon': {
            color: theme.palette.error.main
          }
        },
        standardWarning: {
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.dark,
          '& .MuiAlert-icon': {
            color: theme.palette.warning.main
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: theme.spacing(3),
          height: theme.spacing(4.5)
        },
        sizeLarge: {
          height: theme.spacing(5.5)
        },
        outlined: {
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.background.default
          },
        },
        outlinedError: {
          '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.background.default
          }
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(1),
          fontWeight: 'bold',
          "& .MuiSvgIcon-root": {
            marginLeft: theme.spacing(0.5),
          },
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: theme.spacing(2),
          backgroundColor: theme.palette.background.default
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 3, 2)
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...fontConfig.body3,
          borderRadius: theme.spacing(4)
        },
        sizeSmall: {
          height: theme.spacing(5.5)
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(4)
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[ 500 ]
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 1500,
        enterNextDelay: 500,
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          ...fontConfig.body3,
          '&:focus': {
            outline: 'none'
          },
          '&:focus-within': {
            outline: 'none'
          }
        },
        columnHeader: {
          ...fontConfig.body3,
          color: theme.palette.text.secondary,
          '&:focus': {
            outline: 'none'
          }
        },
        sortIcon: {
          color: theme.palette.primary.main
        },
        row: {
          '&:hover': {
            cursor: 'pointer'
          }
        }
      }
    }
  }
}

export default getComponentDefaults
