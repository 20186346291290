import React, { FC, useCallback, useMemo, useState } from 'react'
import { Alert, Box, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Autorenew, CheckCircleOutlined, Error, PauseCircleFilled, PlayCircleFilled } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import loadable from '@loadable/component'
import { AccountType, Wallet } from '@keo/shared-types'
import { WalletModalAction } from '@/types'
import { useWalletActions } from '../hooks/useWalletActions'
const WalletAction = loadable(() => import('./WalletAction'))
const VerifyAccount = loadable(() => import('@/components/modals/VerifyAccount'))

type WalletActionsProps = {
  wallet: Wallet
  onActionFinish: () => void
  isVerified?: boolean
  isAccountDetailsPage?: boolean
}

const WalletActions: FC<WalletActionsProps> = ({
  wallet,
  onActionFinish,
  isVerified = true,
  isAccountDetailsPage = false
}) => {
  const { t } = useTranslation([ 'common', 'wallets' ])
  const [ isVerifyingAccount, setIsVerifyingAccount ] = useState(false)
  const creditLineAmount = useMemo(() => Number(wallet.account?.creditLineAmount) || 0, [ wallet ])

  const { suspendedAt, bannedAt } = wallet

  const isSuspended = useMemo(() => !!suspendedAt, [ suspendedAt ])
  const isBanned = useMemo(() => !!bannedAt, [ bannedAt ])

  const {
    errorText,
    isBanDisabled,
    isBanLoading,
    isReactivateLoading,
    isSuspendDisabled,
    isSuspendLoading,
    isUpdateCreditLineDisabled,
    isUpdateCreditLineLoading,
    modalAction,
    onBan,
    onSuspend,
    onUpdateCredit,
    setModalAction
  } = useWalletActions({
    wallet,
    onActionFinish
  })

  const isActionLoading = useMemo(
    () => isBanLoading || isSuspendLoading || isReactivateLoading || isUpdateCreditLineLoading,
    [ isBanLoading, isSuspendLoading, isReactivateLoading, isUpdateCreditLineLoading ]
  )

  const onVerifyAccountSuccess = useCallback(() => {
    setIsVerifyingAccount(false)
    onActionFinish()
  }, [])

  if (isBanned) {
    return (
      <Alert severity="error" icon={<Error/>} sx={{ justifyContent: 'center' }}>
        <Typography variant="body4">{t('banned', { ns: 'wallets' })}</Typography>
      </Alert>
    )
  }

  return (
    <>
      <Grid container spacing={3}>
        {isVerified && (
          <Grid item xs={3}>
            <LoadingButton
              size="large"
              variant="outlined"
              color="error"
              startIcon={<Error/>}
              fullWidth
              onClick={() => setModalAction(WalletModalAction.BAN)}
              disabled={isBanDisabled}
              loading={isBanLoading}
              loadingPosition="start"
            >
              {t('actions.ban')}
            </LoadingButton>
          </Grid>
        )}
        {isVerified && (
          <Grid item xs={3}>
            <LoadingButton
              size="large"
              variant="outlined"
              color="error"
              startIcon={isSuspended ? <PlayCircleFilled/> : <PauseCircleFilled/>}
              fullWidth
              onClick={() => setModalAction(isSuspended ? WalletModalAction.UNSUSPEND : WalletModalAction.SUSPEND)}
              disabled={isSuspendDisabled}
              loading={isSuspendLoading || isReactivateLoading}
              loadingPosition="start"
            >
              {t(`actions.${isSuspended ? 'unsuspend' : 'suspend'}`)}
            </LoadingButton>
          </Grid>
        )}
        {/*Temporary hide the update credit line button*/}
        {false && wallet.account?.type === AccountType.BUYER && isVerified && (
          <Grid item xs={3}>
            <LoadingButton
              size="large"
              variant="outlined"
              color="secondary"
              startIcon={<Autorenew/>}
              fullWidth
              onClick={() => setModalAction(WalletModalAction.UPDATE_CREDIT_LINE)}
              disabled={isUpdateCreditLineDisabled}
              loading={isActionLoading}
              loadingPosition="start"
            >
              {t('actions.updateCreditLine')}
            </LoadingButton>
          </Grid>
        )}
        {!isVerified && isAccountDetailsPage && (
          <Grid item xs={3}>
            <LoadingButton
              size="large"
              variant="outlined"
              color="secondary"
              startIcon={<CheckCircleOutlined/>}
              fullWidth
              onClick={() => setIsVerifyingAccount(true)}
              disabled={isUpdateCreditLineDisabled}
              loading={isActionLoading}
              loadingPosition="start"
            >
              {t('actions.verifyAccount')}
            </LoadingButton>
          </Grid>
        )}
        {!!errorText && !isActionLoading && (
          <Grid item xs={3}>
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Typography variant="caption" alignSelf="center" color="error">
                {errorText}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      {
        modalAction && (
          <WalletAction
            modalAction={modalAction}
            isSuspended={isSuspended}
            onSuspend={onSuspend}
            currentCreditLine={creditLineAmount}
            onUpdateCreditLine={onUpdateCredit}
            onBan={onBan}
            onClose={() => setModalAction(null)}
          />
        )
      }
      {
        isVerifyingAccount && (
          <VerifyAccount onClose={() => setIsVerifyingAccount(false)} onSuccess={onVerifyAccountSuccess} wallet={wallet} />
        )
      }
    </>
  )
}

export default WalletActions
