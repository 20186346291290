import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Stack } from '@mui/material'
import { Invoice, InvoicesValidationStatus } from '@keo/shared-types'
import Modal from '@/components/modals/components/Modal'
import { useLocale } from '@/utils/l10n'
import InvoiceDetailsItem from './InvoiceDetailsItem'
import InvoiceStatus from './InvoiceStatus'

type InvoiceDetailsProps = {
  invoice: Invoice
  onClose: () => void
}

const InvoiceDetails: FC<InvoiceDetailsProps> = ({
  invoice,
  onClose,
}) => {
  const { t } = useTranslation([ 'invoices', 'common' ])
  const { parseCurrencyAmount, parseDate } = useLocale()


  return (
    <Modal open onClose={onClose} title={t('details.title')}>
      <Stack spacing={1.5} direction="column">
        <InvoiceDetailsItem label={t('common:id')}>
          {invoice.externalId}
        </InvoiceDetailsItem>
        <InvoiceDetailsItem label={t('common:name')}>
          {invoice.name}
        </InvoiceDetailsItem>
        <InvoiceDetailsItem label={t('common:createdDate')}>
          {parseDate(invoice.createdAt)}
        </InvoiceDetailsItem>
        <InvoiceDetailsItem label={t('common:status')}>
          <InvoiceStatus showSuccess status={invoice.approvalStatus} validationStatus={invoice.validationStatus} />
        </InvoiceDetailsItem>
        <InvoiceDetailsItem label={t('supplierApprovalMode')}>
          {t(`common:${invoice.supplierApprovalType}`)}
        </InvoiceDetailsItem>
        {
          invoice.validationStatus === InvoicesValidationStatus.VALID ? (
            <InvoiceDetailsItem label={t('validationDate')}>
              {parseDate(invoice.createdAt)}
            </InvoiceDetailsItem>
          ) : (
            <InvoiceDetailsItem label={t('common:details')}>
              {t(`validationStatus.${invoice.validationStatus}`)}
            </InvoiceDetailsItem>
          )
        }
        <Grid item>
          <Divider />
        </Grid>
        {
          invoice.buyer ? (
            <>
              <InvoiceDetailsItem label={t('common:buyer')}>
                {invoice.buyer.account.email}
              </InvoiceDetailsItem>
              <InvoiceDetailsItem label={t('buyerWallet')}>
                {invoice.buyer.address}
              </InvoiceDetailsItem>
            </>
          ) : (
            <InvoiceDetailsItem label={t('buyerId')}>
              {invoice.buyerAccount}
            </InvoiceDetailsItem>
          )
        }
        {
          invoice.supplier ? (
            <>
              <InvoiceDetailsItem label={t('common:supplier')}>
                {invoice.supplier.account.email}
              </InvoiceDetailsItem>
              <InvoiceDetailsItem label={t('supplierWallet')}>
                {invoice.supplier.address}
              </InvoiceDetailsItem>
            </>
          ) : (
            <InvoiceDetailsItem label={t('supplierId')}>
              {invoice.supplierAccount}
            </InvoiceDetailsItem>
          )
        }
        <Grid item>
          <Divider />
        </Grid>
        <InvoiceDetailsItem label={t('common:amount')}>
          {parseCurrencyAmount(invoice.amount)}
        </InvoiceDetailsItem>
      </Stack>
    </Modal>
  )
}

export default InvoiceDetails
