import { useQuery } from 'react-query'
import { getAdminUsers } from '@/api/admin-users'
import { ApiQueryKeys } from '@/api/hooks/queries'
import { GetAdminUsersParams } from '@/types'

export const useAdminUsers = (params: GetAdminUsersParams) => {
  const { pagination, filters, sorting } = params
  const { page, perPage } = pagination

  return useQuery(
    [ ApiQueryKeys.ADMIN_USERS, filters, sorting, perPage, page ],
    () => getAdminUsers({
      pagination: {
        page,
        perPage
      },
      filters,
      sorting,
    }),
    { keepPreviousData: true }
  )
}
