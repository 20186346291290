import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { Invoice, InvoicesApprovalStatus, InvoicesValidationStatus } from '@keo/shared-types'
import { OnFilterInvoicesParams } from '@/types/forms'
import { useInvoices } from '@/api/hooks'
import { stringifySortItem } from '@/utils/parsers'
import { CurrencyCell, DateCell, DataGrid, Toolbar, CSVExporter } from '@/components'
import { useDataGridOptions } from '@/components/tables/hooks/useDataGridOptions'
import useExport from '@/components/hooks/useExport'
import { Entity } from '@/components/modals/CSVExporter'
import InvoicesFilters from './components/InvoicesFilters'
import AccountCell from './components/AccountCell'
import InvoiceStatus from './components/InvoiceStatus'
import InvoiceDetails from './components/InvoiceDetails'
import { getDefaultCreatedAtSorting } from '@/utils/sort'

// Refine the schema once it's defined on the backend
const FilterFormSchema = yup.object({
  approvalStatus: yup.array()
    .of(yup.string().oneOf(Object.values(InvoicesApprovalStatus), 'invalid'))
    .notRequired(),
  validationStatus: yup.array()
    .of(yup.string().oneOf(Object.values(InvoicesValidationStatus), 'invalid'))
    .notRequired(),
  searchTerm: yup.string().trim().notRequired(),
})

const FilterFormDefaultValues: OnFilterInvoicesParams = {
  searchTerm: '',
  approvalStatus: [],
  validationStatus: [],
}

const InvoicesTable: FC = () => {
  const { t } = useTranslation([ 'common', 'invoices' ])
  const [ showInvoiceDetails, setShowInvoiceDetails ] = useState<Invoice | null>(null)
  const {
    page,
    perPage,
    filters,
    setPage,
    setPerPage,
    setFilters,
    sorting,
    handleSort,
  } = useDataGridOptions({
    initialFilterValues: FilterFormDefaultValues,
    arrayFilters: [ 'approvalStatus', 'validationStatus' ],
    initialSort: getDefaultCreatedAtSorting()
  })

  const {
    isExporting,
    handleExport,
    handleCancelExport
  } = useExport()

  const { data, isLoading } = useInvoices({
    page,
    perPage,
    filters,
    sorting: stringifySortItem(sorting),
  })

  const methods = useForm<OnFilterInvoicesParams>({
    resolver: yupResolver(FilterFormSchema),
    defaultValues: FilterFormDefaultValues,
    mode: 'all'
  })

  const onReset = useCallback(() => {
    methods.reset(FilterFormDefaultValues)
    setFilters(FilterFormDefaultValues)
  }, [])

  const columns: GridColDef<Invoice>[] = useMemo(() => [
    {
      field: 'externalId',
      headerName: t('id'),
      minWidth: 150,
      flex: 0.5,
    },
    {
      sortable: false,
      field: 'buyer',
      headerName: t('buyer'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => <AccountCell account={row.buyer} />,
    },
    {
      sortable: false,
      field: 'supplier',
      headerName: t('supplier'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => <AccountCell account={row.supplier} />,
    },
    {
      field: 'createdAt',
      headerName: t('created'),
      minWidth: 120,
      flex: 0.3,
      renderCell: ({ row }) => <DateCell date={row.createdAt}/>,
    },
    {
      field: 'amount',
      headerName: t('amount'),
      minWidth: 150,
      flex: 0.4,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }) => <CurrencyCell value={row.amount}/>,
    },
    {
      sortable: false,
      field: 'status',
      headerName: '',
      minWidth: 200,
      flex: 0.5,
      align: 'right',
      renderCell: ({ row }) => (
        <InvoiceStatus
          status={row.approvalStatus}
          validationStatus={row.validationStatus}
        />
      ),
    },
  ], [])

  const handleFilter = useCallback((params: OnFilterInvoicesParams) => {
    setPage(1)
    /**
     * We need to send always a new object to setFilters
     * React hook form mutates internally its fields state object and sends that reference as a param instead of aa new cloned object
     * */
    setFilters({ ...params })
  }, [])

  useEffect(() => {
    methods.reset(filters)
  }, [ filters, methods ])

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFilter)}>
              <Toolbar
                currentFilters={filters}
                filtersToWatch={[ 'approvalStatus', 'validationStatus' ]}
                onReset={onReset}
                searchPlaceholder={t('table.toolbar.searchTermPlaceholder', { ns: 'invoices' })}
                searchField="searchTerm"
                onExport={handleExport}
              >
                <InvoicesFilters />
              </Toolbar>
            </form>
          </FormProvider>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            columns={columns}
            onRowClick={({ row }) => setShowInvoiceDetails(row)}
            rows={data?.results || []}
            rowCount={data?.total || 0}
            page={page - 1}
            pageSize={perPage}
            onPageChange={(currentPage) => setPage(currentPage + 1)}
            onPageSizeChange={(newPerPage) => setPerPage(newPerPage)}
            sorting={sorting}
            handleSort={handleSort}
          />
        </Grid>
        {
          showInvoiceDetails &&
          <InvoiceDetails invoice={showInvoiceDetails} onClose={() => setShowInvoiceDetails(null)} />
        }
      </Grid>
      {isExporting && (
        <CSVExporter onClose={handleCancelExport} entity={Entity.INVOICES}/>
      )}
    </>
  )
}

export default InvoicesTable
