import React, { FC } from 'react'
import { Typography } from '@mui/material'

type AuthPageDescriptionProps = {
  description: string
}

const AuthPageDescription: FC<AuthPageDescriptionProps> = ({ description }) => {
  return (
    <Typography variant="body3" textAlign="center" component="p">
      {description}
    </Typography>
  )
}

export default AuthPageDescription
