import { useEffect, useRef } from 'react'
import ApiClient from '@/utils/api'
import { useConfig } from "@/utils/appContext";
import { CustomHeaders } from "@keo/shared-types";
require('eventsource/lib/eventsource-polyfill')

// @ts-ignore
const { EventSourcePolyfill } = window

type UseSSEParams = {
  url: string
  onUpdated: (data: any) => void
  onError: () => void
}

enum ReadyState {
  CLOSED = 2,
}

export default function useSSE(params: UseSSEParams) {
  // using useRef to prevent re-initialization of eventSource on dev mode
  // https://react.dev/reference/react/useEffect#my-effect-runs-twice-when-the-component-mounts
  const initialized = useRef(false)
  const config = useConfig()
  let eventSource: EventSource

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      eventSource = new EventSourcePolyfill(window.origin + params.url, {
        headers: {
          Authorization: `Bearer ${ApiClient.getAccessToken()}`,
          [ CustomHeaders.APP_CONTEXT ]: config.appContext,
        }
      })

      eventSource.onerror = (err: any) => {
        console.log(err)
        eventSource.close()
        params.onError()
      }

      eventSource.onmessage = (event: MessageEvent) => {
        if(eventSource.CLOSED === ReadyState.CLOSED) {
          eventSource.close()
        }

        if(event.data) {
          try {
            // Note: as the jobId is composed by contextId:jobId, we need to identify it,
            // otherwise we'll get an error on JSON.parse
            const isJobId = event.data.includes(`${config.appContext}:`)

            const data = isJobId
              ? event.data
              : JSON.parse(event.data)

            params.onUpdated(data)
          } catch (err) {
            console.log(err)
            params.onError()
          }
        }
      }
    }
    return () => {
      if(eventSource && !initialized.current) {
        eventSource.close()
      }
    }
  }, [ params.url ])
}
