"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HealthKeys = void 0;
var HealthKeys;
(function (HealthKeys) {
    HealthKeys["ACCOUNTS_DB"] = "accounts-db";
    HealthKeys["PAYMENTS_DB"] = "payments-db";
    HealthKeys["APP_DB"] = "app-db";
    HealthKeys["VAULT_DB"] = "vault-db";
    HealthKeys["REDIS"] = "redis";
    HealthKeys["CIRCLE"] = "circle";
    HealthKeys["GRAPES"] = "grapes";
    HealthKeys["ALGOD"] = "algod";
    HealthKeys["KMD"] = "kmd";
    HealthKeys["DOWNTIME_MODE"] = "downtime-mode";
    HealthKeys["KEO_BE"] = "keo-be";
})(HealthKeys = exports.HealthKeys || (exports.HealthKeys = {}));
