import { FC, PropsWithChildren } from 'react'
import { Grid } from '@mui/material'
import { Navigate, useLocation } from 'react-router-dom'

import { parseUrl } from '@keo/frontend/utils/url'
import { ContentContainer, EllipseBox, LogoBox, PageContainer } from './styles'
import { useSession } from '@/utils/session'
import { INDEX_ROUTE } from '@/utils/routes'

type AuthPageProps = {
  avoidRedirect?: boolean
}

const AuthPage: FC<PropsWithChildren<AuthPageProps>> = ({ avoidRedirect = false, children }) => {
  const { session } = useSession()
  const location = useLocation()


  if (session && !avoidRedirect) {
    const { redirectTo } = parseUrl(location.search).query
    return <Navigate to={redirectTo as string || INDEX_ROUTE}/>
  }

  return (
    <PageContainer>
      <EllipseBox/>
      <ContentContainer>
        <LogoBox>
          <img alt="Workeo logo" src="/logos/workeo_1024.png" height="100%"/>
        </LogoBox>
        <Grid container justifyContent="center">
          <Grid item xs={8} lg={5}>
            {children}
          </Grid>
        </Grid>
      </ContentContainer>
    </PageContainer>
  )
}

export default AuthPage
