import { FC, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { AccountType, Rating, Wallet } from '@keo/shared-types'
import { CurrencyCell, AddressCell, DataGrid, Toolbar, UnderlinedText, CSVExporter, DateCell } from '@/components'
import { OnFilterWalletsParams } from '@/types'
import { getWalletUrl } from '@/utils/routes'
import { enumOrEmpty, rangePositiveNumberOrEmpty } from '@/utils/schemas'
import Filters from './components/Filters'
import { yupLocale } from '@/utils/yupLocale'
import { useDataGridOptions } from '@/components/tables/hooks/useDataGridOptions'
import useExport from '@/components/hooks/useExport'
import { Entity } from '@/components/modals/CSVExporter'
import { useWallets } from '@/api/hooks'
import { stringifySortItem } from '@/utils/parsers'
import { getDefaultCreatedAtSorting } from '@/utils/sort'
import WalletWarningIcon from './components/WalletWarningIcon'

/**
 * Need to call this in the same file as the schema is defined
 * Tried to call this in App.tsx, but it didn't work
 */
yup.setLocale(yupLocale)

// Refine the schema once it's defined on the backend
const FilterFormSchema = yup.object({
  searchTerm: yup.string().trim().notRequired(),
  creditLineAmountFrom: rangePositiveNumberOrEmpty({ lessThanRef: yup.ref('creditLineAmountTo') }),
  creditLineAmountTo: rangePositiveNumberOrEmpty({ moreThanRef: yup.ref('creditLineAmountFrom') }),
  rating: enumOrEmpty(Rating),
  whitelisted: yup.string().notRequired(),
})

const FilterFormDefaultValues: OnFilterWalletsParams = {
  creditLineAmountFrom: '',
  creditLineAmountTo: '',
  rating: '',
  searchTerm: '',
  whitelisted: '',
}

type WalletsTableProps = {
  type: AccountType
}

const WalletsTable: FC<WalletsTableProps> = ({ type }) => {
  const { t } = useTranslation([ 'wallets', 'common' ])
  const navigate = useNavigate()
  const {
    page,
    perPage,
    filters,
    setPage,
    setPerPage,
    setFilters,
    sorting,
    handleSort,
  } = useDataGridOptions<OnFilterWalletsParams>({
    initialFilterValues: {
      ...FilterFormDefaultValues,
      accountType: type
    },
    initialSort: getDefaultCreatedAtSorting('wallet')
  })

  const {
    isExporting,
    handleExport,
    handleCancelExport
  } = useExport()

  const { data, isLoading } = useWallets({
    filters,
    pagination: { page, perPage },
    sorting: stringifySortItem(sorting)
  })

  const handleRowClick = useCallback((row: Wallet) => {
    navigate(getWalletUrl(row.id))
  }, [ navigate ])

  const methods = useForm<OnFilterWalletsParams>({
    resolver: yupResolver(FilterFormSchema, {}, { rawValues: true }),
    defaultValues: { ...FilterFormDefaultValues, ...filters },
    mode: 'all'
  })

  const onReset = useCallback(() => {
    methods.reset(FilterFormDefaultValues)
    setFilters({
      ...FilterFormDefaultValues,
      accountType: type
    })
  }, [ type ])

  useEffect(() => {
    methods.setValue('accountType', type)
    setFilters(prev => ({ ...prev, accountType: type }))
  }, [ type ])

  // TODO: add truncation feature to some columns if needed
  // Need to specifically set the account.<field> due to ambiguous column names in BE
  const columns: GridColDef<Wallet>[] = useMemo(() => ([
    {
      field: 'account.id',
      headerName: t('common:accountId'),
      flex: 0.5,
      minWidth: 120,
      renderCell: ({ row }) => <UnderlinedText>{row.account?.id || '-'}</UnderlinedText>
    },
    {
      field: 'address',
      headerName: t('table.address'),
      flex: 0.8,
      minWidth: 150,
      renderCell: ({ row }) => <AddressCell truncate={24} value={row.address}/>
    },
    {
      field: 'email',
      headerName: t('common:email'),
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.account?.email || '-'
    },
    {
      field: 'wallet.createdAt',
      headerName: t('common:created'),
      minWidth: 120,
      flex: 0.3,
      renderCell: ({ row }) => <DateCell date={row.createdAt}/>,
    },
    {
      field: 'account.creditLineAmount',
      headerName: t('table.creditLine'),
      align: 'right',
      headerAlign: 'right',
      minWidth: 150,
      renderCell: ({ row }) => <CurrencyCell value={row.account?.creditLineAmount || 0}/>
    },
    {
      // sorting balance is more complex since it can be either creditAvailable or currencyToken
      sortable: false,
      field: 'creditAvailable',
      headerName: t('common:creditAvailable'),
      align: 'right',
      headerAlign: 'right',
      minWidth: 160,
      renderCell: ({ row }) => <CurrencyCell value={row.balances.creditAvailable} />,
    },
    {
      sortable: false,
      field: 'currencyToken',
      headerName: t('common:walletBalance'),
      align: 'right',
      headerAlign: 'right',
      minWidth: 150,
      renderCell: ({ row }) => <CurrencyCell value={row.balances.currencyToken} />
    },
    {
      field: 'alerts',
      headerName: '',
      align: 'center',
      minWidth: 20,
      sortable: false,
      renderCell: ({ row }) => <WalletWarningIcon wallet={row}/>
    }
  ]), [])

  const handleFilter = (params: OnFilterWalletsParams) => {
    setPage(1)
    /**
     * We need to send always a new object to setFilters
     * React hook form mutates internally its fields state object and sends that reference as a param instead of aa new cloned object
     * */
    setFilters(prev => ({ ...prev, ...params }))
  }

  useEffect(() => {
    methods.reset(filters)
  }, [ filters, methods ])

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFilter)}>
              <Toolbar
                currentFilters={filters}
                filtersToWatch={[ 'creditLineAmountFrom', 'creditLineAmountTo', 'rating', 'whitelisted' ]}
                onReset={onReset}
                searchPlaceholder={t('table.toolbar.searchTermPlaceholder')}
                searchField="searchTerm"
                onExport={handleExport}
              >
                <Filters accountType={type} />
              </Toolbar>
            </form>
          </FormProvider>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            columns={columns}
            rows={data?.results || []}
            rowCount={data?.total || 0}
            page={page - 1}
            pageSize={perPage}
            getRowId={(row: Wallet) => row.address}
            onRowClick={({ row }) => handleRowClick(row as Wallet)}
            onPageChange={(currentPage) => setPage(currentPage + 1)}
            onPageSizeChange={(newPerPage) => setPerPage(newPerPage)}
            columnVisibilityModel={{
              rating: type === AccountType.BUYER,
              'account.creditLineAmount': type === AccountType.BUYER,
              creditAvailable: type === AccountType.BUYER,
              missedDueDate: type === AccountType.BUYER
            }}
            sorting={sorting}
            handleSort={handleSort}
          />
        </Grid>
      </Grid>
      {isExporting && (
        <CSVExporter onClose={handleCancelExport} entity={Entity.WALLETS}/>
      )}
    </>
  )
}

export default WalletsTable
