import { useCallback, useState } from 'react'

type UseExportParams = {
  onExport?: () => void
  onCancel?: () => void
}

export default function useExport(params?: UseExportParams) {
  const [ isExporting, setIsExporting ] = useState(false)

  const handleCancelExport = useCallback(() => {
    params?.onCancel?.()
    setIsExporting(false)
  }, [ params?.onCancel ])

  const handleExport = useCallback(() => {
    params?.onExport?.()
    setIsExporting(true)
  }, [ params?.onExport ])

  return {
    isExporting,
    handleCancelExport,
    handleExport,
  }
}
