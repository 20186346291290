import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { closePeraWalletSignTxnToast, PeraWalletConnect } from '@perawallet/connect'
import { SignedTxnResponse, TransactionEncoded } from '@keo/shared-types'
import { SignerTransaction } from '@perawallet/connect/dist/util/model/peraWalletModels'
import { decodeUnsignedTransaction, decodeSignedTransaction } from 'algosdk'

const peraWallet = new PeraWalletConnect({
  shouldShowSignTxnToast: false,
})

type ExternalWalletStore = {
  connect: () => Promise<void>
  disconnect: () => void
  closeSignToast: () => void
  isConnected: boolean
  isMobile: boolean
  address: string | null
  sign: (txns: TransactionEncoded[]) => Promise<SignedTxnResponse[]>
}

const ExternalWalletCtx = createContext<ExternalWalletStore | null>(null)

export const ExternalWalletProvider: FC<PropsWithChildren> = ({ children }) => {
  const [ accountAddress, setAccountAddress ] = useState<string | null>(null)
  const isConnectedToPeraWallet = useMemo(() => !!accountAddress, [ accountAddress ])
  const isMobile = useMemo(() => peraWallet.platform === 'mobile', [ isConnectedToPeraWallet ])

  const handleDisconnectWallet = useCallback(() => {
    peraWallet.disconnect()

    setAccountAddress(null)
  }, [])

  const handleConnectWallet = useCallback(async () => {
    try {
      const accounts = await peraWallet.connect()

      peraWallet.connector?.on('disconnect', handleDisconnectWallet)

      setAccountAddress(accounts[ 0 ])
    } catch (error: any) {
      if (error.data?.type !== 'CONNECT_MODAL_CLOSED') {
        console.log(error)
      }
    }
  }, [])

  const sign = useCallback(async (txns: TransactionEncoded[]): Promise<SignedTxnResponse[]> => {
    const txnsToSign: SignerTransaction[] = txns.map((txn) => ({
      txn: decodeUnsignedTransaction(Buffer.from(txn.txn, 'base64')),
    }))

    // PeraWallet returns a subset of the txns that are passed in.
    // It returns just the transactions actually signed by the wallet.
    const signedTxns = await peraWallet.signTransaction([ txnsToSign ])

    return signedTxns.map((signedTxn) => {
      const txn = decodeSignedTransaction(signedTxn)
      return {
        sTxn: Buffer.from(signedTxn).toString('base64'),
        txId: txn.txn.txID(),
      }
    })
  }, [ accountAddress ])

  useEffect(() => {
    // Reconnect to the session when the component is mounted
    peraWallet
      .reconnectSession()
      .then((accounts) => {
        peraWallet.connector?.on('disconnect', handleDisconnectWallet)

        if (accounts.length) {
          setAccountAddress(accounts[ 0 ])
        }
      })
      .catch((e) => console.log(e))
  }, [])

  const closeSignToast = useCallback(() => closePeraWalletSignTxnToast(), [])

  const externalWalletDetails = useMemo(() => ({
    connect: handleConnectWallet,
    disconnect: handleDisconnectWallet,
    isConnected: isConnectedToPeraWallet,
    isMobile,
    address: accountAddress,
    sign,
    closeSignToast,
  }), [ isConnectedToPeraWallet, accountAddress ])

  const memoizedChildren = useMemo(() => children, [ children ])

  return (
    <ExternalWalletCtx.Provider value={externalWalletDetails}>
      {memoizedChildren}
    </ExternalWalletCtx.Provider>
  )
}

export function useExternalWallet() {
  return useContext(ExternalWalletCtx) as ExternalWalletStore
}
