"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permissions = exports.Actions = exports.Modules = exports.AdminRolesHierarchy = exports.AdminRoles = exports.Roles = void 0;
var Roles;
(function (Roles) {
    Roles["SUPPLIER"] = "supplier";
    Roles["BUYER"] = "buyer";
    Roles["GUEST"] = "guest";
})(Roles = exports.Roles || (exports.Roles = {}));
var AdminRoles;
(function (AdminRoles) {
    AdminRoles["PROCESSOR_ADMIN"] = "processorAdmin";
    AdminRoles["PROCESSOR"] = "processor";
    AdminRoles["LENDER_ADMIN"] = "lenderAdmin";
    AdminRoles["LENDER"] = "lender";
})(AdminRoles = exports.AdminRoles || (exports.AdminRoles = {}));
exports.AdminRolesHierarchy = (_a = {},
    _a[AdminRoles.PROCESSOR_ADMIN] = 1,
    _a[AdminRoles.PROCESSOR] = 2,
    _a[AdminRoles.LENDER_ADMIN] = 3,
    _a[AdminRoles.LENDER] = 4,
    _a);
var Modules;
(function (Modules) {
    Modules["ACCOUNTS"] = "accounts";
    Modules["ACCOUNTS_SETTINGS"] = "accountsSettings";
    Modules["INVOICES"] = "invoices";
    Modules["LOANS"] = "loans";
    Modules["LENDING_POOL"] = "lendingPool";
    Modules["ADMIN_USERS"] = "adminUsers";
    Modules["USERS_SETTINGS"] = "usersSettings";
    Modules["AUTH_API_KEYS"] = "authApiKeys";
    Modules["SETTINGS"] = "settings";
    Modules["AUDIT_LOGS"] = "auditLogs";
    Modules["LOANS_OOS"] = "loansOos";
    Modules["TRANSACTIONS"] = "transactions";
    Modules["BANK_ACCOUNTS"] = "bankAccounts";
    Modules["DEPOSITS"] = "deposits";
    Modules["MONITORING"] = "monitoring";
    Modules["DELEGATION"] = "delegation";
})(Modules = exports.Modules || (exports.Modules = {}));
var Actions;
(function (Actions) {
    Actions["LIST"] = "list";
    Actions["VIEW"] = "view";
    Actions["EDIT"] = "edit";
    Actions["DELETE"] = "delete";
    Actions["CREATE"] = "create";
    Actions["APPROVE"] = "approve";
    Actions["PAY"] = "pay";
    Actions["BLOCK"] = "block";
    Actions["SET_TERMS"] = "setTerms";
    Actions["SEEN_DELEGATION_ACTIVATION"] = "seenDelegationActivation";
    Actions["EXPORT"] = "export";
    Actions["CASHOUT"] = "cashOut";
    Actions["MANAGE"] = "manage";
})(Actions = exports.Actions || (exports.Actions = {}));
exports.Permissions = (_b = {},
    _b[AdminRoles.PROCESSOR_ADMIN] = [
        "".concat(Modules.ACCOUNTS_SETTINGS, ":*"),
        "".concat(Modules.ACCOUNTS, ":*"),
        "".concat(Modules.ADMIN_USERS, ":*"),
        "".concat(Modules.AUDIT_LOGS, ":*"),
        "".concat(Modules.AUTH_API_KEYS, ":*"),
        "".concat(Modules.BANK_ACCOUNTS, ":*"),
        "".concat(Modules.INVOICES, ":*"),
        "".concat(Modules.LENDING_POOL, ":*"),
        "".concat(Modules.LOANS_OOS, ":*"),
        "".concat(Modules.LOANS, ":*"),
        "".concat(Modules.MONITORING, ":").concat(Actions.EDIT),
        "".concat(Modules.SETTINGS, ":*"),
        "".concat(Modules.TRANSACTIONS, ":*"),
        "".concat(Modules.USERS_SETTINGS, ":*"),
        "".concat(Modules.DELEGATION, ":*"),
    ],
    _b[AdminRoles.PROCESSOR] = [
        "".concat(Modules.ACCOUNTS_SETTINGS, ":*"),
        "".concat(Modules.ACCOUNTS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.BLOCK, ",").concat(Actions.EXPORT),
        "".concat(Modules.BANK_ACCOUNTS, ":").concat(Actions.VIEW),
        "".concat(Modules.INVOICES, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.EXPORT),
        "".concat(Modules.LOANS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW),
        "".concat(Modules.MONITORING, ":").concat(Actions.EDIT),
        "".concat(Modules.SETTINGS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW),
        "".concat(Modules.TRANSACTIONS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.EXPORT),
        "".concat(Modules.USERS_SETTINGS, ":*"),
        "".concat(Modules.DELEGATION, ":").concat(Actions.LIST, ",").concat(Actions.VIEW),
    ],
    _b[AdminRoles.LENDER_ADMIN] = [
        "".concat(Modules.ADMIN_USERS, ":*"),
        "".concat(Modules.BANK_ACCOUNTS, ":").concat(Actions.VIEW),
        "".concat(Modules.LENDING_POOL, ":").concat(Actions.CASHOUT, ",").concat(Actions.VIEW),
        "".concat(Modules.LOANS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.EXPORT),
        "".concat(Modules.TRANSACTIONS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW),
        "".concat(Modules.USERS_SETTINGS, ":*"),
    ],
    _b[AdminRoles.LENDER] = [
        "".concat(Modules.LOANS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.EXPORT),
        "".concat(Modules.TRANSACTIONS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW),
    ],
    _b[Roles.SUPPLIER] = [
        "".concat(Modules.ACCOUNTS_SETTINGS, ":*"),
        "".concat(Modules.ACCOUNTS, ":*"),
        "".concat(Modules.INVOICES, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.APPROVE),
        "".concat(Modules.BANK_ACCOUNTS, ":*"),
        "".concat(Modules.DEPOSITS, ":").concat(Actions.CREATE, ",").concat(Actions.LIST, ",").concat(Actions.VIEW),
        "".concat(Modules.LOANS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW),
        "".concat(Modules.TRANSACTIONS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW),
        "".concat(Modules.DELEGATION, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.CREATE, ",").concat(Actions.DELETE),
    ],
    _b[Roles.BUYER] = [
        "".concat(Modules.ACCOUNTS_SETTINGS, ":*"),
        "".concat(Modules.ACCOUNTS, ":*"),
        "".concat(Modules.BANK_ACCOUNTS, ":*"),
        "".concat(Modules.DEPOSITS, ":").concat(Actions.CREATE, ",").concat(Actions.LIST, ",").concat(Actions.VIEW),
        "".concat(Modules.INVOICES, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.APPROVE),
        "".concat(Modules.LOANS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW, ",").concat(Actions.PAY),
        "".concat(Modules.TRANSACTIONS, ":").concat(Actions.LIST, ",").concat(Actions.VIEW),
    ],
    _b[Roles.GUEST] = [
        "".concat(Modules.ACCOUNTS_SETTINGS, ":*"),
        "".concat(Modules.ACCOUNTS, ":").concat(Actions.SET_TERMS),
    ],
    _b);
