import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { Warning } from '@mui/icons-material'
import { Account, AccountStatus, AccountType, TargetAccountsSettings } from '@keo/shared-types'
import { OnFilterAccountsParams } from '@/types/forms'
import { DataGrid, UnderlinedText } from '@/components'
import { getAccountUrl } from '@/utils/routes'
import { useDataGridOptions } from '@/components/tables/hooks/useDataGridOptions'
import { getStrategyName } from '@/utils/strategy'
import AccountStatusChip from './components/AccountStatusChip'
import { useAccounts } from '@/api/hooks/accounts'
import { stringifySortItem } from '@/utils/parsers'
import { getDefaultCreatedAtSorting } from '@/utils/sort'

const NotVerifiedAccountsTable: FC = () => {
  const { t } = useTranslation([ 'common', 'accounts' ])
  const navigate = useNavigate()
  const {
    page,
    perPage,
    setPage,
    setPerPage,
    filters,
    sorting,
    handleSort,
  } = useDataGridOptions<OnFilterAccountsParams>({
    initialFilterValues: {
      accountType: AccountType.SUPPLIER,
      statuses: [ AccountStatus.NOT_VERIFIED ]
    },
    arrayFilters: [ 'statuses' ],
    initialSort: getDefaultCreatedAtSorting('account')
  })

  const { data, isLoading } = useAccounts({
    filters,
    pagination: { page, perPage },
    sorting: stringifySortItem(sorting)
  })

  const handleRowClick = useCallback((row: Account) => {
    navigate(getAccountUrl(row.id))
  }, [ navigate ])

  const columns: GridColDef<Account>[] = useMemo(() => [
    {
      // Need to specifically set the account.id field due to ambiguous column names in BE
      field: 'account.id',
      headerName: t('accountId'),
      flex: 0.8,
      minWidth: 120,
      renderCell: ({ row }) => <UnderlinedText>{row.id}</UnderlinedText>,
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'email',
      headerName: t('email'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('type'),
      minWidth: 120,
      flex: 0.1,
      valueFormatter: ({ value }) => t(value),
      sortable: false,
    },
    {
      field: 'statuses',
      headerName: t('status'),
      flex: 1,
      renderCell: ({ row }) => row.statuses?.map((status) => (
        <AccountStatusChip key={status} status={status} />
      )),
      sortable: false,
    },
    {
      field: 'paybackStrategy',
      sortable: false,
      headerName: t('paybackStrategy'),
      minWidth: 150,
      flex: 0.7,
      renderCell: ({ row }) => getStrategyName(
        row,
        TargetAccountsSettings.PAYBACK_STRATEGY_ID,
        t
      )
    },
    {
      field: 'processingFeeStrategy',
      sortable: false,
      headerName: t('processingFeeStrategy'),
      minWidth: 150,
      flex: 0.7,
      renderCell: ({ row }) => getStrategyName(
        row,
        TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID,
        t
      )
    },
    {
      field: 'initialised',
      headerName: '',
      align: 'center',
      minWidth: 20,
      sortable: false,
      renderCell: ({ row }) => !row.isInitialised && (
        <Tooltip title={t('table.tooltip.notInitialised', { ns: 'accounts' })} placement="top">
          <Warning color="warning" fontSize="small"/>
        </Tooltip>
      )
    }
  ], [])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <DataGrid
          loading={isLoading}
          columns={columns}
          columnVisibilityModel={{
            paybackStrategy: false,
            processingFeeStrategy: false,
          }}
          rows={data?.results || []}
          rowCount={data?.total || 0}
          page={page - 1}
          pageSize={perPage}
          getRowId={(row: Account) => row.id}
          onRowClick={({ row }) => handleRowClick(row as Account)}
          onPageChange={(currentPage) => setPage(currentPage + 1)}
          onPageSizeChange={(newPerPage) => setPerPage(newPerPage)}
          sorting={sorting}
          handleSort={handleSort}
        />
      </Grid>
    </Grid>
  )
}

export default NotVerifiedAccountsTable
