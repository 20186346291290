import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { useLocale } from '@/utils/l10n'

type CurrencyProps = {
  currency?: string
  value?: number | string
  colorize?: boolean
}

const CurrencyCell: FC<CurrencyProps> = ({ colorize = false, currency, value }) => {
  const { parseCurrencyAmount } = useLocale()
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value
  const color = colorize && typeof parsedValue === 'number' ?
    parsedValue < 0 ? 'error' : 'success.main'
    :
    undefined


  return parsedValue === undefined ? <> - </> : (
    <Typography variant="body4" align="right" justifySelf="flex-end" color={color}>
      {parseCurrencyAmount(parsedValue, currency)}
    </Typography>
  )
}

export default CurrencyCell
