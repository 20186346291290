import { TransactionSystemPeer } from '@keo/shared-types'
import { TFunction } from 'react-i18next'

export function getTransactionSystemPeer(fromTo: string, t: TFunction) {
  switch (fromTo) {
    case TransactionSystemPeer.LP:
      return t('transactions:LP')
    case TransactionSystemPeer.PROVIDER:
      return t('transactions:PROVIDER')
    default:
      return fromTo
  }
}
