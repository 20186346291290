import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { TransactionEvent } from '@keo/shared-types'
import SelectFilter from '@/components/forms/SelectFilter'
import { CurrencyRangeFilter, DateRangeFilter } from '@/components/forms/RangeFilter'

const TransactionsFilters: FC = () => {
  const { t } = useTranslation([ 'common', 'transactions' ])
  const [ isMounted, setIsMounted ] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const typeOptions = useMemo(() => [
    { value: TransactionEvent.CASH_DISCOUNT, label: t(`type.${TransactionEvent.CASH_DISCOUNT}`, { ns: 'transactions' }) },
    { value: TransactionEvent.DEPOSIT, label: t(`type.${TransactionEvent.DEPOSIT}`, { ns: 'transactions' }) },
    { value: TransactionEvent.PAY_DIRECT, label: t(`type.${TransactionEvent.PAY_DIRECT}`, { ns: 'transactions' }) },
    { value: TransactionEvent.PAY, label: t(`type.${TransactionEvent.PAY}`, { ns: 'transactions' }) },
    { value: TransactionEvent.PAYBACK, label: t(`type.${TransactionEvent.PAYBACK}`, { ns: 'transactions' }) },
    { value: TransactionEvent.WITHDRAW, label: t(`type.${TransactionEvent.WITHDRAW}`, { ns: 'transactions' }) },
  ], [ t ])

  // Temporary fix for animating issue
  if (!isMounted) {
    return null
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <SelectFilter
          label={t('type')}
          fieldName="type"
          options={typeOptions}
          allOptionText={t('all')}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CurrencyRangeFilter
          label={t('amount')}
          fromField="amountFrom"
          toField="amountTo"
          fromPlaceholder={t('from')}
          toPlaceholder={t('to')}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DateRangeFilter
          label={t('date')}
          fromField="createdDateFrom"
          toField="createdDateTo"
          fromPlaceholder={t('from')}
          toPlaceholder={t('to')}
        />
      </Grid>
    </Grid>
  )
}

export default TransactionsFilters
