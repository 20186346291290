import { useQuery } from 'react-query'
import { getAccounts } from '@/api/accounts'
import { GetAccountsParams } from '@/types'
import { ApiQueryKeys } from '@/api/hooks/queries'

export function useAccounts(params: GetAccountsParams) {
  const { pagination, filters, sorting } = params
  const { page, perPage } = pagination

  return useQuery(
    [ ApiQueryKeys.ACCOUNTS, filters, sorting, perPage, page ],
    () => getAccounts({
      pagination: {
        page,
        perPage
      },
      filters,
      sorting,
    }),
    { keepPreviousData: true }
  )
}
