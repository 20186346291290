import React, { FC, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, IconButton, InputAdornment, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { useCurrency, useLocale } from '@/utils/l10n'
import { FormInput } from '@/components'
import Actions from './components/Actions'
import Modal from './components/Modal'

const getSchema = (max: number) => yup.object({
  amount: yup.number().positive().max(max)
})

type WithdrawLendingPoolProps = {
  maxAmount: number
  onWithdraw: (amount: number) => void
  onClose: () => void
  isLoading: boolean
}

const WithdrawLendingPool: FC<WithdrawLendingPoolProps> = ({ maxAmount, onWithdraw, onClose, isLoading }) => {
  const { t } = useTranslation([ 'common', 'settings' ])
  const { currencySymbol } = useCurrency()
  const { parseCurrencyAmount } = useLocale()

  const methods = useForm<{ amount: number }>({
    resolver: yupResolver(getSchema(maxAmount)),
    defaultValues: {
      amount: 0
    }
  })

  const handleWithdraw = ({ amount }: { amount: number }) => {
    onWithdraw(amount)
  }

  const handleSetMaxAmount = useCallback(() => {
    methods.setValue('amount', maxAmount)
    methods.trigger('amount')
  }, [ maxAmount ])

  const handleMouseDownSetMax = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Modal
      open
      onClose={onClose}
      title={t(`settings:lendingPoolManagement.modal.withdraw.title`)}
      maxWidth="xs"
      actions={
        <Actions
          onCancel={onClose}
          onCancelLabel={t('cancel')}
          onSubmit={methods.handleSubmit(handleWithdraw)}
          onSubmitLabel={t(`settings:lendingPoolManagement.modal.withdraw.confirm`)}
          loading={isLoading}
        />
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleWithdraw)}>
          <Box mb={2}>
            <Typography variant="body2" color="text.secondary">
              {t('settings:lendingPoolManagement.modal.withdraw.description')}
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="caption" color="text.secondary">{t('amount')}</Typography>
          </Box>
          <FormInput
            control={methods.control}
            name="amount"
            size="small"
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={handleSetMaxAmount}
                  onMouseDown={handleMouseDownSetMax}
                  edge="end"
                >
                  <Typography variant="caption" color="text.secondary">{t('max')}</Typography>
                </IconButton>
              </InputAdornment>
            }}
            inputProps={{
              min: 0,
              max: maxAmount
            }}
            helperText={`${parseCurrencyAmount(maxAmount)} ${t('max')}`}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default WithdrawLendingPool
