import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { useLocale } from '@/utils/l10n'

type DateCellProps = {
  date: string | Date
  showTime?: boolean
}

const DateCell: FC<DateCellProps> = ({ date, showTime }) => {
  const { parseDate } = useLocale()

  return (
    <Typography variant="body3">{parseDate(date, showTime)}</Typography>
  )
}

export default DateCell
