import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { Box, InputAdornment, Typography } from '@mui/material'
import { FormInput } from '@/components'
import Actions from './components/Actions'
import Modal from './components/Modal'

const NewCreditLineSchema = yup.object({
  newCreditLine: yup.number().positive()
})

type UpdateCreditLineProps = {
  currentCreditLine: number
  onUpdateCreditLine: (creditLine: number) => void
  onClose: () => void
}

const UpdateCreditLine: FC<UpdateCreditLineProps> = ({ currentCreditLine, onUpdateCreditLine, onClose }) => {
  const { t } = useTranslation([ 'common', 'wallets' ])

  const methods = useForm<{ newCreditLine: number }>({
    resolver: yupResolver(NewCreditLineSchema),
    defaultValues: {
      newCreditLine: currentCreditLine
    }
  })

  const handleNewCreditLine = ({ newCreditLine }: { newCreditLine: number }) => {
    onUpdateCreditLine(newCreditLine)
  }

  return (
    <Modal
      open
      onClose={onClose}
      title={t(`modal.updateCreditLine.title`, { ns: 'wallets' })}
      maxWidth="xs"
      actions={
        <Actions
          onCancel={onClose}
          onCancelLabel={t('cancel')}
          onSubmit={methods.handleSubmit(handleNewCreditLine)}
          onSubmitLabel={t(`modal.updateCreditLine.confirm`, { ns: 'wallets' })}
        />
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleNewCreditLine)}>
          <Box mb={1}>
            <Typography variant="caption" color="text.secondary">{t('creditLine')}</Typography>
          </Box>
          <FormInput
            control={methods.control}
            name="newCreditLine"
            size="small"
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            inputProps={{
              min: 0
            }}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default UpdateCreditLine
