import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { differenceInDays, parseISO } from 'date-fns'
import { useLocale } from '@/utils/l10n'
import DateCell from './DateCell'

type DueDateCellProps = {
  dueDate: Date | string
  hideDaysPastDueDate?: boolean
}

const DueDateCell: FC<DueDateCellProps> = ({ dueDate, hideDaysPastDueDate = false }) => {
  const { t } = useTranslation('common')
  const { parseDate } = useLocale()
  const daysPastDueDate = useMemo(() => {
    return differenceInDays(Date.now(), dueDate instanceof Date ? dueDate : parseISO(dueDate))
  }, [ dueDate ])

  if (daysPastDueDate > 0 && !hideDaysPastDueDate) {
    return (
      <Box mb={0.5} display="flex" flexDirection="column">
        <Typography variant="body3" color="error.main">{parseDate(dueDate)}</Typography>
        <Typography
          variant="caption"
          color="error.main"
        >
          {t('daysPastDueDate', { count: daysPastDueDate })}
        </Typography>
      </Box>
    )
  }

  return <DateCell date={dueDate} />
}

export default DueDateCell
