import Api from '@/utils/api'
import { LoginParams, LoginResponse, RequestResetPasswordParams, ResetPasswordParams } from '@/types'

const API_AUTH_PREFIX = '/admin/auth'

export async function getSession() {
  const res = await Api.get<LoginResponse>(`${API_AUTH_PREFIX}/refreshToken`)
  Api.setAccessToken(res.data.adminAccessToken)
  return res.data.adminAccessToken
}

export async function login(params: LoginParams) {
  const res = await Api.post<LoginResponse, unknown>(`${API_AUTH_PREFIX}/signIn`, params)
  Api.setAccessToken(res.data.adminAccessToken)
  return res.data.adminAccessToken
}

export async function logout() {
  return Api.get(`${API_AUTH_PREFIX}/signOut`)
}

export async function requestResetPassword(params: RequestResetPasswordParams) {
  return Api.post(`${API_AUTH_PREFIX}/requestResetPassword`, params)
}

export async function resetPassword(params: ResetPasswordParams) {
  return Api.post(`${API_AUTH_PREFIX}/resetPassword`, params)
}
