import ApiClient from '@keo/frontend/utils/fetch'
import { dispatchCustomEvent } from '@keo/frontend/utils/browser'
import { GlobalEvent } from '@/types/events'

// TODO: Read from env vars
const F = new ApiClient({
  baseUrl: process.env.REACT_APP_API_URL,
  refreshTokenUrl: '/admin/auth/refreshToken',
  onBadGateway: () => dispatchCustomEvent(GlobalEvent.BAD_GATEWAY),
  onRefreshFail: () => dispatchCustomEvent(GlobalEvent.SESSION_REMOVE),
  onRefreshSuccess: () => {}
})

export default F
