import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ArrowForward } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'

type WidgetLinkProps = {
  label: string
  url: string
}

const WidgetLink: FC<WidgetLinkProps> = ({ label, url }) => {
  return (
    <Link to={url} style={{ textDecoration: 'none' }}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="body3" color="text.primary">
          {label}
        </Typography>
        <ArrowForward fontSize="small" />
      </Stack>
    </Link>
  )
}

export default WidgetLink