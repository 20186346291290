"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.staticPath = exports.ContextToStablecoinMap = exports.StablecoinToDecimals = void 0;
var currency_1 = require("./currency");
exports.StablecoinToDecimals = (_a = {},
    _a[currency_1.Stablecoin.QCAD] = 2,
    _a[currency_1.Stablecoin.USDC] = 6,
    _a);
exports.ContextToStablecoinMap = {
    abl: currency_1.Stablecoin.QCAD,
    keo: currency_1.Stablecoin.USDC,
};
exports.staticPath = '/api/files';
