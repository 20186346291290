import { FC, useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { CurrencyRangeFilter, DateRangeFilter } from '@/components/forms/RangeFilter'
import { useTranslation } from 'react-i18next'
import { LoanPayBackStatus } from '@keo/shared-types'
import SelectFilter from '@/components/forms/SelectFilter'

const Filters: FC = () => {
  const { t } = useTranslation([ 'loans', 'common' ])
  const [ isMounted, setIsMounted ] = useState(false)

  const statusOptions = useMemo(() => [
    { value: LoanPayBackStatus.ACTIVE, label: t(`paybackStatus.${LoanPayBackStatus.ACTIVE}`) },
    { value: LoanPayBackStatus.DIRECT_PAID, label: t(`paybackStatus.${LoanPayBackStatus.DIRECT_PAID}`) },
    { value: LoanPayBackStatus.PAID, label: t(`paybackStatus.${LoanPayBackStatus.PAID}`) },
    { value: LoanPayBackStatus.PENDING, label: t(`paybackStatus.${LoanPayBackStatus.PENDING}`) },
    { value: LoanPayBackStatus.REJECTED, label: t(`paybackStatus.${LoanPayBackStatus.REJECTED}`) },
  ], [ t ])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} lg={4}>
        <CurrencyRangeFilter
          label={t('common:amount')}
          fromField="invoiceAmountFrom"
          toField="invoiceAmountTo"
          fromPlaceholder={t('common:from')}
          toPlaceholder={t('common:to')}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DateRangeFilter
          label={t('common:createdDate')}
          fromField="createdDateFrom"
          toField="createdDateTo"
          fromPlaceholder={t('common:from')}
          toPlaceholder={t('common:to')}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DateRangeFilter
          label={t('common:dueDate')}
          fromField="dueDateFrom"
          toField="dueDateTo"
          fromPlaceholder={t('common:from')}
          toPlaceholder={t('common:to')}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <SelectFilter
          label={t('common:status')}
          fieldName="loanPayBackStatuses"
          options={statusOptions}
          multiple
        />
      </Grid>
    </Grid>
  )
}

export default Filters
