import { queryParamsToUrl } from '@keo/frontend/utils/url'
import { IAudit, PaginatedResult } from '@keo/shared-types'
import Api from '@/utils/api'
import { GetAuditLogsParams, GetAuditLogsRequestParams } from '@/types'
import { parseDateExcludingTime } from '@/utils/l10n'

const PATH_PREFIX = '/audits'

type GetAuditLogsResponse = PaginatedResult<IAudit>

const AUDIT_EXCLUSION_URLS = [
  '/api/account/wallets/setPassphraseSecurityApprovedTerms',
  '/api/account/wallets/setPasscodeApprovedTerms',
]

export async function getAuditLogs(params: GetAuditLogsParams): Promise<GetAuditLogsResponse> {
  const { filters, pagination, sorting } = params
  const { dateFrom, dateTo } = filters

  const queryParams: GetAuditLogsRequestParams = {
    ...pagination,
    ...dateFrom && { dateFrom: parseDateExcludingTime(dateFrom) },
    ...dateTo && { dateTo: parseDateExcludingTime(dateTo) },
    ...sorting && { sorting : params.sorting },
    excludeUrls: AUDIT_EXCLUSION_URLS,
  }

  const url = queryParamsToUrl(`${PATH_PREFIX}`, queryParams)
  const res = await Api.get<GetAuditLogsResponse>(url)

  return res.data
}
