import React, { FC, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { ILendingPool } from '@keo/shared-types'
import { FormInput } from '@/components'
import { UpdateLendingPoolParams } from '@/types'
import { updateLendingPoolState } from '@/api/settings'
import Actions from './components/Actions'
import Modal from './components/Modal'

type ManageLendingPoolProps = {
  onClose: () => void
  onSuccess: () => void
  initialValues: ILendingPool
}

const UpdateLendingPoolSchema = yup.object({
  payFee: yup.number().min(0).max(100).typeError('invalid').required() // TODO: make optional once we support updating more fields
})

const ManageLendingPool:FC<ManageLendingPoolProps> = ({ initialValues, onClose, onSuccess }) => {
  const { t } = useTranslation([ 'settings', 'common' ])
  const { enqueueSnackbar } = useSnackbar()

  const [ error, setError ] = useState(false)

  const methods = useForm<UpdateLendingPoolParams>({
    resolver: yupResolver(UpdateLendingPoolSchema),
    defaultValues: {
      payFee: initialValues.payFee
    }
  })

  const { mutate, isLoading } = useMutation(updateLendingPoolState, {
    onSuccess: () => {
      onSuccess()
      enqueueSnackbar(t('system.contractsManagement.successMessage'), { variant: 'success' })
      onClose()
    },
    onError: () => {
      setError(true)
    }
  })

  const handleUpdateLendingPool = useCallback((values: UpdateLendingPoolParams) => {
    setError(false)
    mutate(values)
  }, [])

  const handleCancel = useCallback(() => {
    setError(false)
    onClose()
  }, [])

  return (
    <Modal
      open
      onClose={onClose}
      title={t('system.contractsManagement.manage', { context: 'lendingPool' })}
      actions={
        <Actions
          onCancel={handleCancel}
          onCancelLabel={t('cancel', { ns: 'common' })}
          onSubmit={methods.handleSubmit(handleUpdateLendingPool)}
          onSubmitLabel={t('save', { ns: 'common' })}
          loading={isLoading}
        />
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleUpdateLendingPool)}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <FormInput
                control={methods.control}
                name="payFee"
                label={t('system.contractsManagement.payFee')}
                size="small"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: '0.01'
                }}
                onChange={() => methods.trigger()}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {error && (
        <Box mt={1}>
          <Typography color="error" variant="caption">{t('system.contractsManagement.error.lendingPool')}</Typography>
        </Box>
      )}
    </Modal>
  )
}

export default ManageLendingPool
