import { FC } from 'react'
import { Cancel, CheckCircle } from '@mui/icons-material'

type BooleanIconProps = {
  fontSize?: 'inherit' | 'medium' | 'small' | 'large'
  value: boolean
}

const BooleanIcon: FC<BooleanIconProps> = ({ fontSize = 'small', value }) => {
  if (value) {
    return (
      <CheckCircle color="success" fontSize={fontSize} />
    )
  }

  return (
    <Cancel color="error" fontSize={fontSize} />
  )
}

export default BooleanIcon
