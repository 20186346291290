"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KYCStatus = exports.MAX_CREDIT_LINE = exports.MIN_CREDIT_LINE = exports.AccountInitialisedFilter = exports.AccountStatus = exports.AccountType = void 0;
var AccountType;
(function (AccountType) {
    AccountType["BUYER"] = "buyer";
    AccountType["SUPPLIER"] = "supplier";
})(AccountType = exports.AccountType || (exports.AccountType = {}));
var AccountStatus;
(function (AccountStatus) {
    AccountStatus["NOT_VERIFIED"] = "notVerified";
    AccountStatus["VERIFIED"] = "verified";
    AccountStatus["ACTIVE"] = "active";
    AccountStatus["INACTIVE"] = "inactive";
    AccountStatus["SUSPENDED"] = "suspended";
    AccountStatus["BANNED"] = "banned";
    AccountStatus["TRANSACTED"] = "transacted";
})(AccountStatus = exports.AccountStatus || (exports.AccountStatus = {}));
var AccountInitialisedFilter;
(function (AccountInitialisedFilter) {
    AccountInitialisedFilter["ALL"] = "all";
    AccountInitialisedFilter["YES"] = "yes";
    AccountInitialisedFilter["NO"] = "no";
})(AccountInitialisedFilter = exports.AccountInitialisedFilter || (exports.AccountInitialisedFilter = {}));
exports.MIN_CREDIT_LINE = 0;
exports.MAX_CREDIT_LINE = 99999999.99;
var KYCStatus;
(function (KYCStatus) {
    KYCStatus["Uninitiated"] = "Uninitiated";
    KYCStatus["New"] = "New";
    KYCStatus["Pending"] = "Pending";
    KYCStatus["Review"] = "Review";
    KYCStatus["Escalated"] = "Escalated";
    KYCStatus["Approved"] = "Approved";
    KYCStatus["Rejected"] = "Rejected";
})(KYCStatus = exports.KYCStatus || (exports.KYCStatus = {}));
