import Api from '@/utils/api'
import { ProviderBankAccount, ProviderBankInstructions } from '@keo/shared-types'
import ApiClient from '@/utils/api'

const PATH_PREFIX = '/bankAccounts'

export async function getBankAccount(id: string): Promise<ProviderBankAccount> {
  const url = `${PATH_PREFIX}/${id}`
  const res = await Api.get<ProviderBankAccount>(url)

  return res.data
}

export async function getLendingPoolDepositInstructions(): Promise<ProviderBankInstructions> {
  const url = `${PATH_PREFIX}/lp/instructions`
  const res = await ApiClient.get<ProviderBankInstructions>(url)
  return res.data
}
