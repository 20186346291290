import React, { FC, PropsWithChildren, ReactNode, useCallback, useMemo, useState } from 'react'
import { Badge, Box, Button, Collapse, Grid, IconButton, Stack, Tooltip } from '@mui/material'
import { SystemUpdateAlt, Tune } from '@mui/icons-material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SearchInput from '@/components/forms/SearchInput'

export type ToolbarProps = {
  currentFilters?: Record<string, unknown>
  filtersToWatch?: string[]
  hideSearch?: boolean
  onReset?: () => void
  searchField?: string
  searchPlaceholder?: string
  onExport?: () => void
  extraActions?: ReactNode
}

function areFiltersApplied(filters: Record<string, unknown>, filtersToWatch?: string[]): boolean {
  return Object.entries(filters).some(([ filterKey, filterValue ]) => {
    if (filtersToWatch) {
      const isIncluded = filtersToWatch.includes(filterKey)

      // If the filter is not included in the list of filters to watch, we don't care about it
      if (!isIncluded) {
        return false
      }

      // If the filter is empty array, we don't care about it
      if (Array.isArray(filterValue) && filterValue.length === 0) {
        return false
      }

      // If the filter is a falsy value, we don't care about it
      return filtersToWatch.includes(filterKey) && !!filterValue
    }

    // If the filter is empty array, we don't care about it
    if (Array.isArray(filterValue) && filterValue.length === 0) {
      return false
    }

    return !!filterValue
  })
}

const Toolbar: FC<PropsWithChildren<ToolbarProps>> = ({
  children,
  currentFilters = {},
  filtersToWatch,
  hideSearch = false,
  onReset,
  searchField = '',
  searchPlaceholder = '',
  onExport,
  extraActions,
}) => {
  const { t } = useTranslation('common')
  const [ isExpanded, setIsExpanded ] = useState(false)

  const toggleAccordion = useCallback(() => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded)
  }, [])

  const { formState, watch } = useFormContext()

  const isApplyDisabled = useMemo(() => JSON.stringify(currentFilters) === JSON.stringify(watch()), [ currentFilters, watch() ])
  const hasFiltersApplied = useMemo(() => areFiltersApplied(currentFilters, filtersToWatch), [ currentFilters, filtersToWatch ])

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={8} lg={6}>
          {
            !hideSearch &&
              <SearchInput placeholder={searchPlaceholder} field={searchField} isSubmitDisabled={isApplyDisabled}/>
          }
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {onExport && (
              <Grid item>
                <Tooltip title={t('export')}>
                  <IconButton onClick={onExport}>
                    <SystemUpdateAlt/>
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {children && (
              <Grid item>
                <IconButton onClick={toggleAccordion}>
                  <Badge variant="dot" invisible={!hasFiltersApplied} color="primary">
                    <Tune/>
                  </Badge>
                </IconButton>
              </Grid>
            )}
            {extraActions}
          </Grid>
        </Grid>
      </Grid>
      {children && (
        <Collapse in={isExpanded} timeout={0} mountOnEnter unmountOnExit>
          <Box mt={3}>
            <Stack gap={3} direction="column">
              {children}
              <Stack gap={2} justifyContent="space-between" direction="row">
                <Button color="secondary" onClick={onReset} disabled={!hasFiltersApplied && !formState.isDirty}>
                  {t('filters.clear')}
                </Button>
                <Stack gap={2} direction="row">
                  <Button color="secondary" variant="outlined" onClick={toggleAccordion}>{t('close')}</Button>
                  <Button type="submit" disabled={isApplyDisabled}>{t('apply')}</Button>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Collapse>
      )}
    </>
  )
}

export default Toolbar
