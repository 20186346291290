export const APP_HEADER_HEIGHT = 74
export const DEFAULT_PAGE_SIZE = 10
export const ROWS_PER_PAGE_OPTIONS = [ 10, 25, 50 ]
export const DEFAULT_CURRENCY = 'USD'
export const DATE_FORMAT_DISPLAY = 'dd/MM/yyyy'
export const DATE_FORMAT_API = 'yyyy-MM-dd'
export const HOUR_MINUTE_FORMAT_DISPLAY = 'HH:mm'
export const TIMEZONE_FORMAT_DISPLAY = 'zzz'
export const PASSWORD_MIN_LENGTH = 8
export const CRON_EXPRESSIONS_REGEX = {
  [ HOUR_MINUTE_FORMAT_DISPLAY ]: /^([01][0-9]|2[0-3]):([0-5][0-9])$/
}

// TODO: replace with internal docs page when available
export const LENDING_POOL_DEPOSIT_CIRCLE_GUIDE =
  process.env.REACT_APP_LENDING_POOL_DEPOSIT_CIRCLE_GUIDE || 'https://help.circle.com/s/article-page?articleId=ka08b000000atZFAAY'

