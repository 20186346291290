"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapTransactionStatusFromWithdrawalStatus = exports.TransactionSystemPeer = exports.TransactionStatus = exports.Direction = exports.TransactionEvent = exports.TransactionKey = exports.TransactionType = void 0;
var payment_1 = require("../payment");
var TransactionType;
(function (TransactionType) {
    TransactionType["APP_DEPLOY"] = "APP_DEPLOY";
    TransactionType["APP_UPDATE"] = "APP_UPDATE";
    TransactionType["APP_CALL"] = "APP_CALL";
    TransactionType["APP_OPT_IN"] = "APP_OPT_IN";
    TransactionType["APP_CLEAR_STATE"] = "APP_CLEAR_STATE";
    TransactionType["ASSET_CREATE"] = "ASSET_CREATE";
    TransactionType["ASSET_OPT_IN"] = "ASSET_OPT_IN";
    TransactionType["ASSET_OPT_OUT"] = "ASSET_OPT_OUT";
    TransactionType["ASSET_TRANSFER"] = "ASSET_TRANSFER";
    TransactionType["ASSET_FREEZE"] = "ASSET_FREEZE";
    TransactionType["ASSET_DESTROY"] = "ASSET_DESTROY";
    TransactionType["PAYMENT"] = "PAYMENT";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
var TransactionKey;
(function (TransactionKey) {
    // Lending Pool
    TransactionKey["LP_CREATE"] = "LP_CREATE";
    TransactionKey["LP_UPDATE"] = "LP_UPDATE";
    TransactionKey["LP_OPT_IN"] = "LP_OPT_IN";
    TransactionKey["LP_CLEAR_STATE"] = "LP_CLEAR_STATE";
    // Lending Pool App Calls
    TransactionKey["LP_INIT"] = "LP_INIT";
    TransactionKey["LP_PAY"] = "LP_PAY";
    TransactionKey["LP_PAY_PARTIAL"] = "LP_PAY_PARTIAL";
    TransactionKey["LP_PAYBACK"] = "LP_PAYBACK";
    TransactionKey["LP_SET_CREDIT_LINE"] = "LP_SET_CREDIT_LINE";
    TransactionKey["LP_CASH_OUT"] = "LP_CASH_OUT";
    TransactionKey["LP_SET_IS_ENABLED"] = "LP_SET_IS_ENABLED";
    TransactionKey["LP_SET_MANAGER"] = "LP_SET_MANAGER";
    TransactionKey["LP_SET_OPERATOR"] = "LP_SET_OPERATOR";
    TransactionKey["LP_SET_LENDER"] = "LP_SET_LENDER";
    TransactionKey["LP_SET_FEE_SINK"] = "LP_SET_FEE_SINK";
    TransactionKey["LP_SET_LOAN_CONTRACT"] = "LP_SET_LOAN_CONTRACT";
    TransactionKey["LP_SET_WHITELIST"] = "LP_SET_WHITELIST";
    TransactionKey["LP_SET_PAY_FEE"] = "LP_SET_PAY_FEE";
    TransactionKey["LP_SET_PAYBACK_FEE"] = "LP_SET_PAYBACK_FEE";
    // Loan Contract
    TransactionKey["LC_CREATE"] = "LC_CREATE";
    TransactionKey["LC_UPDATE"] = "LC_UPDATE";
    TransactionKey["LC_OPT_IN"] = "LC_OPT_IN";
    TransactionKey["LC_CLEAR_STATE"] = "LC_CLEAR_STATE";
    // Loan Contract App Calls
    TransactionKey["LC_NEW_LOAN"] = "LC_NEW_LOAN";
    TransactionKey["LC_USE_LOAN"] = "LC_USE_LOAN";
    TransactionKey["LC_SET_LOAN_TOTAL"] = "LC_SET_LOAN_TOTAL";
    TransactionKey["LC_VERIFY_CLEAR"] = "LC_VERIFY_CLEAR";
    TransactionKey["LC_SET_MANAGER"] = "LC_SET_MANAGER";
    TransactionKey["LC_SET_ISSUER"] = "LC_SET_ISSUER";
    TransactionKey["LC_SET_IS_ENABLED"] = "LC_SET_IS_ENABLED";
    TransactionKey["LC_SET_RATING"] = "LC_SET_RATING";
    TransactionKey["LC_CLEAR_RATING"] = "LC_CLEAR_RATING";
    TransactionKey["LC_RESET_RATING"] = "LC_RESET_RATING";
    TransactionKey["LC_MIGRATE_LOAN"] = "LC_MIGRATE_LOAN";
    // DELEGATION Contract
    TransactionKey["DC_CREATE"] = "DC_CREATE";
    TransactionKey["DC_UPDATE"] = "DC_UPDATE";
    TransactionKey["DC_OPT_IN"] = "DC_OPT_IN";
    TransactionKey["DC_CLEAR_STATE"] = "DC_CLEAR_STATE";
    // Loan Contract App Calls
    TransactionKey["DC_VERIFY_CLEAR"] = "DC_VERIFY_CLEAR";
    TransactionKey["DC_VERIFY_WITHDRAWAL"] = "DC_VERIFY_WITHDRAWAL";
    TransactionKey["DC_VERIFY_SUPPLIER_APPROVAL"] = "DC_VERIFY_SUPPLIER_APPROVAL";
    TransactionKey["DC_SET_MANAGER"] = "DC_SET_MANAGER";
    TransactionKey["DC_SET_OPERATOR"] = "DC_SET_OPERATOR";
    TransactionKey["DC_SET_IS_ENABLED"] = "DC_SET_IS_ENABLED";
    // Whitelist Contract
    TransactionKey["WL_CREATE"] = "WL_CREATE";
    TransactionKey["WL_OPT_IN"] = "WL_OPT_IN";
    TransactionKey["WL_UPDATE"] = "WL_UPDATE";
    TransactionKey["WL_SET_MANAGER"] = "WL_SET_MANAGER";
    TransactionKey["WL_SET_VERIFIER"] = "WL_SET_VERIFIER";
    TransactionKey["WL_SET_OPERATOR"] = "WL_SET_OPERATOR";
    // Whitelist App Calls
    TransactionKey["WL_ADD"] = "WL_ADD";
    TransactionKey["WL_REMOVE"] = "WL_REMOVE";
    TransactionKey["WL_SUSPEND"] = "WL_SUSPEND";
    TransactionKey["WL_RESTORE"] = "WL_RESTORE";
    TransactionKey["WL_VERIFY"] = "WL_VERIFY";
    // KYC Token
    TransactionKey["KYC_DEPLOY"] = "KYC_DEPLOY";
    TransactionKey["KYC_OPT_IN"] = "KYC_OPT_IN";
    TransactionKey["KYC_GRANT"] = "KYC_GRANT";
    TransactionKey["KYC_REVOKE"] = "KYC_REVOKE";
    // Credit Token
    TransactionKey["CT_DEPLOY"] = "CT_DEPLOY";
    TransactionKey["CT_DISPENSE"] = "CT_DISPENSE";
    TransactionKey["CT_REVOKE"] = "CT_REVOKE";
    TransactionKey["CT_OPT_IN"] = "CT_OPT_IN";
    // Currency Token
    TransactionKey["CUR_DEPLOY"] = "CUR_DEPLOY";
    TransactionKey["CUR_OPT_IN"] = "CUR_OPT_IN";
    TransactionKey["CUR_DISPENSE"] = "CUR_DISPENSE";
    TransactionKey["CUR_TRANSFER"] = "CUR_TRANSFER";
    // Invoice Token
    TransactionKey["INV_CREATE"] = "INV_CREATE";
    TransactionKey["INV_DESTROY"] = "INV_DESTROY";
    TransactionKey["INV_OPT_IN"] = "INV_OPT_IN";
    TransactionKey["INV_TRANSFER"] = "INV_TRANSFER";
    TransactionKey["AUTO_FUND"] = "AUTO_FUND";
    TransactionKey["FUND"] = "FUND";
    TransactionKey["REFUND"] = "REFUND";
    TransactionKey["PAY"] = "PAY";
    TransactionKey["UNDEFINED"] = "UNDEFINED";
})(TransactionKey = exports.TransactionKey || (exports.TransactionKey = {}));
var TransactionEvent;
(function (TransactionEvent) {
    TransactionEvent["CASH_DISCOUNT"] = "CASH_DISCOUNT";
    TransactionEvent["DEPOSIT"] = "DEPOSIT";
    TransactionEvent["WITHDRAW"] = "WITHDRAW";
    TransactionEvent["PAY"] = "PAY";
    TransactionEvent["PAYBACK"] = "PAYBACK";
    TransactionEvent["PAY_DIRECT"] = "PAY_DIRECT";
})(TransactionEvent = exports.TransactionEvent || (exports.TransactionEvent = {}));
var Direction;
(function (Direction) {
    Direction["INCOMING"] = "INCOMING";
    Direction["OUTGOING"] = "OUTGOING";
})(Direction = exports.Direction || (exports.Direction = {}));
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["PENDING"] = "PENDING";
    TransactionStatus["COMPLETED"] = "COMPLETED";
    TransactionStatus["FAILED"] = "FAILED";
})(TransactionStatus = exports.TransactionStatus || (exports.TransactionStatus = {}));
var TransactionSystemPeer;
(function (TransactionSystemPeer) {
    TransactionSystemPeer["LP"] = "LP";
    TransactionSystemPeer["PROVIDER"] = "PROVIDER";
})(TransactionSystemPeer = exports.TransactionSystemPeer || (exports.TransactionSystemPeer = {}));
exports.MapTransactionStatusFromWithdrawalStatus = (_a = {},
    _a[payment_1.WithdrawalStatus.PROCESSING] = TransactionStatus.PENDING,
    _a[payment_1.WithdrawalStatus.PROCESSED] = TransactionStatus.PENDING,
    _a[payment_1.WithdrawalStatus.COMPLETED] = TransactionStatus.COMPLETED,
    _a[payment_1.WithdrawalStatus.FAILED] = TransactionStatus.FAILED,
    _a);
