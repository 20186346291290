import React, { FC } from 'react'
import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'

type ActionsProps = {
  isErrorAction?: boolean
  onCancel: () => void
  onCancelLabel?: string
  onSubmit: (...args: any) => void
  onSubmitLabel?: string
  loading?: boolean
}

const Actions: FC<ActionsProps> = ({ isErrorAction, onSubmit, onSubmitLabel, onCancel, onCancelLabel, loading = false }) => {
  const { t } = useTranslation('common', { keyPrefix: 'modal' })

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button fullWidth size="large" variant="outlined" color="secondary" onClick={onCancel} disabled={loading}>
          {onCancelLabel || t('cancel')}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          color={isErrorAction ? 'error' : 'primary'}
          onClick={onSubmit}
          loading={loading}
          loadingPosition="start"
        >
          {onSubmitLabel || t('confirm')}
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default Actions
