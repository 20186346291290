import React, { FC } from 'react'
import { Edit } from '@mui/icons-material'
import { IconButton } from '@keo/frontend/components/Button'
import { useTranslation } from 'react-i18next'

type EditIconButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  tooltip?: string
}

const EditIconButton:FC<EditIconButtonProps> = ({ onClick, tooltip }) => {
  const { t } = useTranslation('common')
  return (
    <IconButton onClick={onClick} tooltip={tooltip || t('edit')}>
      <Edit/>
    </IconButton>
  )
}

export default EditIconButton
