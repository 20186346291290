import { useQuery } from 'react-query'
import { ApiQueryKeys } from '@/api/hooks/queries'
import { getLoans } from '@/api/loans'
import { GetLoansParams } from '@/types'
import { LoanPayBackStatus } from '@keo/shared-types'

export const useLoans = (params: GetLoansParams) => {
  const { pagination, filters, sorting } = params
  const { page, perPage } = pagination

  return useQuery(
    [ ApiQueryKeys.LOANS, filters, sorting, perPage, page ],
    () => getLoans({
      pagination: {
        page,
        perPage
      },
      filters,
      sorting,
    }),
    { keepPreviousData: true }
  )
}

export const useLatestLoans = ({ itemsToShow }: { itemsToShow: number }) => {
  return useQuery(
    [ ApiQueryKeys.LOANS, ApiQueryKeys.LATEST ],
    () => getLoans({
      pagination: {
        page: 1,
        perPage: itemsToShow,
      },
      filters: {
        loanPayBackStatuses: [ LoanPayBackStatus.ACTIVE, LoanPayBackStatus.PAID ],
      },
      sorting: '-loan.createdAt',
    })
  )
}
