import * as yup from 'yup'
import Reference from 'yup/lib/Reference'
import { addYears } from 'date-fns'

type RangeSchema = {
  lessThanRef?: Reference<number>
  moreThanRef?: Reference<number>
}

/**
 * This schema is used to validate a number that can be empty
 * In case the value is empty, it will return a string
 * In case the value is a number, it will return a number that needs to be:
 * - less than the lessThan reference value (if defined)
 * - and more than the moreThan reference value (if defined)
 * <br/>
 *
 * @param lessThanRef - Reference to a number that the value must be less than
 * @param moreThanRef - Reference to a number that the value must be more than
 * @returns yup schema
 *
 * @example
 * const schema = yup.object().shape({
 *   min: rangePositiveNumberOrEmpty({ lessThanRef: yup.ref('max') }),
 *   max: rangePositiveNumberOrEmpty({ moreThanRef: yup.ref('min') })
 * })
 *
 * schema.validate({ min: 10, max: 20 }) // valid
 * schema.validate({ min: 20, max: 10 }) // invalid
 * schema.validate({ min: 10, max: '' }) // valid
 * schema.validate({ min: '', max: 10 }) // valid
 * schema.validate({ min: '', max: '' }) // valid
 **/
export const rangePositiveNumberOrEmpty = ({ lessThanRef, moreThanRef }: RangeSchema = {}) => yup.lazy((value) => {
  if (value === '') return yup.string()

  return yup.number()
    .when([ (lessThanRef?.key || 'dummyKey') ], {
      is: (val: any) => val !== undefined && val !== '',
      then: yup.number().max(lessThanRef || Number.MAX_SAFE_INTEGER).min(0),
      otherwise: yup.number().min(0)
    })
    .when([ (moreThanRef?.key || 'dummyKey') ], {
      is: (val: any) => val !== undefined && val !== '',
      then: yup.number().min(moreThanRef || 0).min(0),
      otherwise: yup.number().min(0)
    })
})

export const enumOrEmpty = <T extends {}>(enumObject: T) => 
  yup.lazy(
    (value) =>
      (value === '' ?
        yup.string() :
        yup.mixed<T>().oneOf(Object.values(enumObject)
      )
    )
  )
export const Enum = <T extends {}>(enumObject: T) => yup.mixed<T>().oneOf(Object.values(enumObject))

/**
 * This schema is used to validate a date that can be empty
 * In case the value is empty, it will return a string
 * In case the value is a date, it will return a date that needs to be:
 * - less than the lessThan reference value (if defined)
 * - and more than the moreThan reference value (if defined)
 * <br/>
 *
 * @param lessThanRef - Reference to a date that the value must be less than
 * @param moreThanRef - Reference to a date that the value must be more than
 * @returns yup schema
 *
 * @example
 * const schema = yup.object().shape({
 *   min: rangeDateOrEmpty({ lessThanRef: yup.ref('max') }),
 *   max: rangeDateOrEmpty({ moreThanRef: yup.ref('min') })
 * })
 *
 * schema.validate({ min: new Date(), max: new Date() }) // valid
 * schema.validate({ min: new Date(), max: '' }) // valid
 * schema.validate({ min: '', max: new Date() }) // valid
 * schema.validate({ min: '', max: '' }) // valid
 * schema.validate({ min: new Date(), max: new Date(0) }) // invalid
 * schema.validate({ min: new Date(1), max: new Date(0) }) // invalid
 **/
export const rangeDateOrEmpty = ({ lessThanRef, moreThanRef }: RangeSchema = {}) => yup.lazy((value) => {
  if (!value || value === '') return yup.string().nullable()

  return yup.date()
    .when([ (lessThanRef?.key || 'dummyKey') ], {
      is: (val: any) => val !== undefined && val !== '',
      then: yup.date().max(lessThanRef || addYears(Date.now(), 2)),
      otherwise: yup.date()
    })
    .when([ (moreThanRef?.key || 'dummyKey') ], {
      is: (val: any) => val !== undefined && val !== '',
      then: yup.date().min(moreThanRef || new Date(0)),
      otherwise: yup.date()
    })
})

export function isValidURL() {
  return yup.string().required().test(
    'url',
    'url',
    (value) =>
      {
        if (!value) {
          return false
        }
        try {
          // construct the URL object and force it to throw if the URL is invalid
          new URL(value)
          // URL constructor does not throw an error for urls without protocol
          // Therefore, checking separately
          return /^(http|https):\/\//.test(value)
        } catch (_) {
          return false
        }
      }
  );
}
