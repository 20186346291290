import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import { AccountStatus } from '@keo/shared-types'

type AccountStatusChipProps = {
  status: AccountStatus
}

function getChipColor(status: AccountStatus) {
  switch (status) {
    case AccountStatus.VERIFIED:
      return 'success'
    case AccountStatus.ACTIVE:
      return 'info'
    case AccountStatus.INACTIVE:
      return 'warning'
    case AccountStatus.NOT_VERIFIED:
    case AccountStatus.BANNED:
    case AccountStatus.SUSPENDED:
      return 'error'
    default:
      return 'info'
  }
}

const AccountStatusChip: FC<AccountStatusChipProps> = ({ status }) => {
  const { t } = useTranslation('accounts')
  return (
    <Chip key={status} label={t(`status.${status}`)} variant="filled" size="small" color={getChipColor(status)} sx={{ mr: 0.5 }} />
  )
}

export default AccountStatusChip
