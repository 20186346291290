import React, { FC, PropsWithChildren } from 'react'
import { AdminRoles } from '@keo/shared-types'
import { useSession } from '@/utils/session'

type RoleProtectedProps = {
  children?: React.ReactNode,
  role?: AdminRoles[],
  fallback?: React.ReactNode
}

const RoleProtected: FC<PropsWithChildren<RoleProtectedProps>> = ({ role, fallback = null, children }) => {
  const { session } = useSession()

  // If no children, return null
  if (!children) return null

  // If role it not needed, return children
  if (!role) return <>{children}</>

  // If session does not exist, return fallback
  if (!session) {
    return <>{fallback}</>
  }

  // User does not have the required role, return fallback
  if (!role.includes(session.adminRole)) {
    return <>{fallback}</>
  }

  // User has the required role, return children
  return <>{children}</>
}

export default RoleProtected
