import { useQuery } from 'react-query'
import { getAuditLogs } from '@/api/audit'
import { GetAuditLogsParams } from '@/types'
import { ApiQueryKeys } from '@/api/hooks/queries'

export const useAuditLogs = (params: GetAuditLogsParams) => {
  const { pagination, filters, sorting } = params
  const { page, perPage } = pagination

  return useQuery(
    [ ApiQueryKeys.AUDIT_LOGS, filters, sorting, perPage, page ],
    () => getAuditLogs({
      pagination: {
        page,
        perPage
      },
      filters,
      sorting,
    }),
    { keepPreviousData: true }
  )
}
