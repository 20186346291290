import { useQuery } from 'react-query'
import { ApiQueryKeys } from '@/api/hooks/queries'
import { getLendingPoolDepositInstructions } from '@/api/bankAccounts'

export function useLendingPoolDepositInstructions() {
  return useQuery(
    [ ApiQueryKeys.LENDING_POOL_DEPOSIT_INSTRUCTIONS ],
    getLendingPoolDepositInstructions,
  )
}
