"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocalStorageItem = exports.setLocalStorageItem = exports.dispatchCustomEvent = void 0;
var dispatchCustomEvent = function (eventName, data) {
    window.dispatchEvent(new CustomEvent(eventName, { detail: __assign({ latestUpdate: Date.now() }, data) }));
};
exports.dispatchCustomEvent = dispatchCustomEvent;
function setLocalStorageItem(key, value) {
    try {
        localStorage.setItem(key, value);
    }
    catch (e) {
        console.error('Error setting localStorage item');
    }
}
exports.setLocalStorageItem = setLocalStorageItem;
function getLocalStorageItem(key) {
    try {
        return localStorage.getItem(key);
    }
    catch (e) {
        console.error('Error getting localStorage item');
        return null;
    }
}
exports.getLocalStorageItem = getLocalStorageItem;
