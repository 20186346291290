import React, { FC } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { DateInput } from '@/components'

type CustomRangeFilterProps = {
  label: string,
  fromField: string,
  fromPlaceholder?: string,
  toField: string,
  toPlaceholder?: string,
}

const DateRangeFilter: FC<CustomRangeFilterProps> = ({
  label,
  fromField,
  fromPlaceholder,
  toField,
  toPlaceholder
}) => {
  const theme = useTheme()
  const { trigger } = useFormContext()

  return (
    <>
      <Box mb={1} ml={2}>
        <Typography variant="caption" color="text.secondary">{label}</Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <DateInput name={fromField} onFieldChange={() => trigger(toField)} placeholder={fromPlaceholder}/>
        </Grid>
        <Grid item xs={1}>
          <Typography
            variant="caption"
            color="text.secondary"
            textAlign="center"
            sx={{ marginTop: theme.spacing(2), display: 'block' }}
          >
            {/* emdash character */}
            &#8212;
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <DateInput name={toField} onFieldChange={() => trigger(fromField)} placeholder={toPlaceholder}/>
        </Grid>
      </Grid>
    </>
  )
}

export default DateRangeFilter
