import { Component, ReactNode } from 'react'
import { captureException } from '@sentry/react'

interface Props {
  children: ReactNode
  fallback?: ReactNode
}

class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false
  }

  getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error('An error occured in a component')
    console.log(error)
    captureException(error)
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return this.props.fallback || null
    } else {
      return <>{this.props.children}</>
    }
  }
}

export default ErrorBoundary
