import { FC, ReactNode } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Widget from './Widget'
import { parsePercentage } from '@/utils/l10n'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

type DataCardProps = {
  comparisson?: number
  condensed?: boolean
  icon?: ReactNode
  isLoading: boolean
  label: string
  alignValue?: 'left' | 'right'
  previousValue?: string
  value?: string
}

const DataCard: FC<DataCardProps> = ({
  alignValue = 'left',
  comparisson,
  condensed,
  icon,
  isLoading,
  label,
  previousValue,
  value,
}) => {
  return (
    <Widget condensed={condensed} hasData={!!value} isLoading={isLoading}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        sx={{ px: 1, height: '100%', width: '100%' }}>
        {icon && (
          <Grid item>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'background.paper',
                borderRadius: 4,
                display: 'flex',
                height: 58,
                justifyContent: 'center',
                marginRight: 4,
                width: 58,
              }}>
              {icon}
            </Box>
          </Grid>
        )}
        <Grid item flex={1}>
          <Grid container direction="column" spacing={1}>
            {/* Icon */}
            <Grid item>
              <Typography variant="body3">{label}</Typography>
            </Grid>
            {/* Content */}
            <Grid item container spacing={1}>
              {/* Comparisson */}
              {
                comparisson !== undefined && (
                  <Grid container item flex={1} alignItems="center" flexWrap="nowrap">
                    {
                      comparisson < 0 ?
                        <ArrowDropDown fontSize="small" color="error" />
                        :
                        <ArrowDropUp fontSize="small" color="success" />
                    }
                    <Typography
                      align={alignValue}
                      color={comparisson < 0 ? 'error.main' : 'success.main'}
                      variant="body2"
                    >
                      {parsePercentage(Math.abs(comparisson), false, 2)}
                    </Typography>
                  </Grid>
                )
              }
              {/* Value */}
              <Grid item container direction="column" flex={1} spacing={1}>
                <Grid item>
                  <Typography variant="h2" align={alignValue}>
                    {value}
                  </Typography>
                </Grid>
                {
                  previousValue &&
                  <Grid item>
                    <Typography variant="h4" color="text.secondary" align={alignValue}>
                      {previousValue}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Widget>
  )
}

export default DataCard
