"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WithdrawalStatus = exports.PaybackStrategySteps = exports.BulkTransactionSignerActions = exports.TransactionSignerActions = void 0;
var TransactionSignerActions;
(function (TransactionSignerActions) {
    TransactionSignerActions["PAY_INVOICE"] = "PAY_INVOICE";
    TransactionSignerActions["PAY_BACK_LOAN"] = "PAY_BACK_LOAN";
    TransactionSignerActions["SUPPLIER_APPROVAL"] = "SUPPLIER_APPROVAL";
})(TransactionSignerActions = exports.TransactionSignerActions || (exports.TransactionSignerActions = {}));
var BulkTransactionSignerActions;
(function (BulkTransactionSignerActions) {
    BulkTransactionSignerActions["SUPPLIER_APPROVAL"] = "SUPPLIER_APPROVAL";
})(BulkTransactionSignerActions = exports.BulkTransactionSignerActions || (exports.BulkTransactionSignerActions = {}));
var PaybackStrategySteps;
(function (PaybackStrategySteps) {
    PaybackStrategySteps["FEE"] = "FEE";
    PaybackStrategySteps["INTEREST"] = "INTEREST";
    PaybackStrategySteps["PRINCIPAL"] = "PRINCIPAL";
})(PaybackStrategySteps = exports.PaybackStrategySteps || (exports.PaybackStrategySteps = {}));
var WithdrawalStatus;
(function (WithdrawalStatus) {
    WithdrawalStatus["PROCESSING"] = "processing";
    // This status is used when the payout request was sent to Circle
    WithdrawalStatus["PROCESSED"] = "processed";
    // This status is used when the payout was completed by Circle (money is in the bank account)
    WithdrawalStatus["COMPLETED"] = "completed";
    // This status is used when the transfer/payout failed
    WithdrawalStatus["FAILED"] = "failed";
})(WithdrawalStatus = exports.WithdrawalStatus || (exports.WithdrawalStatus = {}));
