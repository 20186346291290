import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import loadable from '@loadable/component'
import { Loan } from '@keo/shared-types'
import { CurrencyCell, DataGrid, DateCell, Widget } from '@/components'
import { useLatestLoans } from '@/api/hooks'
const LoanDetails = loadable(() => import('@/components/modals/LoanDetails'))

const ITEMS_TO_SHOW = 4

const LatestLoansWidget: FC = () => {
  const { t } = useTranslation([ 'common', 'dashboard' ])
  const [ loanDetails, setLoanDetails ] = useState<Loan | null>(null)

  const handleCloseLoanDetails = useCallback(() => {
    setLoanDetails(null)
  }, [])

  const handleRowClick = useCallback((row: Loan) => {
    setLoanDetails(row)
  }, [])

  const { data, isLoading } = useLatestLoans({ itemsToShow: ITEMS_TO_SHOW })

  const columns: GridColDef<Loan>[] = useMemo(() => ([
    {
      field: 'externalId',
      headerName: t('id'),
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'supplier',
      headerName: t('supplier'),
      flex: 2,
      sortable: false,
      valueGetter: ({ row }) => row.invoice.supplier?.account.name
    },
    {
      field: 'createdDate',
      headerName: t('createdDate'),
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => <DateCell date={row.createdAt} />
    },
    {
      field: 'amount',
      headerName: t('amount'),
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      renderCell: ({ row }) => {
        const total = Number(row.fees) + Number(row.interest) + Number(row.principal)
        return <CurrencyCell value={total}/>
      }
    }
  ]), [])
  return (
    <Widget title={t('latestLoans', { ns: 'dashboard' })} isLoading={isLoading} hasData={!!data?.total}>
      <DataGrid
        columns={columns}
        rows={data?.results || []}
        rowCount={data?.total || 0}
        pageSize={ITEMS_TO_SHOW}
        onRowClick={({ row }) => handleRowClick(row as Loan)}
        hideFooter
      />
        {!!loanDetails && (
          <LoanDetails loan={loanDetails} onClose={handleCloseLoanDetails}/>
        )}
    </Widget>
  )
}

export default LatestLoansWidget
