import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Warning } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { Wallet } from '@keo/shared-types'

type WalletWarningIconProps = {
  wallet: Wallet
}

function getWarningMessageKey(wallet: Wallet): string {
  const { whitelisted } = wallet

  if (!whitelisted) {
    return 'table.tooltip.whitelisted'
  }

  return 'table.tooltip.missedDueDate'
}

const WalletWarningIcon: FC<WalletWarningIconProps> = ({ wallet }) => {
  const { t } = useTranslation([ 'wallets' ])
  
  if (wallet.missedDueDatesCount === 0 && wallet.whitelisted) {
    return null
  }

  return (
    <Tooltip title={t(getWarningMessageKey(wallet))} placement="top">
      <Warning color="error" fontSize="small"/>
    </Tooltip>
  )
}

export default WalletWarningIcon