import React, { FC } from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Modal from './components/Modal'
import Actions from './components/Actions'

type ConfirmProps = {
  isErrorAction?: boolean
  title: string
  onClose: () => void
  onCloseLabel?: string
  onSubmit: () => void
  onSubmitLabel?: string
  confirmText?: string
  error?: boolean
  errorText?: string
  loading?: boolean
  sx?: SxProps
}

const Confirm: FC<ConfirmProps> = ({
  isErrorAction,
  onClose,
  onSubmit,
  onCloseLabel,
  onSubmitLabel,
  title,
  confirmText,
  loading = false,
  error = false,
  errorText,
  sx,
}) => {
  const { t } = useTranslation('common')

  return (
    <Modal
      open
      onClose={onClose}
      title={title}
      maxWidth="xs"
      loading={loading}
      sx={sx}
      actions={
        <Actions
          isErrorAction={isErrorAction}
          loading={loading}
          onSubmit={onSubmit}
          onCancel={onClose}
          onCancelLabel={onCloseLabel || t('cancel')}
          onSubmitLabel={onSubmitLabel || t('confirm')}
        />
      }
    >
      <Typography>{confirmText || t('confirmText')}</Typography>
      {error && errorText && (
        <Box mt={1}>
          <Typography color="error" variant="caption">{errorText}</Typography>
        </Box>
      )}
    </Modal>
  )
}

export default Confirm
