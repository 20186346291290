import { Box, styled } from '@mui/material'

export const PageContainer = styled('div')(({ theme }) => ({
  height: '100vh',
  background: theme.palette.background.default,
  margin: '0 auto'
}))

const LOGO_SIZE_DEFAULT = 120
const LOGO_SIZE_LARGE = 160

export const ContentContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  marginLeft: `calc(40vw - ${LOGO_SIZE_DEFAULT / 2}px)`,
  zIndex: 1,
  [ theme.breakpoints.up('md') ]: {
    marginLeft: `calc(40vw - ${LOGO_SIZE_LARGE / 2}px)`
  }
}))

export const EllipseBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  height: '150vh',
  top: '-25vh',
  width: '120vw',
  left: `-80vw`,
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main
}))

export const LogoBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: LOGO_SIZE_DEFAULT,
  [ theme.breakpoints.up('md') ]: {
    height: LOGO_SIZE_LARGE
  }
}))
