import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { AdminRoles, IAudit } from '@keo/shared-types'
import { DateCell, DataGrid, Toolbar, SettingsHeader } from '@/components'
import { useDataGridOptions } from '@/components/tables/hooks/useDataGridOptions'
import EventMessage from './components/EventMessage'
import { rangeDateOrEmpty } from '@/utils/schemas'
import { OnFilterAuditLogsParams } from '@/types'
import AuditLogsFilters from './components/AuditLogsFilters'
import { stringifySortItem } from '@/utils/parsers'
import { useRoleProtection } from '@/utils/routes'
import { getDefaultCreatedAtSorting } from '@/utils/sort'
import { useAuditLogs } from '@/api/hooks/auditLogs'

const FilterDefaultValues = {
  dateFrom: '',
  dateTo: '',
}

const FilterSchema = yup.object({
  dateFrom: rangeDateOrEmpty({ lessThanRef: yup.ref('dateTo') }),
  dateTo: rangeDateOrEmpty({ moreThanRef: yup.ref('dateFrom') }),
})

const AuditLogs: FC = () => {
  useRoleProtection([ AdminRoles.PROCESSOR_ADMIN ])
  const { t } = useTranslation([ 'common', 'settings' ])
  const {
    page,
    perPage,
    filters,
    sorting,
    setPage,
    setPerPage,
    setFilters,
    handleSort,
  } = useDataGridOptions({ initialFilterValues: {}, shouldUpdateUrl: true, initialSort: getDefaultCreatedAtSorting() })

  const { data, isLoading } = useAuditLogs({
    pagination: { page, perPage },
    filters,
    sorting: stringifySortItem(sorting),
  })

  const methods = useForm<OnFilterAuditLogsParams>({
    resolver: yupResolver(FilterSchema),
    defaultValues: FilterDefaultValues,
    mode: 'all'
  })

  const columns: GridColDef<IAudit>[] = useMemo(() => [
    {
      field: 'createdAt',
      headerName: t('date'),
      minWidth: 180,
      flex: 0.3,
      renderCell: ({ row }) => <DateCell date={row.date} showTime />,
    },
    {
      sortable: false,
      field: 'label',
      headerName: t('event'),
      flex: 1.5,
      renderCell: ({ row }) => <EventMessage event={row} />,
    },
    {
      sortable: false,
      field: 'userId',
      headerName: t('user'),
      minWidth: 100,
      flex: 0.8,
      valueGetter: ({ row }) => `${row.userEmail ?? '-'} (Id: ${row.userId})`,
    },
  ], [])

  const onReset = useCallback(() => {
    methods.reset(FilterDefaultValues)
    setFilters(FilterDefaultValues)
  }, [])

  const handleFilter = (params: OnFilterAuditLogsParams) => {
    setPage(1)
    /**
     * We need to send always a new object to setFilters
     * React hook form mutates internally its fields state object and sends that reference as a param instead of aa new cloned object
     * */
    setFilters({ ...params })
  }

  return (
    <>
      <SettingsHeader
        description={{
          details: t('settings:auditLogs.description.details'),
        }}
        title={t('settings:tabs.auditLogs')}
      />
      <Stack spacing={3}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFilter)}>
            <Toolbar
              currentFilters={filters}
              filtersToWatch={[ 'dateFrom', 'dateTo' ]}
              hideSearch
              onReset={onReset}
              searchPlaceholder={t('table.toolbar.searchTermPlaceholder', { ns: 'invoices' })}
              searchField="searchTerm"
            >
              <AuditLogsFilters />
            </Toolbar>
          </form>
        </FormProvider>
        <DataGrid
          loading={isLoading}
          columns={columns}
          getRowId={(row: IAudit) => row.requestId}
          rows={data?.results || []}
          rowCount={data?.total || 0}
          page={page - 1}
          pageSize={perPage}
          onPageChange={(currentPage) => setPage(currentPage + 1)}
          onPageSizeChange={(newPerPage) => setPerPage(newPerPage)}
          sorting={sorting}
          handleSort={handleSort}
        />
      </Stack>
    </>
  )
}

export default AuditLogs
