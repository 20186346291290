import { FC } from 'react'
import { Chip, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { InvoicesApprovalStatus, InvoicesValidationStatus } from '@keo/shared-types'
import InvoiceStatusIcon from './InvoiceStatusIcon'
import InvoiceValidationStatus from './InvoiceValidationStatus'

type InvoiceStatusProps = {
  showSuccess?: boolean
  status: InvoicesApprovalStatus
  validationStatus: InvoicesValidationStatus
}

const validationLabel = {
  [ InvoicesApprovalStatus.APPROVED ]: 'status.APPROVED',
  [ InvoicesApprovalStatus.CREATED ]: 'status.pendingSupplier',
  [ InvoicesApprovalStatus.LOCKED ]: 'status.locked',
  [ InvoicesApprovalStatus.DENIED ]: 'status.deniedBuyer',
  [ InvoicesApprovalStatus.PENDING ]: 'status.pendingBuyer',
  [ InvoicesApprovalStatus.REJECTED ]: 'status.deniedSupplier',
}

function useValidationColor(status: InvoicesApprovalStatus): string {
  const theme = useTheme()
  switch (status) {
    case InvoicesApprovalStatus.LOCKED:
      return theme.palette.warning.main
    case InvoicesApprovalStatus.CREATED:
    case InvoicesApprovalStatus.PENDING:
      return theme.palette.info.main
    case InvoicesApprovalStatus.DENIED:
    case InvoicesApprovalStatus.REJECTED:
      return theme.palette.error.main
    case InvoicesApprovalStatus.APPROVED:
      return theme.palette.success.main
  }
}

const InvoiceStatus: FC<InvoiceStatusProps> = ({ showSuccess, status, validationStatus }) => {
  const { t } = useTranslation([ 'invoices' ])
  const color = useValidationColor(status)

  // If the invoice is not valid we show the validation status instead
  if (validationStatus !== InvoicesValidationStatus.VALID) {
    return <InvoiceValidationStatus validationStatus={validationStatus} />
  }

  // We don't want to show the success chip always if the invoice is approved
  if (status === InvoicesApprovalStatus.APPROVED && !showSuccess) {
    return null
  }

  return (
    <Chip
      icon={<InvoiceStatusIcon status={status} />}
      label={t(validationLabel[ status ])}
      size="small"
      sx={{ borderColor: color, color }}
      variant="outlined"
    />
  )
}

export default InvoiceStatus
