"use strict";
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapAccountSettingToReferenceField = exports.MapAppSettingToReferenceField = exports.MapAppSettingToAccountSetting = exports.MapAccountSettingToAppSetting = exports.TargetAccountsSettings = void 0;
var settings_1 = require("../settings");
var TargetAccountsSettings;
(function (TargetAccountsSettings) {
    TargetAccountsSettings["PROCESSING_FEE_STRATEGY_ID"] = "PROCESSING_FEE_STRATEGY_ID";
    TargetAccountsSettings["PAYBACK_STRATEGY_ID"] = "PAYBACK_STRATEGY_ID";
    TargetAccountsSettings["LOCALE"] = "LOCALE";
})(TargetAccountsSettings = exports.TargetAccountsSettings || (exports.TargetAccountsSettings = {}));
exports.MapAccountSettingToAppSetting = (_a = {},
    _a[TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID] = settings_1.TargetSettings.PROCESSING_FEE_STRATEGIES,
    _a[TargetAccountsSettings.PAYBACK_STRATEGY_ID] = settings_1.TargetSettings.PAYBACK_STRATEGIES,
    _a);
exports.MapAppSettingToAccountSetting = (_b = {},
    _b[settings_1.TargetSettings.PROCESSING_FEE_STRATEGIES] = TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID,
    _b[settings_1.TargetSettings.PAYBACK_STRATEGIES] = TargetAccountsSettings.PAYBACK_STRATEGY_ID,
    _b);
exports.MapAppSettingToReferenceField = (_c = {},
    _c[settings_1.TargetSettings.PROCESSING_FEE_STRATEGIES] = 'processingFeeStrategyId',
    _c[settings_1.TargetSettings.PAYBACK_STRATEGIES] = 'paybackStrategyId',
    _c);
exports.MapAccountSettingToReferenceField = (_d = {},
    _d[TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID] = 'processingFeeStrategyId',
    _d[TargetAccountsSettings.PAYBACK_STRATEGY_ID] = 'paybackStrategyId',
    _d);
