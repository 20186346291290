import React, { FC, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import { CurrencyCell, DataGrid, DateCell, Widget } from '@/components'
import { ITransaction, TriggerType } from '@keo/shared-types'
import { useLatestTransactions } from '@/api/hooks'

const ITEMS_TO_SHOW = 4

const LatestTransactionsWidget: FC = () => {
  const { t } = useTranslation([ 'common', 'dashboard', 'transactions' ])

  const { data, isLoading } = useLatestTransactions({ itemsToShow: ITEMS_TO_SHOW })

  const columns: GridColDef<ITransaction>[] = useMemo(() => ([
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      minWidth: 50,
      sortable: false,
      renderCell: ({ row }) => t(`transactions:type.${row.triggerType === TriggerType.AUTOMATIC ? 'automatic' : ''}${row.type}`)
    },
    {
      field: 'walletAddress',
      headerName: t('walletAddress'),
      flex: 2,
      sortable: false,
      valueFormatter: ({ value }) => value || '-'
    },
    {
      field: 'createdAt',
      headerName: t('created'),
      minWidth: 80,
      flex: 0.8,
      renderCell: ({ row }) => <DateCell date={row.createdAt}/>,
    },
    {
      field: 'amount',
      headerName: t('amount'),
      minWidth: 120,
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }) => <CurrencyCell value={row.amount}  />,
    },
  ]), [])

  return (
    <Widget title={t('dashboard:latestTransactions')} isLoading={isLoading} hasData={!!data?.total}>
      <DataGrid
        columns={columns}
        rows={data?.results || []}
        pageSize={ITEMS_TO_SHOW}
        rowCount={data?.total || 0}
        hideFooter
      />
    </Widget>
  )
}

export default LatestTransactionsWidget
