import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material'
import { Box, Button, CircularProgress, Link, Stack, Typography, useTheme } from '@mui/material'
import { parseUrl, queryParamsToUrl } from '@keo/frontend/utils/url'
import useSSE from '@/components/hooks/useSSE'
import ApiClient from '@/utils/api'
import Modal from './components/Modal'
import { removeUndefinedAndEmptyValues } from '@/utils/parsers'
import { serializeData } from '@/components/tables/hooks/useDataGridOptions'

export enum Entity {
  ACCOUNTS = 'accounts',
  TRANSACTIONS = 'transactions',
  LOANS = 'loans',
  INVOICES = 'invoices',
  WALLETS = 'account/wallets',
  PROCESSING_FEE_STRATEGIES = 'account/settings/processing-fee',
}

type CSVExporterProps = {
  onClose: () => void
  entity: Entity
  query?: Record<string, unknown>
}

const CSVExporter: FC<CSVExporterProps> = ({ entity, query, onClose }) => {
  // Using both state and ref because both have different use cases
  // State is used to trigger re-rendering of the download button when the download url is ready
  // Ref is used to revoke the url when the component is unmounted
  const [ downloadUrl, setDownloadUrl ] = useState('')
  const urlRef = useRef('')

  const theme = useTheme()
  const location = useLocation()
  const { t } = useTranslation([ 'common' ])
  const [ contentToDownload, setContentToDownload ] = useState<string | null>(null)
  const [ error, setError ] = useState(false)

  const queryUrl = useMemo(() => {
    const parsedUrl = parseUrl(location.search, [ 'page', 'perPage' ])

    return queryParamsToUrl(`/api/${entity}/export`, query ? serializeData(removeUndefinedAndEmptyValues(query)) : parsedUrl.query)
  }, [ query ])

  useSSE({
    url: queryUrl,
    onUpdated: (jobId) => {
      setContentToDownload(jobId)
    },
    onError: () => {
      setError(true)
    }
  })

  const isLoading = useMemo(() => !downloadUrl && !error, [ downloadUrl, error ])
  const isExported = useMemo(() => contentToDownload && !error, [ contentToDownload, error ])
  const isReadyToDownload = useMemo(() => downloadUrl && !error, [ downloadUrl, error ])
  const contentUrl = useMemo(() => `/${entity}/export/${contentToDownload}`, [ contentToDownload ])

  const handleRevoke = () => {
    if (urlRef.current) {
      window.URL.revokeObjectURL(urlRef.current)
    }
  }

  useEffect(() => {
    if(isExported){
      ApiClient.get(contentUrl).then((response) => {
        const fileData = response.data as string

        const blob = new Blob([ fileData ], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        urlRef.current = url
        setDownloadUrl(url)
      })
    }
  }, [ isExported ])

  useEffect(() => {
    return () => {
      handleRevoke()
    }
  }, [])

  return (
    <Modal
      open
      onClose={onClose}
      title={t('export')}
      maxWidth="sm"
    >
      <Box mt={5} mb={2}>
        {error && (
          <Stack spacing={2} alignItems="center">
            <ErrorOutline color="error" sx={{ fontSize: 50 }}/>
            <Typography>{t('exportError')}</Typography>
          </Stack>
        )}
        {isReadyToDownload && (
          <Stack spacing={2} alignItems="center">
            <CheckCircleOutline color="success" sx={{ fontSize: 50 }}/>
            <Typography>{t('exportComplete')}</Typography>
            <Box pt={2}>
              <Link
                underline="none"
                component={Button}
                size="large"
                href={downloadUrl}
                download={`${entity}.csv`}
                sx={{ color: theme.palette.background.default }}
              >
                {t('downloadExport')}
              </Link>
            </Box>
          </Stack>
        )}
        {isLoading && (
          <Stack spacing={2} alignItems="center">
            <CircularProgress color="primary"/>
            <Typography>{t('exporting')}</Typography>
          </Stack>
        )}
      </Box>
    </Modal>
  )
}

export default CSVExporter
