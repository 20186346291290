import { useMutation, useQuery } from 'react-query'
import { Account, ApiErrorCode, IAccountsSettings, TargetAccountsSettings } from '@keo/shared-types'
import {
  getAccountSettings,
  GetAccountSettingsUsageParams,
  getAccountSettingUsage,
  getDefaultAccountSetting,
  GetDefaultAccountSettingParams,
  setProcessingFeeStrategySetting,
  updateProcessingFeeStrategySetting
} from '../account-settings'
import { ApiQueryKeys } from './queries'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'

type UseAccountSettingsParams<T extends TargetAccountsSettings> = {
  accountId?: Account['id']
  appliedToId?: Account['id']
  appSettingId?: number
  type: T
}

export function useAccountSettings<TData = IAccountsSettings<TargetAccountsSettings>[]>(
  params: UseAccountSettingsParams<TargetAccountsSettings>,
  select?: (data: IAccountsSettings<TargetAccountsSettings>[]) => TData,
) {
  const { accountId, appliedToId, type, appSettingId } = params
  return useQuery(
    [ ApiQueryKeys.ACCOUNT_SETTINGS, accountId, appliedToId, appSettingId ],
    () => getAccountSettings({
      accountId,
      appliedToId,
      type,
      appSettingId
    }),
    {
      enabled: !!(accountId || appliedToId),
      select,
    },
  )
}

type UseDefaultAccountSettingsParams<T extends TargetAccountsSettings> = {
  accountId?: Account['id']
  appliedToId?: Account['id']
  type: T
}

export function useDefaultAccountSettings<T extends TargetAccountsSettings>(params: UseDefaultAccountSettingsParams<T>) {
  return useQuery(
    [ ApiQueryKeys.ACCOUNT_SETTINGS, ApiQueryKeys.ACCOUNT_SETTING_BEST_MATCH, params.accountId, params.appliedToId, params.type ],
    () => getDefaultAccountSetting<T>(params as GetDefaultAccountSettingParams<T>),
    {
      enabled: !!params.accountId,
    }
  )
}

type UseAccountSettingUsageParams = Pick<GetAccountSettingsUsageParams, 'accountType' | 'type'> & {
  appSettingId: number | undefined
}

export function useAccountSettingUsage(params: UseAccountSettingUsageParams) {
  return useQuery(
    [ ApiQueryKeys.ACCOUNT_SETTINGS_USAGE, params.appSettingId, params.type, params.accountType ],
    // We cast here because params will always have an appSettingId since enabled flag is controlling it
    () => getAccountSettingUsage(params as GetAccountSettingsUsageParams),
    {
      enabled: !!params.appSettingId,
    }
  )
}

type UseProcessingFeeSettingsParams<T extends TargetAccountsSettings> = Omit<UseAccountSettingsParams<T>, 'type'>

export function useProcessingFeeSettings<T extends TargetAccountsSettings>(params: UseProcessingFeeSettingsParams<T>)  {
  const { accountId, appliedToId, appSettingId } = params
  return useAccountSettings(
    {
      accountId,
      appliedToId,
      appSettingId,
      type: TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID
    },
    (data) => data?.filter(setting => !!setting.id && !!setting.appliedToId)
  )
}

type UseAddProcessingFeeSetting = {
  onSuccess: () => void
}

export function useAddProcessingFeeSetting(params: UseAddProcessingFeeSetting) {
  const { t } = useTranslation('processing-fees')
  const { onSuccess } = params
  return useMutation(setProcessingFeeStrategySetting, {
    onError: (err: any) => {
      const errCode = err.response.data.code
      if (errCode === ApiErrorCode.ACCOUNTS_SETTINGS_ALREADY_EXISTS) {
        enqueueSnackbar(t('messages.addErrorExists'), { variant: 'error' })
        return
      }
      enqueueSnackbar(t('messages.addError'), { variant: 'error' })
    },
    onSuccess: () => {
      enqueueSnackbar(t('messages.addSuccess'), { variant: 'success' })
      onSuccess()
    }
  })
}

type UseEditProcessingFeeSetting = {
  onSuccess: () => void
}

export function useUpdateProcessingFeeSetting(params: UseEditProcessingFeeSetting) {
  const { t } = useTranslation('processing-fees')
  const { onSuccess } = params
  return useMutation(updateProcessingFeeStrategySetting, {
    onError: () => {
      enqueueSnackbar(t('messages.editError'), { variant: 'error' })
    },
    onSuccess: () => {
      enqueueSnackbar(t('messages.editSuccess'), { variant: 'success' })
      onSuccess()
    }
  })
}
