import { FC } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { useLocale } from '@/utils/l10n'
import { Launch } from '@mui/icons-material'
import { getTransactionLink } from '@/utils/routes'

type TransactionListItemProps = {
  amount: number
  date: string | Date
  txId: string
}

const TransactionListItem: FC<TransactionListItemProps> = ({ amount, date, txId }) => {
  const { parseCurrencyAmount, parseDate } = useLocale()
  return (
    <Box>
      <Grid container flexDirection="column">
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography fontSize="default">
                {txId.substring(0, 20) + '...'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize="default" variant="body2" color={amount < 0 ? 'error.main' : 'success.main'}>
                {parseCurrencyAmount(amount)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography fontSize="default" color="text.secondary">
                {parseDate(date)}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton href={getTransactionLink(txId)} target='_blank'>
                <Launch />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TransactionListItem