import React, { FC, PropsWithChildren, ReactNode, useCallback } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  SxProps,
  Typography
} from '@mui/material'
import { Close } from '@mui/icons-material'

type ModalProps = {
  maxWidth?: DialogProps['maxWidth']
  disableBackdropClick?: boolean
  open: boolean
  onClose: () => void
  title: string
  actions?: ReactNode
  loading?: boolean
  sx?: SxProps
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  disableBackdropClick = true,
  onClose,
  title,
  actions,
  maxWidth = 'sm',
  loading = false,
  children,
  sx,
}) => {
  const handleClose = useCallback((
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (!disableBackdropClick) {
      onClose()
      return
    }

    // Disable closing on backdrop click
    if (reason !== 'backdropClick') {
      onClose()
    }
  }, [ disableBackdropClick ])

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      sx={sx}
      PaperProps={{
        sx: { overflowY: 'visible' }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{title}</Typography>
          <IconButton
            edge="end"
            onClick={onClose}
            aria-label="close"
            disabled={loading}
          >
            <Close/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ overflowY: 'visible' }}>
        {children}
      </DialogContent>
      {!!actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default Modal
