import React, { FC, PropsWithChildren } from 'react'
import { Container, styled } from '@mui/material'
import { APP_HEADER_HEIGHT } from '@/utils/constants'

const StyledDiv = styled('div')(({ theme }) => ({
  height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
  overflow: 'auto',
  padding: theme.spacing(4, 0),
  background: '#eee'
}))

const ProtectedPage: FC<PropsWithChildren> = ({ children }) => (
  <StyledDiv>
    <Container maxWidth="xl">
      {children}
    </Container>
  </StyledDiv>
)

export default ProtectedPage
