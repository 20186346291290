import { FC, Suspense } from 'react'
import { ErrorBoundary, wrapCreateBrowserRouter } from '@sentry/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { SessionProvider } from '@keo/frontend/utils/session'
import { GlobalError } from '@keo/frontend/components'
import { GlobalLoader, SnackbarProvider, SystemChecker } from '@/components'
import { ThemeProvider } from '@/styles'
import { AppContextProvider } from '@/utils/appContext'
import { getSession } from '@/api/session'
import { routes } from '@/utils/routes'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter(routes)

const App: FC = () => {
  return (
    <ThemeProvider>
      <ErrorBoundary fallback={<GlobalError/>} showDialog>
        <SnackbarProvider>
          {/* Sets up react-query */}
          <QueryClientProvider client={queryClient}>
            {/* Checks system health, otherwise shows unavailable page */}
            <SystemChecker>
              {/* Sets up session */}
              <SessionProvider fetchSession={getSession}>
                {/*
                * Sets up site config - this needs to be done after session is set up so that
                * we can use the session to determine the correct context to use
                */}
                <AppContextProvider>
                  <CssBaseline/>
                  <Suspense fallback={<GlobalLoader/>}>
                    <RouterProvider router={router}/>
                  </Suspense>
                </AppContextProvider>
              </SessionProvider>
            </SystemChecker>
          </QueryClientProvider>
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
