import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { useExternalWallet } from '@/components'
import { truncateAddress } from '@/utils/formatters'

type ConnectExternalWalletProps = {
  text?: string
  isEmbedded?: boolean
}

const ConnectExternalWallet: FC<ConnectExternalWalletProps> = ({ text, isEmbedded = false }) => {
  const { t } = useTranslation('common')
  const {
    connect,
    disconnect,
    isConnected,
    address
  } = useExternalWallet()

  if (!isConnected) {
    return (
      <Grid container flexDirection="column" spacing={2}>
        {!isEmbedded && (
          <Grid item>
            <Typography whiteSpace="pre-line">{text || t('connectToExternalWalletText')}</Typography>
          </Grid>
        )}
        <Grid item>
          <Button onClick={connect}>{t('connect')}</Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={4}
      {...(!isEmbedded && {
        justifyContent: 'space-between',
        alignItems: 'center'
      })}
    >
      <Grid item>
        <Grid container flexDirection="column" spacing={1}>
          <Grid item>
            <Box display="flex" gap={1}>
              <CheckCircleOutline color="success"/>
              <Typography>{t('connected')}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography>{t('address')}: {truncateAddress(address!, 20)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button onClick={disconnect}>{t('disconnect')}</Button>
      </Grid>
    </Grid>
  )
}

export default ConnectExternalWallet
