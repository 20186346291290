"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrapesEndpoints = void 0;
var GrapesEndpoints;
(function (GrapesEndpoints) {
    // organizations
    GrapesEndpoints["GRAPES_NEW_MEMBER"] = "/organizations/member";
    GrapesEndpoints["GRAPES_GET_MEMBER_DETAILS"] = "/organizations/member";
    GrapesEndpoints["GRAPES_UPDATE_MEMBER_BANK_ACCOUNT"] = "/organizations/member";
    GrapesEndpoints["GRAPES_DELETE_MEMBER_BANK_ACCOUNT"] = "/organizations/member/bankDetails";
    GrapesEndpoints["GRAPES_ADD_WALLET"] = "/organizations/member/createBlockchain";
    // orders
    GrapesEndpoints["GRAPES_GET_FX_RATE"] = "/orders/fxRate";
    GrapesEndpoints["GRAPES_REQUEST_PURCHASE"] = "/orders/purchase/member";
    GrapesEndpoints["GRAPES_GET_MEMBER_ORDERS"] = "/orders/member";
    GrapesEndpoints["GRAPES_REQUEST_REDEEM"] = "/orders/redeem/member";
})(GrapesEndpoints = exports.GrapesEndpoints || (exports.GrapesEndpoints = {}));
