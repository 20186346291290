import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { DATE_FORMAT_API, DEFAULT_CURRENCY } from '@/utils/constants'
import { useConfig } from '@/utils/appContext'

export function getAccountCurrency(): string {
  return process.env.REACT_APP_CURRENCY || DEFAULT_CURRENCY
}

export function parseCurrencyAmount(amount: number, locale: string, currency: string): string {
  try {
    const formatter = new Intl.NumberFormat(locale, { currency, style: 'currency', currencyDisplay: 'narrowSymbol' })
    return formatter.format(amount)
  } catch {
    const formatter = new Intl.NumberFormat(locale)
    return `${formatter.format(amount)} ${currency}`
  }
}

function getCurrencySymbol(currencyCode: string, locale: string = 'en-US'): string {
  const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
  })

  // Get the currency symbol by formatting 0 with the given currency code
  const parts = formatter.formatToParts(0)
  const currencyPart = parts.find(part => part.type === 'currency')

  if (currencyPart) {
      return currencyPart.value;
  } else {
      throw new Error(`Unsupported currency code: ${currencyCode}`);
  }
}

export function parsePercentage(num?: number, skipConversion = false, decimalPlaces = 3): string {
  if (num === 0) {
    return '0 %'
  }
  if (num === Infinity) {
    return '∞ %'
  }
  if (!num) {
    return '-'
  }
  try {
    return `${(num * (skipConversion ? 1 : 100)).toFixed(decimalPlaces)} %`
  } catch {
    return '-'
  }
}

export function parseDate(date: string | Date, locale: string, showTime?: boolean): string {
  try {
    const formatter = new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      ...(showTime ? { timeStyle: 'medium' } : {}),
    })
    return formatter.format(new Date(date))
  } catch {
    return date.toString()
  }
}

export function parseTime(date: string | Date, locale: string): string {
  try {
    const formatter = new Intl.DateTimeFormat(locale, { timeStyle: 'short' })
    return formatter.format(new Date(date))
  } catch {
    return date.toString()
  }
}

export function parseDateExcludingTime(date: string | number, dateFormat = DATE_FORMAT_API): string {
  return format(new Date(date), dateFormat)
}

type UseLocaleResult = {
  parseCurrencyAmount: (amount: number, currency?: string) => string
  parseDate: (date: string | Date, showTime?: boolean) => string
  parseTime: (date: string | Date) => string
}

export function useLocale(): UseLocaleResult {
  const { i18n } = useTranslation()
  const language = i18n.language
  const { appCurrency } = useConfig()

  return useMemo(() => ({
    parseCurrencyAmount: (amount: number, currency?: string) => parseCurrencyAmount(amount, language, currency || appCurrency),
    parseDate: (date: string | Date, showTime?: boolean) => parseDate(date, language, showTime),
    parseTime: (date: string | Date) => parseTime(date, language)
  }), [ language, appCurrency ])
}

export function useCurrency() {
  const { i18n } = useTranslation()
  const { appCurrency } = useConfig()

  return useMemo(() => ({
    currency: appCurrency,
    currencySymbol: getCurrencySymbol(appCurrency, i18n.language),
  }), [ i18n.language, appCurrency ])
}
