import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { GlobalEvent } from '@/types/events';
import { useHealthCheck } from '@/api/hooks';
import { GlobalLoader, SystemUnavailable } from '.';
import { HealthCheckResult } from '@/api/health';

export type SystemCheckerProps = {
  children: ReactNode
}

export const SystemDataContext = createContext<HealthCheckResult | undefined>(undefined)

export const SystemChecker: FC<SystemCheckerProps> = ({ children }) => {
  const [ isFetcherActive, setIsFetcherActive ] = useState(true)

  const { data: healthData, isLoading, refetch } = useHealthCheck({
    enabled: isFetcherActive,
    refetchInterval: 60 * 1000,
    keepPreviousData: true,
  })

  // We listen for bad gateway and if it happens, platform UI is deactivated
  useEffect(() => {
    window.addEventListener(GlobalEvent.BAD_GATEWAY, () => refetch())
    return () => {
      window.removeEventListener(GlobalEvent.BAD_GATEWAY, () => refetch())
    }
  }, [])

  // If platform is deactivated, enable the fetcher, if it is active disable the fetcher
  useEffect(() => {
    if (!healthData) {
      return
    }
    // We fetch in interval only if system is not available do to service downtime
    setIsFetcherActive(!healthData.isAvailable && !healthData.isOnlyDowntimeFlag)
  }, [ healthData ])

  const memoizedChildren = useMemo(() => children, [ children ])

  if (isLoading) {
    return <GlobalLoader />
  }
 
  // Deactivate platform UI
  if (!healthData?.isAvailable && !healthData?.isOnlyDowntimeFlag) {
    return <SystemUnavailable />
  }

  return (
    <SystemDataContext.Provider value={healthData}>
      {memoizedChildren}
    </SystemDataContext.Provider>
  )
}

export default SystemChecker
