import { FC } from 'react'
import { AccessTimeFilled, Cancel } from '@mui/icons-material'
import { InvoicesValidationStatus } from '@keo/shared-types'
import { isPendingStatus } from '../utils'

type InvoiceValidationStatusIconProps = {
  status: InvoicesValidationStatus
}

const InvoiceValidationStatusIcon: FC<InvoiceValidationStatusIconProps> = ({ status }) => {
  if (isPendingStatus(status)) {
    return <AccessTimeFilled color="info" fontSize="small" />
  }

  return <Cancel color="error" fontSize="small" />
}

export default InvoiceValidationStatusIcon
