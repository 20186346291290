import React, { FC } from 'react'
import { LoanPayBackStatus } from '@keo/shared-types'
import { AccessTimeFilled, Cancel, CheckCircle } from '@mui/icons-material'

type LoanStatusIconProps = {
  status: LoanPayBackStatus
}

const LoanStatusIcon: FC<LoanStatusIconProps> = ({ status }) => {
  return {
    [ LoanPayBackStatus.PENDING ]: <AccessTimeFilled color="info" fontSize="small" />,
    [ LoanPayBackStatus.ACTIVE ]: <AccessTimeFilled color="info" fontSize="small" />,
    [ LoanPayBackStatus.REJECTED ]: <Cancel color="error" fontSize="small" />,
    [ LoanPayBackStatus.PAID ]: <CheckCircle color="success" fontSize="small" />,
    [ LoanPayBackStatus.DIRECT_PAID ]: <CheckCircle color="success" fontSize="small" />,
  }[ status ]
}

export default LoanStatusIcon
