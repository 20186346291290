import { HealthCheckResult } from '@/api/health'
import { HealthKeys, IHealthResponse } from '@keo/shared-types'

export function getFailedHealthServices(healthCheckDetails: IHealthResponse['details']) {
  return Object.entries(healthCheckDetails)
    .filter(([ , value ]) => value.status === 'down')
    .map(([ key ]) => key as HealthKeys)
}

export function parseHealthCheckResponse(
  response: IHealthResponse,
): HealthCheckResult {
  const failedServices = getFailedHealthServices(response.details)
  return {
    isAvailable: response.status === 'ok',
    // this property just returns failed services which status is 'down'
    failedServices,
    // this property returns true if all services are up except the downtime mode flag
    isOnlyDowntimeFlag: failedServices.length === 1 && failedServices.includes(HealthKeys.DOWNTIME_MODE),
    services: response.details,
  }
}
