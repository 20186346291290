import { FC, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import SelectFilter from '@/components/forms/SelectFilter'
import { getPaybackStrategies } from '@/api/settings'
import { PaybackStrategySetting } from '@/types/settings'
import { useFormContext } from 'react-hook-form'

type PaybackStrategyOption = {
  value: number
  label: string
}

type PaybackStrategySelectProps = {
  fieldName?: string
}

const PaybackStrategySelect: FC<PaybackStrategySelectProps> = ({ fieldName = 'paybackStrategy' }) => {
  const { setValue } = useFormContext()
  const { t } = useTranslation([ 'common', 'settings' ])
  const { data } = useQuery('paybackStrategies', () => getPaybackStrategies({
    page: 1,
    perPage: 100
  }))

  const mapPaybackStrategiesToOptions = useCallback((paybackStrategies: PaybackStrategySetting[]): PaybackStrategyOption[] => {
    return paybackStrategies.map(paybackStrategy => ({
      value: paybackStrategy.id,
      label: `${paybackStrategy.value.name} (${paybackStrategy.value.strategy.map(strategy => t(strategy.toLocaleLowerCase())).join(', ')})`,
    }))
  }, [])

  const options = useMemo(() => mapPaybackStrategiesToOptions(data?.results || []), [ data ])

  return (
    <SelectFilter
      onClear={() => setValue(fieldName, 0)}
      options={options}
      label={t('paybackStrategy')}
      fieldName={fieldName}
    />
  )
}

export default PaybackStrategySelect
