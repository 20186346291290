import React, { FC, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { IconButton, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
import { FormInput } from '@/components'

type SearchInputProps = {
  placeholder: string
  field: string
  isSubmitDisabled?: boolean
}

const SearchInput: FC<SearchInputProps> = ({ placeholder, field, isSubmitDisabled = false }) => {
  const { control, setValue } = useFormContext()
  const submitRef = useRef<HTMLButtonElement | null>(null)

  const handleClear = () => {
    setValue(field, '')
    if (submitRef.current) {
      submitRef.current.click()
    }
  }

  return (
    <FormInput
      onClear={handleClear}
      control={control}
      name={field}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton type="submit" ref={submitRef}>
              <Search/>
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchInput
