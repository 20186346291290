import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query'
import { getHealthCheck, HealthCheckResult, resetDowntimeFlag } from '../health'
import { ApiQueryKeys } from './queries'

export function useHealthCheck(
  options?: UseQueryOptions<HealthCheckResult>,
){
  return useQuery<HealthCheckResult>(
    ApiQueryKeys.HEALTH_CHECK,
    getHealthCheck,
    options,
  )
}

export function useResetDowntimeFlagMutation(
  options: UseMutationOptions
){
  return useMutation(resetDowntimeFlag, options)
}
