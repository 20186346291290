"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockerActions = exports.WalletType = void 0;
var WalletType;
(function (WalletType) {
    WalletType[WalletType["INTERNAL"] = 0] = "INTERNAL";
    WalletType[WalletType["EXTERNAL"] = 1] = "EXTERNAL";
    WalletType[WalletType["LSIG"] = 2] = "LSIG";
    WalletType[WalletType["MOBILE"] = 3] = "MOBILE";
})(WalletType = exports.WalletType || (exports.WalletType = {}));
var BlockerActions;
(function (BlockerActions) {
    BlockerActions["SUSPEND"] = "SUSPEND";
    BlockerActions["BAN"] = "BAN";
    BlockerActions["REACTIVATE"] = "REACTIVATE";
})(BlockerActions = exports.BlockerActions || (exports.BlockerActions = {}));
