import { FC } from 'react'
import { Skeleton } from '@mui/material'

type WidgetSkeletonProps = {
  height?: number
}

const WidgetSkeleton: FC<WidgetSkeletonProps> = ({ height }) => {
  return (
    <Skeleton
      variant="rectangular"
      // Skeleton will inherit hc height or fallback to 100
      height={height || 100}
      width="100%"
    />
  )
}

export default WidgetSkeleton