import { useBeforeUnload, unstable_useBlocker as useBlocker } from 'react-router-dom'
import { createContext, useCallback, useState } from 'react'
import { Outlet } from 'react-router-dom'
import loadable from '@loadable/component'

const NavigationGuardModal = loadable(() => import('./NavigationGuardModal'))

export type NavigationGuardContextData = {
  setShouldShow: (show: boolean) => void
}

export const NavigationGuardContext = createContext({} as NavigationGuardContextData)

const NavigationGuardProvider = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ shouldBlock, setShouldBlock ] = useState(false)
  const blocker = useBlocker(() => {
    shouldBlock && setIsOpen(true)
    return shouldBlock
  })

  useBeforeUnload((e) => {
    if (shouldBlock) {
      e.preventDefault()
      e.returnValue = ''

      return 'Are you sure you want to leave this page?'
    }
  })

  const setShouldShow = useCallback((show: boolean) => setShouldBlock(show), [])

  const onCancel = useCallback(() => setIsOpen(false), [])

  const onContinue = useCallback(() => {
    blocker.proceed?.()
    setShouldBlock(false)
    setIsOpen(false)
  }, [ blocker ])

  return (
    <NavigationGuardContext.Provider value={{ setShouldShow }}>
      <Outlet />
      {isOpen && <NavigationGuardModal onCancel={onCancel} onContinue={onContinue} />}
    </NavigationGuardContext.Provider>
  )
}

export default NavigationGuardProvider
