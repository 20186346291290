import { createTheme } from '@mui/material'
import getComponentDefaults from './componentDefaults'
import { fontConfig } from './typography'
import themeColors from './palettes/keo'

declare module '@mui/material/styles' {
  interface Theme {
    chipBackgroundColor: {
      error: React.CSSProperties['color']
      info: React.CSSProperties['color']
      success: React.CSSProperties['color']
    }
  }

  interface ThemeOptions {
    chipBackgroundColor: {
      error: React.CSSProperties['color']
      info: React.CSSProperties['color']
      success: React.CSSProperties['color']
    }
  }
}

const FONT_FAMILY = 'Poppins'

const theme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    ...fontConfig
  },
  palette: {
    ...themeColors
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  chipBackgroundColor: {
    error: 'rgba(255, 0, 61, 0.40)',
    info: 'rgba(0, 163, 255, 0.40)',
    success: '#CBFFE0',
  },
})

const themeWithComponents = createTheme({
  ...theme,
  components: getComponentDefaults(theme)
})
export default themeWithComponents
