"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrapesNotificationsType = exports.GrapesNotificationsEventType = void 0;
var GrapesNotificationsEventType;
(function (GrapesNotificationsEventType) {
    GrapesNotificationsEventType["ORDER"] = "ORDER";
    GrapesNotificationsEventType["SETTLEMENT"] = "SETTLEMENT";
})(GrapesNotificationsEventType = exports.GrapesNotificationsEventType || (exports.GrapesNotificationsEventType = {}));
var GrapesNotificationsType;
(function (GrapesNotificationsType) {
    GrapesNotificationsType["PURCHASE"] = "PURCHASE";
    GrapesNotificationsType["REDEEM"] = "REDEEM";
    GrapesNotificationsType["REDEEM_COMPLETE_REQUEST"] = "REDEEM_COMPLETE_REQUEST";
})(GrapesNotificationsType = exports.GrapesNotificationsType || (exports.GrapesNotificationsType = {}));
