import { CronFields, fieldsToExpression, parseExpression } from 'cron-parser'

export function truncateAddress(address: string, length = 30): string {
  if (address.length <= length) return address

  const delimiter = Math.floor(length / 2)
  return `${address.slice(0, delimiter)} ⋯ ${address.slice(-delimiter)}`
}

export function formatCronExpression(fields: Partial<CronFields>, baseExpression?: string ): string {
  const BASE_EXPRESSION = '* * * * *'
  const baseFields = parseExpression(baseExpression || BASE_EXPRESSION).fields
  const finalFields = { ...baseFields, ...fields }
  return fieldsToExpression(finalFields).stringify()
}
