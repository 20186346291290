import Api from '@/utils/api'
import {
  GetWalletRequestParams,
  GetWalletsParams,
  GetWalletsRequestParams,
  GetWalletsResponse,
} from '@/types'
import { queryParamsToUrl } from '@keo/frontend/utils/url'
import { BlockerActions, Wallet } from '@keo/shared-types'
import { removeUndefinedAndEmptyValues } from '@/utils/parsers'

const PATH_PREFIX = '/account/wallets'

export async function getWallets(params: GetWalletsParams) {
  const { pagination, filters, sorting } = params
  const { accountType, creditLineAmountFrom, creditLineAmountTo, rating, searchTerm, whitelisted } = removeUndefinedAndEmptyValues(filters)

  const queryParams: GetWalletsRequestParams = {
    accountType: accountType,
    populateWalletDetails: true,
    ...creditLineAmountFrom && { creditLineAmountFrom: Number(creditLineAmountFrom) },
    ...creditLineAmountTo && { creditLineAmountTo: Number(creditLineAmountTo) },
    ...pagination,
    ...rating && { rating },
    ...searchTerm && { searchTerm },
    // Spreading the following fields to avoid sending empty strings to the API
    ...sorting && { sorting },
    ...whitelisted && { whitelisted },
  }

  const url = queryParamsToUrl(`${PATH_PREFIX}`, queryParams)
  const res = await Api.get<GetWalletsResponse>(url)

  return res.data
}

export async function getWallet(id: string) {
  const queryParams: GetWalletRequestParams = { populateWalletDetails: true }

  const url = queryParamsToUrl(`${PATH_PREFIX}/${id}`, queryParams)
  const res = await Api.get<Wallet>(url)

  return res.data
}

const BLOCKER_PATH_PREFIX = `${PATH_PREFIX}/blocker`

export async function callWalletAction(action: BlockerActions, id: number) {
  const res = await Api.post(BLOCKER_PATH_PREFIX, { id, action })

  return res.data
}

export function suspendWallet(id: number) {
  return callWalletAction(BlockerActions.SUSPEND, id)
}

export function reactivateWallet(id: number) {
  return callWalletAction(BlockerActions.REACTIVATE, id)
}

export function banWallet(id: number) {
  return callWalletAction(BlockerActions.BAN, id)
}
