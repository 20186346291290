import React, { FC } from 'react'
import { Box, Divider, Grid, Skeleton } from '@mui/material'
import { Wallet } from '@keo/shared-types'
import WalletActions from './components/WalletActions'
import WalletInfo from './components/WalletInfo'

type WalletDetailsProps = {
  wallet?: Wallet
  isLoading?: boolean
  refetchWallet: () => void
}
const WalletDetails: FC<WalletDetailsProps> = ({ wallet, isLoading = true, refetchWallet }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <WalletInfo wallet={wallet} isLoading={isLoading}/>
      </Grid>
      <Grid item>
        <Box my={3}>
          <Divider/>
        </Box>
      </Grid>
      <Grid item>
        {!wallet ? <Skeleton/> : <WalletActions wallet={wallet} onActionFinish={refetchWallet}/>}
      </Grid>
    </Grid>
  )
}

export default WalletDetails
