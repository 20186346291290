"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stablecoin = exports.Currency = void 0;
var Currency;
(function (Currency) {
    Currency["USD"] = "USD";
    Currency["CAD"] = "CAD";
})(Currency = exports.Currency || (exports.Currency = {}));
var Stablecoin;
(function (Stablecoin) {
    Stablecoin["USDC"] = "USDC";
    Stablecoin["QCAD"] = "QCAD";
})(Stablecoin = exports.Stablecoin || (exports.Stablecoin = {}));
