import { Account } from '@keo/shared-types'
import { useQuery } from 'react-query'
import { getBankAccount } from '../bankAccounts'
import { ApiQueryKeys } from './queries'

export function useBankAccount(bankAccountId: Account['bankAccountId']) {
  return useQuery(
    [ ApiQueryKeys.BANK_ACCOUNT, bankAccountId ],
    () => getBankAccount(bankAccountId as unknown as string),
    { enabled: !!bankAccountId },
  )
}
