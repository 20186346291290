import React, { FC } from 'react'
import { Button, Typography } from '@mui/material'

type AuthPageBackLinkProps = {
  label: string
  onClick: () => void
}

const AuthPageBackLink: FC<AuthPageBackLinkProps> = ({ label, onClick }) => {
  return (
    <Button onClick={onClick} size="large" variant="text" fullWidth>
      <Typography>
        {label}
      </Typography>
    </Button>
  )
}

export default AuthPageBackLink
