import { useSession as helper } from '@keo/frontend/utils/session'
import { Session } from '@/types/session'

export function useSession() {
  return helper<Session>()
}

export function useUser(): Session {
  const session = useSession()
  if (!session.session) {
    throw Error('useError should be only used within context where user is already defined')
  }
  return session.session
}
