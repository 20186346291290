import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getApplicationAddress } from 'algosdk'
import { Stablecoin } from '@keo/shared-types'
import { useConfig } from '@/utils/appContext'
import Modal from '../components/Modal'
import CircleInstructions from './CircleInstructions'
import StableCorpInstructions from './StableCorpInstructions'

type DepositLendingPoolInstructionsProps = {
  onClose: () => void
  appId: number
}

const DepositLendingPoolInstructions: FC<DepositLendingPoolInstructionsProps> = ({ onClose, appId }) => {
  const { t } = useTranslation('settings')
  const { appStablecoin } = useConfig()
  const instructions = useMemo(() => {
    const lendingPoolAddress = getApplicationAddress(appId)
    return (
      appStablecoin === Stablecoin.USDC ?
        <CircleInstructions address={lendingPoolAddress} /> : <StableCorpInstructions address={lendingPoolAddress} />
    )
  }, [ appStablecoin, appId ])

  return (
    <Modal
      open
      onClose={onClose}
      title={t(`lendingPoolManagement.actions.deposit`)}
      maxWidth="sm"
    >
      {instructions}
    </Modal>
  )
}

export default DepositLendingPoolInstructions
