import { FC } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { DataGrid as MUIDataGrid, DataGridProps as MUIDataGridProps, GridSortItem } from '@mui/x-data-grid'
import { DEFAULT_PAGE_SIZE, ROWS_PER_PAGE_OPTIONS } from '@/utils/constants'
import NoResults from './NoResults'

type DataGridProps = MUIDataGridProps & {
  sorting?: GridSortItem
  handleSort?: (sorting: GridSortItem) => void
}

const DataGrid: FC<DataGridProps> = (props) => {
  return (
    <MUIDataGrid
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      components={{
        ColumnSortedDescendingIcon: ArrowDropUp,
        ColumnSortedAscendingIcon: ArrowDropDown,
        NoRowsOverlay: NoResults,
      }}
      paginationMode={props.paginationMode || 'server'}
      sortingMode={props.sortingMode || 'server'}
      initialState={{
        pagination: {
          pageSize: props.pageSize || DEFAULT_PAGE_SIZE
        },
        sorting: {
          sortModel: props.sorting ? [ props.sorting ] : []
        }
      }}
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          borderBottomColor: 'divider'
        },
        '& .MuiDataGrid-cell': {
          borderBottomColor: 'divider'
        },
        '& .MuiDataGrid-row:hover': {
          cursor: props.onRowClick ? 'pointer' : 'default',
        },
      }}
      // We are only using 1st model because Free Datagrid supports only 1
      onSortModelChange={(sortModel) => props.handleSort?.(sortModel[ 0 ])}
      sortModel={props.sorting ? [ props.sorting ] : []}
      {...props}
    />
  )
}

export default DataGrid
