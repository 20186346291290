import Api from '@/utils/api'
import { GetAccountsParams, GetAccountsRequestParams, GetAccountsResponse } from '@/types'
import { queryParamsToUrl } from '@keo/frontend/utils/url'
import {
  Account,
  AccountStatus,
  KYCStatus,
  Rating,
  SignedTxnResponse,
  TargetAccountsSettings,
  TotalSummaryAccountsByStatuses,
  TransactionEncoded,
} from '@keo/shared-types'
import { removeUndefinedAndEmptyValues } from '@/utils/parsers'

const PATH_PREFIX = '/accounts'

export async function getAccounts(params: GetAccountsParams) {
  const { pagination, filters, sorting } = params
  const {
    accountType,
    initialised,
    kycStatus,
    paybackStrategy,
    processingFeeStrategy,
    searchTerm,
    statuses,
  } = removeUndefinedAndEmptyValues(filters, [ 'paybackStrategy', 'processingFeeStrategy' ])

  const queryParams: GetAccountsRequestParams = {
    ...pagination,
    // Spreading the following fields to avoid sending empty strings to the API
    ...searchTerm && { searchTerm },
    ...accountType && { accountType },
    ...paybackStrategy && { filterAppSettingId: paybackStrategy },
    ...processingFeeStrategy && { filterAppSettingId: processingFeeStrategy },
    ...initialised !== '' && { initialised },
    ...statuses?.length && { statuses: (statuses as AccountStatus[]).join(',') },
    ...kycStatus?.length && { kycStatus: (kycStatus as KYCStatus[]).join(',') },
    populateAccountSettings: [
      TargetAccountsSettings.PAYBACK_STRATEGY_ID,
      TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID,
    ],
    ...sorting && { sorting },
  }

  const url = queryParamsToUrl(`${PATH_PREFIX}`, queryParams)
  const res = await Api.get<GetAccountsResponse>(url)

  return res.data
}

export async function getAccount(id: string): Promise<Account> {
  const url = `${PATH_PREFIX}/${id}`
  const res = await Api.get<Account>(url)

  return res.data
}

export type UpdateCreditPayload = {
  creditLineAmount: number
  rating?: Rating
}

export type UpdateCreditParams = UpdateCreditPayload & {
  id: string
}

export async function updateCreditParams(params: UpdateCreditParams): Promise<unknown> {
  const { id, ...rest } = params
  const url = `${PATH_PREFIX}/${id}/credit-params`
  const res = await Api.patch<void, UpdateCreditPayload>(url, rest)

  return res.data
}

export async function getTotalSummaryAccountsByStatuses(): Promise<TotalSummaryAccountsByStatuses> {
  const url = `${PATH_PREFIX}/total-summary-accounts-by-statuses`
  const res = await Api.get<TotalSummaryAccountsByStatuses>(url)

  return res.data
}

export async function verifyAccount(id: string): Promise<TransactionEncoded[]> {
  const url = `${PATH_PREFIX}/verify`
  const res = await Api.post<TransactionEncoded[], { id: string }>(url, { id })

  return res.data
}

export type CompleteVerifyParams = {
  id: string
  signedTxns: SignedTxnResponse[]
}

export async function completeVerifyAccount({ id, signedTxns }: CompleteVerifyParams): Promise<void> {
  const url = `${PATH_PREFIX}/completeVerify`
  const res = await Api.post<void, CompleteVerifyParams>(url, { id, signedTxns })

  return res.data
}
