import React, { FC, ReactNode } from 'react'
import { Link as RRLink, useLocation } from 'react-router-dom'
import { Box, Link, styled, Typography, TypographyProps } from '@mui/material'

type AppHeaderLinkProps = {
  children: ReactNode
  url: string
  strict?: boolean 
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2)
}))

const StyledTypography = styled(Typography)<TypographyProps & { isActive: boolean }>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.background.default : theme.palette.grey[ 500 ],
  '&:hover': {
    color: theme.palette.background.default
  }
}))

const AppHeaderLink: FC<AppHeaderLinkProps> = ({ children, strict, url }) => {
  const location = useLocation()
  const isActive = strict ? location.pathname === url : location.pathname.startsWith(url)
  // We need this in order to prevent the link from being clicked when it's already active, that way we are losing search params
  const shouldDisableClick = isActive && location.pathname === url

  return (
    <StyledBox>
      <Link component={RRLink} to={url} underline="none" color="background.default" onClick={e => shouldDisableClick && e.preventDefault()}>
        <StyledTypography variant="body3" isActive={isActive}>
          {children}
        </StyledTypography>
      </Link>
    </StyledBox>
  )
}

export default AppHeaderLink
