import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { AppBar, Box, Container, Grid, Hidden, Toolbar, Typography } from '@mui/material'
import { APP_HEADER_HEIGHT } from '@/utils/constants'
import { APP_HEADER_LINKS } from '@/utils/routes'
import { RoleProtected } from '@/components'
import AppHeaderLink from './components/AppHeaderLink'
import AccountMenu from './components/AccountMenu'

type AppHeaderProps = {
  showLinks?: boolean
}

const AppHeader: FC<AppHeaderProps> = ({ showLinks = true }) => {
  const { t } = useTranslation('app-header')

  return (
    <>
      <AppBar color="secondary" position="relative">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid container alignItems="center" display="grid" gridAutoFlow="column" sx={{ minHeight: APP_HEADER_HEIGHT }}>
              <Grid item justifySelf="flex-start">
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item>
                    <Box display="flex">
                      <img alt="KEO logo" src="/logos/workeo_1024.png" height={45}/>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Hidden mdDown>
                      <Typography variant="h5">
                        {t('title')}
                      </Typography>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  {showLinks && APP_HEADER_LINKS.map(({ label, role, strict, url }) => (
                    <RoleProtected role={role} key={url}>
                      <Grid item>
                        <AppHeaderLink url={url} strict={strict}>{t(label)}</AppHeaderLink>
                      </Grid>
                    </RoleProtected>
                  ))}
                </Grid>
              </Grid>
              <Grid item justifySelf="flex-end">
                <AccountMenu/>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default memo(AppHeader)
