"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Locale = void 0;
var Locale;
(function (Locale) {
    Locale["EN_US"] = "en-US";
    Locale["EN_CA"] = "en-CA";
    Locale["ES_ES"] = "es-ES";
    Locale["ES_MX"] = "es-MX";
    Locale["ES_PE"] = "es-PE";
})(Locale = exports.Locale || (exports.Locale = {}));
