import { PaletteOptions } from '@mui/material/styles/createPalette'

const KEOColors: PaletteOptions = {
  primary: {
    main: '#FF004C',
    light: '#FF8DAF',
    dark: '#AA0033',
  },
  secondary: {
    main: '#00172D',
  },
  text: {
    primary: '#000A1E',
    secondary: 'rgba(0, 4, 12, 0.56)',
    disabled: 'rgba(0, 10, 30, 0.38)',
  },
  error: {
    main: '#FF0045',
    light: '#FFEDF2',
    dark: '#960029',
  },
  success: {
    main: '#2BB161',
    light: '#E2FFEE',
    dark: '#046B2E',
  },
  warning: {
    main: '#EFBC00',
    light: '#FFFAE9',
    dark: '#BC9506',
  },
  info: {
    main: '#2196F3',
    light: '#F0F8FF',
    dark: '#0960A5',
  },
  grey: {
    50: '#F5F6F7',
    200: '#D7DBDE',
    500: '#9BA6AD',
    900: '#4B5F6C',
  },
  divider: '#EAECEE',
  background: {
    paper: '#F5F6F7',
    default: '#FFFFFF',
  },
}

export default KEOColors
