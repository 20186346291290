import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Config, ContextId, Currency, FinanceProviderName, Stablecoin } from '@keo/shared-types'
import { getConfig } from '@/api/config'
import { useSession } from '@/utils/session'
import { getLocalStorageItem, setLocalStorageItem } from '@keo/frontend/utils/browser'
import Api from '@/utils/api'
import { GlobalLoader } from '@/components'
import { ApiQueryKeys } from '@/api/hooks/queries'

export const APP_CONTEXT_KEY = 'appContext'

type AppContextStore = Config & {
  isLoading: boolean
  appContext?: ContextId
}

const AppContextCtx = createContext<AppContextStore | null>(null)

export type AppContextProviderProps = {
  children: ReactNode
}

export const AppContextProvider: FC<AppContextProviderProps> = ({ children }) => {
  const [ appContext, setAppContext ] = useState<ContextId>()
  const { session } = useSession()
  const { isLoading, data } = useQuery([ ApiQueryKeys.CONFIG, appContext ], getConfig, { enabled: !!session && !!appContext })

  useEffect(() => {
    if (session) {
      const storedContext = getLocalStorageItem(APP_CONTEXT_KEY) as ContextId

      // TODO: Refactor this once we support multiple contexts
      const isCurrentContextAllowed = storedContext === session.context
      Api.setAppContext(session.context)
      setAppContext(session.context)

      if (!isCurrentContextAllowed) {
        setLocalStorageItem(APP_CONTEXT_KEY, session.context)
      }
    }
  }, [ session ])

  const configDetails: AppContextStore = useMemo(() => ({
    isLoading,
    verifierAddress: data?.verifierAddress || 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ',
    appStablecoin: data?.appStablecoin || Stablecoin.USDC,
    appCurrency: data?.appCurrency || Currency.USD,
    currencyAssetId: data?.currencyAssetId || 0,
    lendingPoolAppId: data?.lendingPoolAppId || 0,
    provider: data?.provider || FinanceProviderName.CIRCLE,
    features: data?.features!,
    appContext,
  }), [ data ])

  const memoizedChildren = useMemo(() => children, [ children ])

  if (isLoading) {
    return <GlobalLoader />
  }

  return (
    <AppContextCtx.Provider value={configDetails}>
      {memoizedChildren}
    </AppContextCtx.Provider>
  )
}

export const useConfig = () => {
  return useContext(AppContextCtx) as AppContextStore
}
