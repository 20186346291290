import { FC, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import loadable from '@loadable/component'
import { RangeFilter } from '@keo/shared-types'
import { Widget } from '@/components'
import { getTransactionHistory } from '@/api/transactions'
import DateRangeSelector from '@/components/DateRangeSelector'
const Chart = loadable(() => import('@/components/Chart'))

const dataLabels = [ 'currentPeriod', 'previousPeriod' ]

const TotalTransactionsChart: FC = () => {
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const [ range, setRange ] = useState<RangeFilter>(RangeFilter.MONTH)

  const { data, isLoading } = useQuery([ 'transactionAmountHistory', range ], () => getTransactionHistory(range))

  const enrichedData = useMemo(() => {
    if (!data) {
      return
    }

    return [
      {
        id: `${t('common:current')} ${t(`common:${range}`)}`,
        data: data.currentView.map((d) => ({
          x: d.range,
          y: d.amount,
        }))
      },
      {
        id: `${t('common:previous')} ${t(`common:${range}`)}`,
        data: data.previousView.map((d) => ({
          x: d.range,
          y: d.amount,
        }))
      }
    ]
  }, [ data, dataLabels, range ])

  return (
    <Widget
      hasData={!!enrichedData}
      // We need to provide height for chart
      height={400}
      isLoading={isLoading}
      meta={<DateRangeSelector onChange={setRange} value={range} />}
      title={t('totalAmountTransacted')}
    >
      {enrichedData && <Chart data={enrichedData} />}
    </Widget>
  )
}

export default TotalTransactionsChart
