"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditLogStatuses = exports.AuditLogMethods = void 0;
var AuditLogMethods;
(function (AuditLogMethods) {
    AuditLogMethods["GET"] = "GET";
    AuditLogMethods["POST"] = "POST";
    AuditLogMethods["PUT"] = "PUT";
    AuditLogMethods["PATCH"] = "PATCH";
    AuditLogMethods["DELETE"] = "DELETE";
})(AuditLogMethods = exports.AuditLogMethods || (exports.AuditLogMethods = {}));
var AuditLogStatuses;
(function (AuditLogStatuses) {
    AuditLogStatuses["SUCCEEDED"] = "SUCCEEDED";
    AuditLogStatuses["FAILED"] = "FAILED";
})(AuditLogStatuses = exports.AuditLogStatuses || (exports.AuditLogStatuses = {}));
