import React, { FC, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLendingPoolDepositInstructions } from '@/api/hooks'
import { GlobalLoader } from '@/components'
import DetailsItem from '@/components/Details/DetailsItem'

type StableCorpInstructionsProps = {
  address: string
}

const StableCorpInstructions: FC<StableCorpInstructionsProps> = ({}) => {
  const { t } = useTranslation('settings', { keyPrefix: 'lendingPoolManagement.modal.deposit.instructions.grapes' })
  const { data: instructions, isLoading } = useLendingPoolDepositInstructions()

  const valueContainerProps = useMemo(() => ({
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  }), [])

  if(isLoading) {
    return <GlobalLoader />
  }

  return (
    <Stack gap={2}>
      <Typography variant="body2">{t('title')}</Typography>
      { !instructions && <Typography color="text.secondary">{t('noInstructions')}</Typography>}
      { instructions && (
        <>
          <Typography mb={2}>{t('description')}</Typography>
          <Typography variant="body2">{t('beneficiaryDetails')}</Typography>
          <DetailsItem
            label={t('routingNumber')}
            canCopy
            value={instructions.beneficiaryBank.routingNumber}
            valueContainerProps={valueContainerProps}
          />
          <DetailsItem
            label={t('accountNumber')}
            canCopy
            value={instructions.beneficiaryBank.accountNumber}
            valueContainerProps={valueContainerProps}
          />
          <DetailsItem
            label={t('beneficiaryName')}
            canCopy
            value={instructions.beneficiary.name}
            valueContainerProps={valueContainerProps}
          />
          <DetailsItem
            label={t('beneficiaryAddress')}
            canCopy
            value={`${instructions.beneficiary.address1}, ${instructions.beneficiary.address2}`}
            valueContainerProps={valueContainerProps}
          />
          <Typography variant="body2" sx={{ marginTop: 2 }}>{t('beneficiaryBankDetails')}</Typography>
          <DetailsItem
            label={t('bankName')}
            canCopy
            value={instructions.beneficiaryBank.name}
            valueContainerProps={valueContainerProps}
          />
          <DetailsItem
            label={t('bankAddress')}
            canCopy
            // eslint-disable-next-line max-len
            value={`${instructions.beneficiaryBank.address}, ${instructions.beneficiaryBank.city}, ${instructions.beneficiaryBank.postalCode}, ${instructions.beneficiaryBank.country}`}
            valueContainerProps={valueContainerProps}
          />
          <DetailsItem
            label={t('swiftCode')}
            canCopy
            value={instructions.beneficiaryBank.swiftCode}
            valueContainerProps={valueContainerProps}
          />
          {instructions.trackingRef && (
            <DetailsItem
              label={t('reference')}
              canCopy
              value={instructions.trackingRef}
              valueContainerProps={valueContainerProps}
            />
          )}
        </>
      )}
    </Stack>
  )
}

export default StableCorpInstructions
