export enum ApiQueryKeys {
  ACCOUNTS = 'ACCOUNTS',
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  ACCOUNT_SETTING_BEST_MATCH = 'ACCOUNT_SETTING_BEST_MATCH',
  ACCOUNT_SETTINGS_USAGE = 'ACCOUNT_SETTINGS_USAGE',
  ADMIN_USERS = 'ADMIN_USERS',
  AUDIT_LOGS = 'AUDIT_LOGS',
  AUTOMATIONS_SUMMARY = 'AUTOMATIONS_SUMMARY',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CONFIG = 'CONFIG',
  FEATURES_STATUS = 'FEATURES_STATUS',
  HEALTH_CHECK = 'HEALTH_CHECK',
  INVOICES = 'INVOICES',
  LATEST = 'LATEST',
  LENDING_POOL_DEPOSIT_INSTRUCTIONS = 'LENDING_POOL_DEPOSIT_INSTRUCTIONS',
  LOANS = 'LOANS',
  PROCESSING_FEE_STRATEGIES = 'PROCESSING_FEE_STRATEGIES',
  SUPPLIER_APPROVAL_AUTOMATION = 'SUPPLIER_APPROVAL_AUTOMATION',
  SYSTEM_BALANCE = 'SYSTEM_BALANCE',
  TRANSACTIONS = 'TRANSACTIONS',
  WALLETS = 'WALLETS',
  WITHDRAW_AUTOMATION = 'WITHDRAW_AUTOMATION',
}
