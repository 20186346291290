import Api from '@/utils/api'
import { queryParamsToUrl } from '@keo/frontend/utils/url'
import {
  AddAdminUserParams,
  AdminUser,
  EditAdminUserParams,
  GetAdminUsersParams,
  GetAdminUsersRequestParams,
  GetAdminUsersResponse,
} from '@/types'

const PATH_PREFIX = '/admin/users'



export async function getAdminUsers(params: GetAdminUsersParams) {
  const { pagination, filters, sorting } = params
  const { searchTerm } = filters

  const queryParams: GetAdminUsersRequestParams = {
    ...pagination,
    // Spreading the following fields to avoid sending empty strings to the API
    ...searchTerm && { searchTerm },
    ...sorting && { sorting }
  }

  const url = queryParamsToUrl(`${PATH_PREFIX}`, queryParams)
  const res = await Api.get<GetAdminUsersResponse>(url)

  return res.data
}

export async function addAdminUser(params: AddAdminUserParams) {
  const res = await Api.post<AdminUser, AddAdminUserParams>(`${PATH_PREFIX}`, params)

  return res.data
}

export async function editAdminUser({ id, params }: { id: number, params: EditAdminUserParams }) {
  const url = `${PATH_PREFIX}/${id}`
  const res = await Api.patch<AdminUser, EditAdminUserParams>(url, params)

  return res.data
}

export async function deleteAdminUser(id: number) {
  const url = `${PATH_PREFIX}/${id}`
  const res = await Api.del(url)

  return res.data
}
