import Api from '@/utils/api'
import { Loan, RangeFilter, SummaryLent, SummaryLentComparison } from '@keo/shared-types'
import { GetLoansParams, GetLoansRequestParams, GetLoansResponse } from '@/types'
import { queryParamsToUrl } from '@keo/frontend/utils/url'
import { parseDateExcludingTime } from '@/utils/l10n'
import { removeUndefinedAndEmptyValues } from '@/utils/parsers'

const PATH_PREFIX = '/loans'

export async function getLoans(params: GetLoansParams) {
  const { pagination, filters, sorting } = params
  const { invoiceAmountFrom, invoiceAmountTo, createdDateFrom, createdDateTo, dueDateFrom, dueDateTo, loanPayBackStatuses, searchTerm, accountId } = removeUndefinedAndEmptyValues(filters)

  const queryParams: GetLoansRequestParams = {
    ...pagination,
    ...invoiceAmountFrom && { invoiceAmountFrom: Number(invoiceAmountFrom) },
    ...invoiceAmountTo && { invoiceAmountTo: Number(invoiceAmountTo) },
    ...searchTerm && { searchTerm },
    ...createdDateFrom && { createdDateFrom: parseDateExcludingTime(createdDateFrom) },
    ...createdDateTo && { createdDateTo: parseDateExcludingTime(createdDateTo) },
    ...dueDateFrom && { dueDateFrom: parseDateExcludingTime(dueDateFrom) },
    ...dueDateTo && { dueDateTo: parseDateExcludingTime(dueDateTo) },
    ...accountId && { accountId },
    ...loanPayBackStatuses && { loanPayBackStatuses: loanPayBackStatuses.join(',') },
    ...sorting && { sorting },
  }

  const url = queryParamsToUrl(`${PATH_PREFIX}`, queryParams)
  const res = await Api.get<GetLoansResponse>(url)

  return res.data
}

export async function getLoan(id: number, populateFinancialDetails = false) {
  const queryParams = populateFinancialDetails ? `?populateFinancialDetails=${populateFinancialDetails}` : ''
  const url = `${PATH_PREFIX}/${id}${queryParams}`
  const res = await Api.get<Loan>(url)

  return res.data
}

export async function getLoansSummary() {
  const url = `${PATH_PREFIX}/summaryLent`
  const res = await Api.get<SummaryLent>(url)
  return res.data
}

export async function getLoansSummaryComparisson(range: RangeFilter) {
  const url = `${PATH_PREFIX}/summaryLentComparison?range=${range}`
  const res = await Api.get<SummaryLentComparison>(url)
  return res.data
}

export async function getBestPerformingLoans(params: Pick<GetLoansParams, 'pagination'>) {
  const baseUrl = `${PATH_PREFIX}/ROIOfLoans`
  const url = queryParamsToUrl(baseUrl, params.pagination)
  const res = await Api.get<Loan[]>(url)
  return res.data
}
