import { FC, PropsWithChildren, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { setUser } from '@sentry/react'
import { AdminRoles } from '@keo/shared-types'
import { useSession } from '@/utils/session'
import { AppHeader, DowntimeHandler, GlobalLoader, SystemUnavailable } from '@/components'
import { urls } from '@/utils/routes'
import { ExternalWalletProvider, useExternalWallet } from '@/components/hooks/useExternalWallet'
import ProtectedPage from './ProtectedPage'
import { useSystemStatus } from '../hooks/useSystemStatus'

const ProtectedRoute: FC<PropsWithChildren> = () => {
  const { isLoading, session } = useSession()
  const location = useLocation()
  const { isConnected, disconnect } = useExternalWallet()
  const { isOnlyDowntimeFlag } = useSystemStatus()

  useEffect(() => {
    // Set user for Sentry
    session && setUser({ id: String(session.id) })
  }, [ session?.id ])

  if (isLoading) {
    return <GlobalLoader/>
  }

  if (!session) {
    if(isConnected) {
      disconnect()
    }

    const redirectTo = encodeURIComponent(location.pathname + location.search)
    return <Navigate to={`${urls.LOGIN}?sessionExpired=1&redirectTo=${redirectTo}`}/>
  }

  // If system is down because of downtime flag
  if (isOnlyDowntimeFlag) {
    // If user is processor or processor admin, show downtime form page
    if ([ AdminRoles.PROCESSOR, AdminRoles.PROCESSOR_ADMIN ].includes(session.adminRole)) {
      return (
        <>
          <AppHeader showLinks={false} />
          <DowntimeHandler />
        </>
      )
    }
    // Otherwise show system unavailable page
    return (
      <>
        <AppHeader showLinks={false} />
        <SystemUnavailable />
      </>
    )
  }

  return (
    <>
      <AppHeader />
      <ProtectedPage>
        <Outlet />
      </ProtectedPage>
    </>
  )
}

const ProtectedRouteWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ExternalWalletProvider>
      <ProtectedRoute>
        {children}
      </ProtectedRoute>
    </ExternalWalletProvider>
  )
}

export default ProtectedRouteWrapper
