import { queryParamsToUrl } from '@keo/frontend/utils/url'
import { ApiPagination } from '@keo/shared-types'
import Api from '@/utils/api'
import { GetInvoicesRequestParams, GetInvoicesResponse, OnFilterInvoicesParams } from '@/types'
import { removeUndefinedAndEmptyValues } from '@/utils/parsers'

const PATH_PREFIX = '/invoices'

type GetInvoicesParams = {
  pagination: ApiPagination
  filters: OnFilterInvoicesParams
  sorting?: string
}

export async function getInvoices(params: GetInvoicesParams) {
  const { pagination, filters, sorting } = params
  const { searchTerm, approvalStatus, validationStatus } = removeUndefinedAndEmptyValues(filters)

  const queryParams: GetInvoicesRequestParams = {
    ...pagination,
    // Spreading the following fields to avoid sending empty strings to the API
    ...searchTerm && { searchTerm },
    ...approvalStatus?.length && { approvalStatus: approvalStatus.join(',') },
    ...validationStatus?.length && { validationStatus: validationStatus.join(',') },
    ...sorting && { sorting },
  }

  const url = queryParamsToUrl(`${PATH_PREFIX}`, queryParams)
  const res = await Api.get<GetInvoicesResponse>(url)

  return res.data
}
