import { FC } from 'react'
import { Chip, Tooltip, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {  InvoicesValidationStatus } from '@keo/shared-types'
import InvoiceValidationStatusIcon from './InvoiceValidationStatusIcon'
import { isPendingStatus } from '../utils'

type InvoiceValidationStatusProps = {
  validationStatus: InvoicesValidationStatus
}

function getValidationLabel(status: InvoicesValidationStatus): string {
  if (isPendingStatus(status)) {
    return 'validationStatusLabel.pending'
  }

  return 'validationStatusLabel.invalid'
}

function useValidationColor(status: InvoicesValidationStatus): string {
  const theme = useTheme()
  if (isPendingStatus(status)) {
    return theme.palette.info.main
  }

  return theme.palette.error.main
}

const InvoiceValidationStatus: FC<InvoiceValidationStatusProps> = ({ validationStatus }) => {
  const { t } = useTranslation([ 'invoices' ])
  const color = useValidationColor(validationStatus)

  return (
    <Tooltip title={t(`validationStatus.${validationStatus}`)} enterDelay={0}>
      <Chip
        icon={<InvoiceValidationStatusIcon status={validationStatus} />}
        label={t(getValidationLabel(validationStatus))}
        size="small"
        sx={{ borderColor: color, color }}
        variant="outlined"
      />
    </Tooltip>
  )
}

export default InvoiceValidationStatus