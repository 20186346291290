import React, { FC } from 'react'
import { Grid, InputAdornment, Typography, useTheme } from '@mui/material'
import { FormInput } from '@/components'
import { useFormContext } from 'react-hook-form'

type CustomRangeFilterProps = {
  label: string,
  fromField: string,
  fromPlaceholder?: string,
  toField: string,
  toPlaceholder?: string,
}

const CurrencyRangeFilter: FC<CustomRangeFilterProps> = ({
  label,
  fromField,
  fromPlaceholder,
  toField,
  toPlaceholder
}) => {
  const theme = useTheme()
  const { control, trigger } = useFormContext()

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <FormInput
            control={control}
            name={fromField}
            placeholder={fromPlaceholder}
            label={label}
            size="small"
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            inputProps={{
              min: 0,
              step: '0.01'
            }}
            onChange={() => {
              trigger(toField)
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography
            variant="caption"
            color="text.secondary"
            textAlign="center"
            sx={{ marginTop: theme.spacing(6), display: 'block' }} // additional margin to align with the input
          >
            {/* emdash character */}
            &#8212;
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <FormInput
            control={control}
            name={toField}
            placeholder={toPlaceholder}
            label=" " // used to align with the first input
            size="small"
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            inputProps={{
              min: 0,
              step: '0.01'
            }}
            onChange={() => {
              trigger(fromField)
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CurrencyRangeFilter
