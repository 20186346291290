import Api from '@/utils/api'
import { AccountType, IAccountsSettings, TargetAccountsSettings, TargetSettings } from '@keo/shared-types'
import { queryParamsToUrl } from '@keo/frontend/utils/url'
import { GetAccountSettingsParams } from '@/types'

const PATH_PREFIX = '/account/settings'

export async function getAccountSettings(params: GetAccountSettingsParams) {
  const { accountId, appliedToId, type, appSettingId } = params
  const queryParams: GetAccountSettingsParams = {
    accountId,
    appliedToId,
    type,
    ...(appSettingId && { appSettingId }),
  }
  const url = queryParamsToUrl(`${PATH_PREFIX}/account-settings`, queryParams)
  const res = await Api.get<IAccountsSettings<TargetAccountsSettings>[]>(url)

  return res.data
}

type SetAccountSettingsPayload = {
  accountId: string
  appliedToId?: string
  type: TargetAccountsSettings
}

export type SetProcessingFeeStrategyPayload = SetAccountSettingsPayload & {
  processingFeeStrategyIdData: {
    processingFeeStrategyId: number
  }
}

export type SetPaybackStrategyPayload = SetAccountSettingsPayload & {
  paybackStrategyIdData: {
    paybackStrategyId: number
  }
}

export type SetStrategySettingParams = {
  accountId: string
  appliedToId?: string
  strategyId: number
}

export async function setProcessingFeeStrategySetting(params: SetStrategySettingParams): Promise<void> {
  const { accountId, appliedToId, strategyId } = params
  const res = await Api.post<void, SetProcessingFeeStrategyPayload>(PATH_PREFIX, {
    type: TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID,
    accountId,
    appliedToId,
    processingFeeStrategyIdData: {
      processingFeeStrategyId: strategyId,
    },
  })

  return res.data
}

export async function setPaybackStrategySetting(params: SetStrategySettingParams): Promise<void> {
  const { accountId, strategyId } = params
  const res = await Api.post<void, SetPaybackStrategyPayload>(PATH_PREFIX, {
    type: TargetAccountsSettings.PAYBACK_STRATEGY_ID,
    accountId,
    paybackStrategyIdData: {
      paybackStrategyId: strategyId,
    },
  })

  return res.data
}

export type UpdateStrategySettingParams = {
  accountId: string
  appliedToId?: string
  accountSettingId: number
  strategyId: number
}

export async function updateProcessingFeeStrategySetting(params: UpdateStrategySettingParams): Promise<void> {
  const { accountId, appliedToId, strategyId, accountSettingId } = params
  const url = `${PATH_PREFIX}/${accountSettingId}`
  const res = await Api.put<void, SetProcessingFeeStrategyPayload>(url, {
    type: TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID,
    accountId,
    appliedToId,
    processingFeeStrategyIdData: {
      processingFeeStrategyId: strategyId,
    },
  })

  return res.data
}

export async function updatePaybackStrategySetting(params: UpdateStrategySettingParams): Promise<void> {
  const { accountId, strategyId, accountSettingId } = params
  const url = `${PATH_PREFIX}/${accountSettingId}`
  const res = await Api.put<void, SetPaybackStrategyPayload>(url, {
    type: TargetAccountsSettings.PAYBACK_STRATEGY_ID,
    accountId,
    paybackStrategyIdData: {
      paybackStrategyId: strategyId,
    },
  })

  return res.data
}

export type DeleteAccountSettingParams = {
  accountSettingId: number
}

export async function deleteAccountSetting(params: DeleteAccountSettingParams): Promise<void> {
  const { accountSettingId } = params
  const url = `${PATH_PREFIX}/${accountSettingId}`
  const res = await Api.del<void>(url)

  return res.data
}

export type GetAccountSettingsUsageParams = {
  appSettingId: number
  type: TargetSettings
  accountType: AccountType
}

export async function getAccountSettingUsage(params: GetAccountSettingsUsageParams): Promise<number> {
  const { appSettingId, type, accountType } = params
  const queryParams = {
    appSettingId,
    type,
    accountType,
  }
  const url = queryParamsToUrl(`${PATH_PREFIX}/count-accounts-by-app-setting`, queryParams)
  const res = await Api.get<number>(url)

  return res.data
}

export type GetDefaultAccountSettingParams<T extends TargetAccountsSettings> = {
  accountId: string
  appliedToId: string
  type: T
}

export async function getDefaultAccountSetting<
  T extends TargetAccountsSettings
>(params: GetDefaultAccountSettingParams<T>) {
  const url = queryParamsToUrl(`${PATH_PREFIX}/account-settings/best-match`, params)
  const res = await Api.get<IAccountsSettings<T>>(url)
  return res.data
}


