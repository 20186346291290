import { FC } from 'react'
import { Grid } from '@mui/material'
import { DateRangeFilter } from '@/components/forms/RangeFilter'
import { useTranslation } from 'react-i18next'

const AuditLogsFilters: FC = () => {
  const { t } = useTranslation('common')

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} lg={4}>
        <DateRangeFilter
          label={t('date')}
          fromField="dateFrom"
          toField="dateTo"
          fromPlaceholder={t('from')}
          toPlaceholder={t('to')}
        />
      </Grid>
    </Grid>
  )
}

export default AuditLogsFilters
