import { useContext } from 'react'
import { SystemDataContext } from '../SystemChecker'

export function useSystemStatus() {
  const systemStatus = useContext(SystemDataContext)
  if (!systemStatus) {
    throw new Error('useSystemStatus is not used properly')
  }
  return systemStatus
}
