import { FC } from 'react'
import { Box, Checkbox, IconButton, ListItemText, MenuItem, Select, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Controller, useFormContext } from 'react-hook-form'
import { parseFormFieldError } from '@/utils/parsers'
import { useTranslation } from 'react-i18next'

type SelectFilterProps = {
  allOptionText?: string
  disabled?: boolean
  label: string,
  fieldName: string,
  onClear?: () => void
  options: Array<{ value: string | number, label: string }>
  multiple?: boolean
  size?: 'small' | 'medium'
}

const SelectFilter: FC<SelectFilterProps> = ({ allOptionText, disabled, label, fieldName, multiple, onClear, options, size = 'small' }) => {
  const { t } = useTranslation([ 'errorMessages', 'common' ])
  const { control } = useFormContext()

  return (
    <Box minWidth={140}>
      <Box mb={1} ml={2}>
        <Typography variant="caption" color="text.secondary">{label}</Typography>
      </Box>
      <Controller
        name={fieldName}
        control={control}
        render={({ fieldState, field }) => (
          <>
            <Select
              endAdornment={
                !!field.value && onClear ? (
                <IconButton
                  sx={{ mr: 2 }}
                  onClick={onClear}
                >
                  <ClearIcon/>
                </IconButton>
              ) : undefined}
              multiple={multiple}
              size={size}
              fullWidth
              disabled={disabled}
              displayEmpty
              renderValue={multiple ?
                (selected) => options.map(({ value, label: opLabel }) => selected.includes(value) ? opLabel : null).filter(Boolean).join(', ')
                :
                undefined
              }
              {...field}
            >
              {allOptionText && <MenuItem value="">{allOptionText}</MenuItem>}
              {!multiple && options.map(({ value, label: opLabel }) => <MenuItem key={value} value={value}>{opLabel}</MenuItem>)}
              {multiple && options.map(({ value, label: opLabel }) => (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={field.value.includes(value)} />
                  <ListItemText primary={opLabel} />
                </MenuItem>
              ))}
            </Select>
            {fieldState.error &&
            <Typography color="error" variant="caption" sx={{ ml: 2 }}>
              {t(parseFormFieldError(fieldState.error).key, { label })}
            </Typography>}
          </>
        )}
      />
    </Box>
  )
}

export default SelectFilter
