import {
  ApiPagination,
  CallbackUrlFields,
  CashOutLendingPoolParams,
  DepositParametersFields,
  FraudCheckUrlFields,
  ILendingPool,
  ILendingPoolSummary,
  ISettings,
  LendingPoolStateParams,
  LoansCheckFields,
  PaginatedResult,
  PaybackStrategyStepsFields,
  ProcessingFeeTemplates,
  ProcessingFeeStrategy,
  SupplierUSDCWarningAmountFields,
  TargetSettings,
  IDelegationContract,
  FeatureFlagsFields
} from '@keo/shared-types'
import { queryParamsToUrl } from '@keo/frontend/utils/url'
import Api from '@/utils/api'
import {
  AddApiKeyParams,
  AddApiKeyResponse,
  CallbackUrlPayload,
  EditApiKeyParams,
  GetApiKeysResponse,
  GetCallbackUrlsResponse
} from '@/types'
import { PaybackStrategySetting, ProcessingFeeStrategySetting } from '@/types/settings'

const AUTH_PATH_PREFIX = '/auth/apiKey'
const LENDING_POOL_PREFIX = '/lending-pool'
const SETTINGS_PREFIX = '/settings'
const AUTOMATIONS_PREFIX = '/settings/automations'

export async function deleteSetting(id: number | string): Promise<void> {
  await Api.del(`${SETTINGS_PREFIX}/${id}`)
}

export const getApiKeys = async () => {
  const res = await Api.get<GetApiKeysResponse>(AUTH_PATH_PREFIX)
  return res.data
}

export async function addApiKey(params: AddApiKeyParams) {
  const res = await Api.post<AddApiKeyResponse, AddApiKeyParams>(`${AUTH_PATH_PREFIX}`, params)

  return res.data
}

export async function editApiKey({ id, params }: { id: string, params: EditApiKeyParams }) {
  const url = `${AUTH_PATH_PREFIX}/${id}`
  const res = await Api.patch(url, params)

  return res.data
}

export async function deleteApiKey(id: string) {
  const url = `${AUTH_PATH_PREFIX}/${id}`
  const res = await Api.del(url)

  return res.data
}

export async function getLendingPoolState() {
  const url = `${LENDING_POOL_PREFIX}/getLendingPoolState`
  const res = await Api.get<ILendingPool>(url)
  return res.data
}

export async function getLendingPoolSummary() {
  const url = `${LENDING_POOL_PREFIX}/getLendingPoolSummary`
  const res = await Api.get<ILendingPoolSummary[]>(url)
  return res.data
}

export async function cashOut(params: CashOutLendingPoolParams) {
  const url = `${LENDING_POOL_PREFIX}/cashOut`
  const body = {
    appId: params.appId,
    amount: params.amount
  }
  await Api.post<void, CashOutLendingPoolParams>(url, body)
}

export async function updateLendingPoolState(params: LendingPoolStateParams) {
  const url = `${LENDING_POOL_PREFIX}/updateLendingPoolState`
  const body = {
    ...(params.payFee !== undefined && { payFee: params.payFee }),
  }
  const res = await Api.patch<ILendingPool, LendingPoolStateParams>(url, body)
  return res.data
}

async function getSettingByType<T extends TargetSettings>(type: T) {
  const params = {
    page: 1,
    perPage: 1,
    type,
  }
  const url = queryParamsToUrl(SETTINGS_PREFIX, params)
  const res = await Api.get<PaginatedResult<ISettings<T>>>(url)
  return res.data.results[ 0 ]
}

export async function getPaybackStrategies(params: ApiPagination) {
  const { page , perPage  } = params
  const url = SETTINGS_PREFIX
  const queryParams = {
    page,
    perPage,
    type: TargetSettings.PAYBACK_STRATEGIES,
  }
  const res = await Api.get<PaginatedResult<PaybackStrategySetting>>(queryParamsToUrl(url, queryParams))
  return res.data
}

export async function getProcessingFeeStrategies(params: ApiPagination) {
  const { page , perPage  } = params
  const url = SETTINGS_PREFIX
  const queryParams = {
    page,
    perPage,
    type: TargetSettings.PROCESSING_FEE_STRATEGIES,
  }
  const res = await Api.get<PaginatedResult<ProcessingFeeStrategySetting>>(queryParamsToUrl(url, queryParams))
  return res.data
}

interface ProcessingFeeStrategyDataBase {
  isDefault: boolean
  name: string,
}

interface ProcessingFeeFlatFeeStrategyData extends ProcessingFeeStrategyDataBase {
  template: ProcessingFeeTemplates.FLAT_FEE
  templateFlatFeeData: {
    flatFee: number
  }
}

interface ProcessingFeeThresholdStrategyData extends ProcessingFeeStrategyDataBase {
  template: ProcessingFeeTemplates.THRESHOLD
  templateThresholdData: {
    feeBelowThreshold: number
    feeAboveThreshold: number
    amountThreshold: number
  }
}

export type ProcessingFeeStrategyData = ProcessingFeeFlatFeeStrategyData | ProcessingFeeThresholdStrategyData

interface AddProcessingFeeStrategyPayload {
  type: TargetSettings
  processingFeeStrategyData: ProcessingFeeStrategyData
}

export async function addProcessingFeeStrategy(strategy: ProcessingFeeStrategyData): Promise<ISettings<TargetSettings.PROCESSING_FEE_STRATEGIES>> {
  const url = SETTINGS_PREFIX
  const payload: AddProcessingFeeStrategyPayload = {
    type: TargetSettings.PROCESSING_FEE_STRATEGIES,
    processingFeeStrategyData: strategy,
  }
  const res = await Api.post<ISettings<TargetSettings.PROCESSING_FEE_STRATEGIES>, AddProcessingFeeStrategyPayload>(url, payload)
  return res.data
}

export interface UpdateProcessingFeeStrategyPayload {
  id: number
  strategy: ProcessingFeeStrategy
}

export async function updateProcessingFeeStrategy(params: UpdateProcessingFeeStrategyPayload): Promise<void> {
  const payload = {
    type: TargetSettings.PROCESSING_FEE_STRATEGIES,
    processingFeeStrategyData: params.strategy,
  }
  await updateSettings<AddProcessingFeeStrategyPayload>(params.id, payload)
}

export async function deleteProcessingFeeStrategy(id: number): Promise<void> {
  await deleteSetting(id)
}

export async function addSettings<T>(payload: T): Promise<T> {
  const res = await Api.post<T, T>(SETTINGS_PREFIX, payload)
  return res.data
}

export async function updateSettings<T>(id: number, payload: T): Promise<T> {
  const url = `${SETTINGS_PREFIX}/${id}`
  const res = await Api.put<T, T>(url, payload)
  return res.data
}

interface addPaybackStrategyPayload {
  type: TargetSettings
  paybackStrategyStepsData: PaybackStrategyStepsFields
}

export async function addPaybackStrategy(strategy: PaybackStrategyStepsFields): Promise<ISettings<TargetSettings.PAYBACK_STRATEGIES>> {
  const url = SETTINGS_PREFIX
  const payload: addPaybackStrategyPayload = {
    type: TargetSettings.PAYBACK_STRATEGIES,
    paybackStrategyStepsData: strategy,
  }
  const res = await Api.post<ISettings<TargetSettings.PAYBACK_STRATEGIES>, addPaybackStrategyPayload>(url, payload)
  return res.data
}

interface updatePaybackStrategyPayload {
  id: number
  strategy: PaybackStrategyStepsFields
}

export async function updatePaybackStrategy(params: updatePaybackStrategyPayload): Promise<void> {
  const payload = {
    type: TargetSettings.PAYBACK_STRATEGIES,
    paybackStrategyStepsData: params.strategy,
  }
  await updateSettings<addPaybackStrategyPayload>(params.id, payload)
}

export async function deletePaybackStrategy(id: number): Promise<void> {
  await deleteSetting(id)
}

type GetCallbackUrlParams = {
  pagination: ApiPagination
}

export async function getCallbackUrls(params: GetCallbackUrlParams): Promise<GetCallbackUrlsResponse> {
  const queryParams = {
    ...params.pagination,
    type: TargetSettings.CALLBACK_URL,
  }
  const url = queryParamsToUrl(SETTINGS_PREFIX, queryParams)
  const res = await Api.get<GetCallbackUrlsResponse>(url)
  return res.data
}

export async function addCallbackUrl(params: CallbackUrlFields) {
  const payload: CallbackUrlPayload = {
    type: TargetSettings.CALLBACK_URL,
    callbackUrlData: params,
  }
  const res = await Api.post<ISettings<TargetSettings.CALLBACK_URL>, CallbackUrlPayload>(SETTINGS_PREFIX, payload)
  return res.data
}

export async function getFraudCheckUrl() {
  const params = {
    page: 1,
    perPage: 1,
    type: TargetSettings.FRAUD_CHECK_URL,
  }
  const url = queryParamsToUrl(SETTINGS_PREFIX, params)
  const res = await Api.get<PaginatedResult<ISettings<TargetSettings.FRAUD_CHECK_URL>>>(url)
  return res.data.results[ 0 ]
}

type UpdateFraudCheckUrlParams = {
  id: number
  url: string
}

type UpdateFraudCheckUrlPayload = {
  type: TargetSettings
  fraudCheckUrlData: FraudCheckUrlFields
}

export async function updateFraudCheckUrl({ id, url }: UpdateFraudCheckUrlParams): Promise<void> {
  const payload = {
    type: TargetSettings.FRAUD_CHECK_URL,
    fraudCheckUrlData: {
      url,
    },
  }
  await Api.put<ISettings<TargetSettings.FRAUD_CHECK_URL>, UpdateFraudCheckUrlPayload>(`${SETTINGS_PREFIX}/${id}`, payload)
}

export async function getDepositLimit() {
  const params = {
    page: 1,
    perPage: 1,
    type: TargetSettings.DEPOSIT_PARAMETERS,
  }
  const url = queryParamsToUrl(SETTINGS_PREFIX, params)
  const res = await Api.get<PaginatedResult<ISettings<TargetSettings.DEPOSIT_PARAMETERS>>>(url)
  return res.data.results[ 0 ]
}

type UpdateDepositLimitParams = {
  id: number
  depositLimit: number
}

type UpdateDepositLimitParamsPayload = {
  type: TargetSettings
  depositParametersData: DepositParametersFields
}

export async function updateDepositLimit({ id, depositLimit }: UpdateDepositLimitParams): Promise<void> {
  const payload = {
    type: TargetSettings.DEPOSIT_PARAMETERS,
    depositParametersData: { depositLimit },
  }
  await Api.put<ISettings<TargetSettings.DEPOSIT_PARAMETERS>, UpdateDepositLimitParamsPayload>(`${SETTINGS_PREFIX}/${id}`, payload)
}

async function getLoanSetting() {
  const params = {
    page: 1,
    perPage: 1,
    type: TargetSettings.LOANS_CHECK,
  }
  const url = queryParamsToUrl(SETTINGS_PREFIX, params)
  const res = await Api.get<PaginatedResult<ISettings<TargetSettings.LOANS_CHECK>>>(url)
  return res.data.results[ 0 ]
}

export async function getLoanOutOfSyncInterval() {
  const setting = await getLoanSetting()
  return {
    id: setting.id,
    loanOOSIntervalInMinutes: setting.value.loanOOSIntervalInMinutes,
  }
}

export async function getLoanOOSTooLongThresholdInterval() {
  const setting = await getLoanSetting()
  return {
    id: setting.id,
    loanOOSTooLongThresholdInHours: setting.value.loanOOSTooLongThresholdInHours,
  }
}

export async function getLoanOOSMonitorCronTime() {
  const setting = await getLoanSetting()
  return {
    id: setting.id,
    loanOOSMonitorCronTimeInMinutes: setting.value.loanOOSMonitorCronTimeInMinutes,
  }
}

type UpdateLoanSettingParams = {
  id: number
  param: keyof LoansCheckFields
  value: number
}

type UpdateLoanSettingPayload = {
  type: TargetSettings.LOANS_CHECK
  loansOOSData: Partial<LoansCheckFields>
}

async function updateLoanSettings({ id, param, value }: UpdateLoanSettingParams) {
  const payload: UpdateLoanSettingPayload = {
    type: TargetSettings.LOANS_CHECK,
    loansOOSData: {
      [ param ]: value,
    }
  }
  await Api.put<ISettings<TargetSettings.LOANS_CHECK>, UpdateLoanSettingPayload>
    (`${SETTINGS_PREFIX}/${id}`, payload)
}

type UpdateLoanOutOfSyncIntervalParams = {
  id: number
  intervalInMinutes: number
}

export function updateLoanOutOfSyncInterval({ id, intervalInMinutes } : UpdateLoanOutOfSyncIntervalParams): Promise<void> {
  return updateLoanSettings({ id, param: 'loanOOSIntervalInMinutes', value: intervalInMinutes })
}

type UpdateLoanOOSTooLongThresholdParams = {
  id: number
  thresholdInHours: number
}

export async function updateLoanOOSTooLongThresholdInterval({ id, thresholdInHours } : UpdateLoanOOSTooLongThresholdParams): Promise<void> {
  return updateLoanSettings({ id, param: 'loanOOSTooLongThresholdInHours', value: thresholdInHours })
}

type UpdateLoanOOSMonitorCronTimeParams = {
  id: number
  cronTimeInMinutes: number
}

export async function updateLoanOOSMonitorCronTime({ id, cronTimeInMinutes } : UpdateLoanOOSMonitorCronTimeParams): Promise<void> {
  return updateLoanSettings({ id, param: 'loanOOSMonitorCronTimeInMinutes', value: cronTimeInMinutes })
}

export async function getMaxSupplierBalanceLimit() {
  const params = {
    page: 1,
    perPage: 1,
    type: TargetSettings.SUPPLIER_USDC_WARNING_AMOUNT,
  }
  const url = queryParamsToUrl(SETTINGS_PREFIX, params)
  const res = await Api.get<PaginatedResult<ISettings<TargetSettings.SUPPLIER_USDC_WARNING_AMOUNT>>>(url)
  return res.data.results[ 0 ]
}

type UpdateMaxSupplierBalanceLimitParams = {
  id: number
  amount: number
}

type UpdateMaxSupplierBalanceLimitPayload = {
  type: TargetSettings
  supplierUSDCWarningAmountData: SupplierUSDCWarningAmountFields
}

export async function updateMaxSupplierBalanceLimit({ id, amount } : UpdateMaxSupplierBalanceLimitParams): Promise<void> {
  const payload = {
    type: TargetSettings.SUPPLIER_USDC_WARNING_AMOUNT,
    supplierUSDCWarningAmountData: {
      amount,
    }
  }
  await Api.put<ISettings<TargetSettings.SUPPLIER_USDC_WARNING_AMOUNT>, UpdateMaxSupplierBalanceLimitPayload>(`${SETTINGS_PREFIX}/${id}`, payload)
}

export async function getWithdrawAutomationSetting() {
  const params = {
    page: 1,
    perPage: 1,
    type: TargetSettings.AUTOMATE_WITHDRAWAL,
  }
  const url = queryParamsToUrl(SETTINGS_PREFIX, params)
  const res = await Api.get<PaginatedResult<ISettings<TargetSettings.AUTOMATE_WITHDRAWAL>>>(url)
  return res.data.results[ 0 ]
}

export async function getSupplierApprovalAutomationSetting() {
  const params = {
    page: 1,
    perPage: 1,
    type: TargetSettings.AUTOMATE_SUPPLIER_INVOICE_APPROVAL,
  }
  const url = queryParamsToUrl(SETTINGS_PREFIX, params)
  const res = await Api.get<PaginatedResult<ISettings<TargetSettings.AUTOMATE_SUPPLIER_INVOICE_APPROVAL>>>(url)
  return res.data.results[ 0 ]
}

type UpdateWithdrawAutomationSettingParams = {
  id: number
  cronExpr: string
}

type UpdateWithdrawAutomationSettingPayload = {
  type: TargetSettings
  automateWithdrawalData: {
    cronExpr: string
  }
}

export async function updateWithdrawAutomationSetting({ id, cronExpr }: UpdateWithdrawAutomationSettingParams) {
  const payload = {
    type: TargetSettings.AUTOMATE_WITHDRAWAL,
    automateWithdrawalData: {
      cronExpr,
    }
  }
  await Api.put<ISettings<TargetSettings.AUTOMATE_WITHDRAWAL>, UpdateWithdrawAutomationSettingPayload>(`${SETTINGS_PREFIX}/${id}`, payload)
}

type UpdateSupplierApprovalAutomationSettingParams = {
  id: number
  delayInMinutes: number
}

type UpdateSupplierApprovalAutomationSettingPayload = {
  type: TargetSettings
  automateSupplierInvoiceApprovalData: {
    delayInMinutes: number
  }
}

export async function updateSupplierApprovalAutomationSetting({ id, delayInMinutes }: UpdateSupplierApprovalAutomationSettingParams) {
  const payload = {
    type: TargetSettings.AUTOMATE_SUPPLIER_INVOICE_APPROVAL,
    automateSupplierInvoiceApprovalData: {
      delayInMinutes,
    }
  }
  await Api.put<ISettings<TargetSettings.AUTOMATE_SUPPLIER_INVOICE_APPROVAL>, UpdateSupplierApprovalAutomationSettingPayload>(`${SETTINGS_PREFIX}/${id}`, payload)
}

export async function getAutomationsSummary() {
  const res = await Api.get<IDelegationContract>(
    `${AUTOMATIONS_PREFIX}/summary`
  )
  return res.data
}

export async function enableAutomations(enabled: boolean) {
  const res = await Api.patch<IDelegationContract, { enabled: boolean }>(
    `${AUTOMATIONS_PREFIX}`,
    { enabled }
  )
  return res.data
}

export async function getFeatureFlagsSetting() {
  return getSettingByType(TargetSettings.FEATURE_FLAGS)
}

export type UpdateFeatureFlagsSettingParams = {
  id: number
  features: Partial<FeatureFlagsFields>
}

type UpdateFeatureFlagsSettingPayload = {
  type: TargetSettings
  featureFlagsData: UpdateFeatureFlagsSettingParams['features']
}

export async function updateFeatureFlagsSetting({ id, features }: UpdateFeatureFlagsSettingParams): Promise<ISettings<TargetSettings.FEATURE_FLAGS>> {
  const payload = {
    type: TargetSettings.FEATURE_FLAGS,
    featureFlagsData: features,
  }
  const res = await Api.put<ISettings<TargetSettings.FEATURE_FLAGS>, UpdateFeatureFlagsSettingPayload>(`${SETTINGS_PREFIX}/${id}`, payload)
  return res.data
}
