import React, { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { LENDING_POOL_DEPOSIT_CIRCLE_GUIDE } from '@/utils/constants'
import { CopyButton } from '@/components'
import { useTranslation } from 'react-i18next'

const CircleInstructions: FC<{
  address: string
}> = ({ address }) => {
  const { t } = useTranslation('settings', { keyPrefix: 'lendingPoolManagement.modal.deposit.instructions.circle' })
  return (
    <Stack spacing={2}>
      <Typography variant="body2">{t('title')}</Typography>
      <Typography color="text.secondary" component="p">
        {t('description.beforeLink')}
        <Box
          component="a"
          style={{ color: 'blue' }}
          href={LENDING_POOL_DEPOSIT_CIRCLE_GUIDE}
          target="_blank"
          rel="noopener"
        >
          {t('description.link')}
        </Box>
        {t('description.afterLink')}
      </Typography>
      <Box>
        <Typography component="p">{t('blockchain')}</Typography>
        <Typography color="text.secondary" component="p">Algorand</Typography>
      </Box>
      <Box>
        <Typography component="p">{t('address')}</Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography color="text.secondary" component="p" style={{ overflowWrap: 'anywhere' }}>{address}</Typography>
          <CopyButton value={address} />
        </Stack>
      </Box>
    </Stack>
  )
}

export default CircleInstructions
