import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { FC, ReactNode } from 'react'

import theme from './defaultTheme'

interface ThemeProviderProps {
  children: ReactNode
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider
