"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CircleTransferStatusToTransactionStatus = exports.CircleTransferStatus = void 0;
var transactions_1 = require("../../transactions");
var CircleTransferStatus;
(function (CircleTransferStatus) {
    CircleTransferStatus["Created"] = "created";
    CircleTransferStatus["Failed"] = "failed";
    CircleTransferStatus["Complete"] = "complete";
    CircleTransferStatus["Pending"] = "pending";
})(CircleTransferStatus = exports.CircleTransferStatus || (exports.CircleTransferStatus = {}));
exports.CircleTransferStatusToTransactionStatus = (_a = {},
    _a[CircleTransferStatus.Created] = transactions_1.TransactionStatus.PENDING,
    _a[CircleTransferStatus.Pending] = transactions_1.TransactionStatus.PENDING,
    _a[CircleTransferStatus.Failed] = transactions_1.TransactionStatus.FAILED,
    _a[CircleTransferStatus.Complete] = transactions_1.TransactionStatus.COMPLETED,
    _a);
