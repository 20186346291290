import { FC, useCallback, useMemo, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import loadable from '@loadable/component'
import { AdminRoles } from '@keo/shared-types'
import { deleteApiKey, editApiKey, getApiKeys } from '@/api/settings'
import { ApiKey, EditApiKeyParams } from '@/types'
import { AddButton, DataGrid, RowActions, SettingsHeader } from '@/components'
import { useRoleProtection } from '@/utils/routes'
import ScopeChip from './ScopeChip'
const Confirm = loadable(() => import('@/components/modals/Confirm'))
const AddApiKey = loadable(() => import('@/components/modals/AddApiKey'))
const EditApiKey = loadable(() => import('@/components/modals/EditApiKey'))

const ApiKeysTable: FC = () => {
  useRoleProtection([ AdminRoles.PROCESSOR_ADMIN ])
  const { t } = useTranslation([ 'settings', 'common' ])
  const { enqueueSnackbar } = useSnackbar()
  const { data, isLoading, refetch } = useQuery('get-api-keys', getApiKeys)
  const [ isAddingApiKey, setIsAddingApiKey ] = useState(false)
  const [ actionError, setActionError ] = useState(false)
  const [ apiKeyToEdit, setApiKeyToEdit ] = useState<ApiKey | null>(null)
  const [ apiKeyToRemove, setApiKeyToRemove ] = useState<string | null>(null)

  const { mutate: deleteApiKeyMutation } = useMutation(deleteApiKey, {
    onSuccess: () => {
      refetch()
      setApiKeyToRemove(null)
    },
    onError: () => {
      setActionError(true)
    }
  })

  const { mutate: editApiKeyMutation } = useMutation(editApiKey, {
    onSuccess: () => {
      refetch()
      setApiKeyToEdit(null)
    },
    onError: () => {
      setActionError(true)
    }
  })

  const onEditApiKey = useCallback(async (values: EditApiKeyParams): Promise<void> => {
    setActionError(false)
    if (apiKeyToEdit) {
      await editApiKeyMutation({ id: apiKeyToEdit.id, params: values })
      enqueueSnackbar(t('modal.edit.success', { apiKey: apiKeyToEdit.id }), { variant: 'success' })
    }
  }, [ apiKeyToEdit?.id ])

  const onEditApiKeyCancel = useCallback(() => {
    setActionError(false)
    setApiKeyToEdit(null)
  }, [])

  const onRemoveApiKey = useCallback(async () => {
    setActionError(false)
    if (apiKeyToRemove) {
      await deleteApiKeyMutation(apiKeyToRemove)
      enqueueSnackbar(t('modal.remove.success', { apiKey: apiKeyToRemove }), { variant: 'success' })
    }
  }, [ apiKeyToRemove ])

  const onRemoveApiKeyCancel = useCallback(() => {
    setActionError(false)
    setApiKeyToRemove(null)
  }, [])

  const columns: GridColDef<ApiKey>[] = useMemo(() => [
    {
      field: 'id',
      headerName: t('id', { ns: 'common' }),
      minWidth: 200
    },
    {
      field: 'label',
      headerName: t('label', { ns: 'common' }),
      flex: 1
    },
    {
      field: 'scope',
      headerName: t('scope', { ns: 'common' }),
      flex: 1,
      renderCell: ({ row }) => <ScopeChip scope={row.scope}/>
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: ({ row }) => (
        <RowActions onEditClick={() => setApiKeyToEdit(row)} onDeleteClick={() => setApiKeyToRemove(row.id)}/>
      )
    }
  ], [])

  const handleCloseAddApiKey = useCallback(() => {
    setIsAddingApiKey(false)
  }, [])

  const onSubmitAddApiKey = useCallback(() => {
    refetch()
    handleCloseAddApiKey()
  }, [])

  return (
    <>
      <SettingsHeader
        description={{
          details: t('apiKeys.description.details'),
          reason: t('apiKeys.description.reason'),
          impact: t('apiKeys.description.impact'),
        }}
        meta={<AddButton
          size="small"
          onClick={() => setIsAddingApiKey(true)}
          label={t('createApiKey')}
        />}
        title={t('tabs.apiKeys')}
      />
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={data || []}
        rowCount={data?.length || 0}
        hideFooterPagination
      />
      {apiKeyToRemove && (
        <Confirm
          isErrorAction
          title={t('modal.remove.title')}
          error={actionError}
          errorText={t('modal.remove.error')}
          onClose={onRemoveApiKeyCancel}
          onCloseLabel={t('cancel', { ns: 'common' })}
          onSubmit={onRemoveApiKey}
          onSubmitLabel={t('revoke', { ns: 'common' })}
          confirmText={t('modal.remove.message')}
        />
      )}
      {apiKeyToEdit && (
        <EditApiKey
          error={actionError}
          initialValues={apiKeyToEdit}
          onClose={onEditApiKeyCancel}
          onSubmit={onEditApiKey}
        />
      )}
      { isAddingApiKey && <AddApiKey onClose={handleCloseAddApiKey} onSubmit={onSubmitAddApiKey} />}
    </>
  )
}

export default ApiKeysTable
