import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { Check, Clear, ExpandMore } from '@mui/icons-material'
import { GlobalLoader, ManageLendingPool } from '@/components'
import DetailsItem from '@/components/Details/DetailsItem'
import { parsePercentage } from '@/utils/l10n'
import { getLendingPoolState } from '@/api/settings'
import { useConfig } from '@/utils/appContext'
import { getAccountLink, getApplicationLink, getAssetLink } from '@/utils/routes'

const LendingPoolDetails: FC = () => {
  const { t } = useTranslation([ 'settings', 'common' ])
  const { data, isLoading, refetch } = useQuery('lendingPoolManagement', getLendingPoolState)
  const config = useConfig()
  const [ isManageModalOpen, setIsManageModalOpen ] = useState(false)

  const handleCloseManageModal = useCallback(() => setIsManageModalOpen(false), [])

  if (isLoading) {
    return <GlobalLoader/>
  }

  if (!data) {
    return null
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls={`contract-${data.applicationId}-content`}
        id={`contract-${data.applicationId}-header`}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title={t(`system.contractsManagement.tooltip.${data.isEnabled ? 'enabled' : 'disabled'}`)}>
              {data.isEnabled ? <Check color="success"/> : <Clear color="error"/>}
            </Tooltip>
          </Grid>
          <Grid item>
            <Typography variant="h6">{t('system.contractsManagement.title.lendingPool')}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
      <Stack gap={2}>
          <DetailsItem label={t('system.contractsManagement.isEnabled')} value={t(data.isEnabled ? 'yes' : 'no', { ns: 'common' })}/>
          <DetailsItem
            canCopy
            opensInNewTab
            variant="link"
            href={getApplicationLink(data.applicationId)}
            label={t('system.contractsManagement.applicationId')}
            value={data.applicationId}
          />
          <DetailsItem
            canCopy
            opensInNewTab
            variant="link"
            href={getAccountLink(data.lender)}
            label={t('system.contractsManagement.lender')}
            value={data.lender}
          />
          <DetailsItem
            canCopy
            opensInNewTab
            variant="link"
            href={getAccountLink(data.manager)}
            label={t('system.contractsManagement.manager')}
            value={data.manager}
          />
          <DetailsItem
            canCopy
            opensInNewTab
            variant="link"
            href={getAccountLink(data.feeSink)}
            label={t('system.contractsManagement.feeSink')}
            value={data.feeSink}
          />
          <DetailsItem
            canCopy
            opensInNewTab
            variant="link"
            href={getApplicationLink(data.loanContractAppId)}
            label={t('system.contractsManagement.loanContractAppId')}
            value={data.loanContractAppId}
          />
          <DetailsItem
            canCopy
            opensInNewTab
            variant="link"
            href={getApplicationLink(data.whitelistAppId)}
            label={t('system.contractsManagement.whitelistAppId')}
            value={data.whitelistAppId}
          />
          <DetailsItem
            canCopy
            opensInNewTab
            variant="link"
            href={getAssetLink(config.currencyAssetId)}
            label={t('system.contractsManagement.currencyAssetId')}
            value={config.currencyAssetId}
          />
          <DetailsItem label={t('system.contractsManagement.currencyAssetName')} value={config.appStablecoin}/>
          <DetailsItem label={t('system.contractsManagement.payFee')} value={parsePercentage(data.payFee, true)}/>
          <DetailsItem label={t('system.contractsManagement.paybackFee')} value={parsePercentage(data.paybackFee, true)}/>
          <Box alignSelf="flex-end">
            <Button size="small" onClick={() => setIsManageModalOpen(true)}>{t('system.contractsManagement.manage')}</Button>
          </Box>
      </Stack>
      {isManageModalOpen && (
        <ManageLendingPool onClose={handleCloseManageModal} onSuccess={refetch} initialValues={data}/>
      )}
      </AccordionDetails>
    </Accordion>
  )
}

export default LendingPoolDetails
