import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CardContent, Grid, Typography } from '@mui/material'
import WidgetContent from './components/WidgetContent'

type WidgetProps = {
  children: ReactNode
  condensed?: boolean
  hasData?: boolean
  height?: number
  isLoading?: boolean
  meta?: ReactNode
  title?: string
}

const Widget: FC<WidgetProps> = ({
  children,
  condensed,
  hasData = true,
  height,
  isLoading = false,
  meta,
  title,
}) => {
  const pad = condensed ? [ 3, 2 ] : [ 4, 3 ]
  return (
    <Box bgcolor="background.default" borderRadius={4} height="100%">
      <CardContent sx={{ py: pad[ 0 ], px: pad[ 1 ], height: '100%' }}>
        <Grid
          container
          spacing={2}
          direction="column"
          height="100%"
          alignItems="stretch"
          flexWrap="nowrap">
          {/* Header */}
          {(!!title || !!meta) && (
            <Grid item>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2">{title}</Typography>
                </Grid>
                {/* Meta */}
                {meta && <Grid item>{meta}</Grid>}
              </Grid>
            </Grid>
          )}
          {/* Content */}
          <Grid
            item
            sx={{
              // These styles are needed to stretch not found container
              display: 'flex',
              height: height || '100%',
              alignItems: height || hasData ? 'flex-start' : 'center',
              justifyContent: height || hasData ? 'stretch' : 'center',
            }}>
            <WidgetContent hasData={hasData} height={height} isLoading={isLoading}>
              {children}
            </WidgetContent>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  )
}

export default Widget
