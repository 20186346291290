import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { GridColDef } from '@mui/x-data-grid'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { Direction, ITransaction, TransactionEvent, TriggerType } from '@keo/shared-types'
import loadable from '@loadable/component'
import { OnFilterTransactionsParams } from '@/types/forms'
import { CSVExporter, CurrencyCell, DataGrid, DateCell, Toolbar } from '@/components'
import { useDataGridOptions } from '@/components/tables/hooks/useDataGridOptions'
import { Entity } from '@/components/modals/CSVExporter'
import useExport from '@/components/hooks/useExport'
import { enumOrEmpty, rangeDateOrEmpty, rangePositiveNumberOrEmpty } from '@/utils/schemas'
import { yupLocale } from '@/utils/yupLocale'
import { getTransactionSystemPeer } from '@/utils/transactions'
import { stringifySortItem } from '@/utils/parsers'
import { useTransactions } from '@/api/hooks'
import TransactionsFilters from './components/TransactionsFilter'
import TransactionStatusIcon from './components/TransactionsStatusIcon'
import { getDefaultCreatedAtSorting } from '@/utils/sort'

const TransactionDetails = loadable(() => import('./components/TransactionDetails'))

yup.setLocale(yupLocale)

type TransactionsTableProps = {
  accountId?: string
  persistFilters?: boolean
  walletAddress?: string
  shouldExportWithFilters?: boolean
}

const FilterFormSchema = yup.object({
  amountFrom: rangePositiveNumberOrEmpty({ lessThanRef: yup.ref('amountTo') }),
  amountTo: rangePositiveNumberOrEmpty({ moreThanRef: yup.ref('amountFrom') }),
  createdDateFrom: rangeDateOrEmpty({ lessThanRef: yup.ref('createdDateTo') }),
  createdDateTo: rangeDateOrEmpty({ moreThanRef: yup.ref('createdDateFrom') }),
  direction: enumOrEmpty(Direction),
  transactionId: yup.string().trim().notRequired(),
  type: enumOrEmpty(TransactionEvent),
})

const FilterFormDefaultValues: OnFilterTransactionsParams = {
  amountFrom: '',
  amountTo: '',
  createdDateFrom: '',
  createdDateTo: '',
  direction: '',
  transactionId: '',
  type: '',
}

const TransactionsTable: FC<TransactionsTableProps> = ({ accountId, persistFilters, walletAddress, shouldExportWithFilters = false }) => {
  const { t } = useTranslation([ 'common', 'transactions' ])
  const [ showTransactionDetails, setShowTransactionDetails ] = useState<ITransaction | null>(null)
  const {
    filters,
    page,
    perPage,
    setFilters,
    setPage,
    setPerPage,
    sorting,
    handleSort,
  } = useDataGridOptions({
    initialFilterValues: FilterFormDefaultValues,
    shouldUpdateUrl: !!persistFilters,
    initialSort: getDefaultCreatedAtSorting()
  })

  const {
    isExporting,
    handleExport,
    handleCancelExport
  } = useExport()

  const { data, isLoading } = useTransactions({
    page,
    perPage,
    filters: {
      ...filters,
      accountId,
      walletAddress,
    },
    sorting: stringifySortItem(sorting),
  })

  const methods = useForm<OnFilterTransactionsParams>({
    resolver: yupResolver(FilterFormSchema, {}, { rawValues: true }),
    defaultValues: FilterFormDefaultValues,
    mode: 'all',
  })

  const onReset = useCallback(() => {
    methods.reset(FilterFormDefaultValues)
    setFilters(FilterFormDefaultValues)
  }, [])

  const columns: GridColDef<ITransaction>[] = useMemo(() => [
    {
      sortable: false,
      field: 'type',
      headerName: t('type'),
      flex: 1.5,
      minWidth: 40,
      renderCell: ({ row }) => t(`transactions:type.${row.triggerType === TriggerType.AUTOMATIC ? 'automatic' : ''}${row.type}`)
    },
    {
      field: 'from',
      headerName: t('from'),
      flex: 1,
      minWidth: 40,
      valueFormatter: ({ value }) => getTransactionSystemPeer(value, t),
    },
    {
      field: 'to',
      headerName: t('to'),
      flex: 1,
      minWidth: 40,
      valueFormatter: ({ value }) => getTransactionSystemPeer(value, t),
    },
    {
      field: 'txId',
      headerName: t('txId', { ns: 'transactions' }),
      flex: 5,
      minWidth: 40,
    },
    {
      field: 'createdAt',
      headerName: t('created'),
      minWidth: 90,
      flex: 0.5,
      renderCell: ({ row }) => <DateCell date={row.createdAt}/>,
    },
    {
      field: 'amount',
      headerName: t('amount'),
      minWidth: 120,
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }) => <CurrencyCell colorize value={row.direction === Direction.OUTGOING ? -row.amount : row.amount} />,
    },
    {
      sortable: false,
      field: 'status',
      headerName: '',
      width: 40,
      renderCell: ({ row }) => <TransactionStatusIcon status={row.status}  />,
    },
    {
      sortable: false,
      field: 'details',
      headerName: '',
      minWidth: 120,
      flex: 1,
      align: 'right',
      renderCell: () => <Button variant="text" size="small" endIcon={<ChevronRight fontSize="small" />}>{t('details')}</Button>,
    }
  ], [])

  const handleFilter = useCallback((params: OnFilterTransactionsParams) => {
    setPage(1)
    /**
     * We need to send always a new object to setFilters
     * React hook form mutates internally its fields state object and sends that reference as a param instead of aa new cloned object
     * */
    setFilters({ ...params })
  }, [])

  useEffect(() => {
    methods.reset(filters)
  }, [ filters, methods ])

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFilter)}>
              <Toolbar
                currentFilters={filters}
                onReset={onReset}
                searchField="transactionId"
                searchPlaceholder={t('transactions:toolbar.searchTermPlaceholder')}
                onExport={handleExport}
              >
                <TransactionsFilters/>
              </Toolbar>
            </form>
          </FormProvider>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            columns={columns}
            rows={data?.results || []}
            rowCount={data?.total || 0}
            page={page - 1}
            pageSize={perPage}
            onPageChange={(currentPage) => setPage(currentPage + 1)}
            onPageSizeChange={(newPerPage) => setPerPage(newPerPage)}
            onRowClick={({ row }) => setShowTransactionDetails(row)}
            sorting={sorting}
            handleSort={handleSort}
          />
        </Grid>
        {
          showTransactionDetails &&
            <TransactionDetails transaction={showTransactionDetails} onClose={() => setShowTransactionDetails(null)}/>
        }
      </Grid>
      {isExporting && (
        <CSVExporter
          onClose={handleCancelExport}
          entity={Entity.TRANSACTIONS}
          {...(shouldExportWithFilters && {
            query: {
              ...filters,
              accountId,
              walletAddress,
            }
          })}
        />
      )}
    </>
  )
}

export default TransactionsTable
