import React, { FC, useCallback, useMemo } from 'react'
import { Chip, ChipProps, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ApiKeyScope } from '@keo/shared-types'

type ScopeChipProps = {
  scope: ApiKeyScope
}

type ChipColor = Pick<ChipProps, 'color'>['color']
const ScopeChip: FC<ScopeChipProps> = ({ scope }) => {
  const { t } = useTranslation('settings')

  const getChipColor = useCallback((value: ApiKeyScope): ChipColor => {
    switch (value) {
      case ApiKeyScope.READ:
        return 'default'
      case ApiKeyScope.WRITE:
      default:
        return 'secondary'
    }
  }, [])

  const chips = useMemo(() => {
    if (scope === ApiKeyScope.RW) {
      return [
        { label: t('scope.R'), color: getChipColor(ApiKeyScope.READ) },
        { label: t('scope.W'), color: getChipColor(ApiKeyScope.WRITE) }
      ]
    }

    return [ { label: t(`scope.${scope}`), color: getChipColor(scope) } ]
  }, [ scope ])

  return (
    <Grid container spacing={1}>
      {chips.map(chip => (
        <Grid item key={chip.label}>
          <Chip label={chip.label} variant="filled" color={chip.color}/>
        </Grid>
      ))}
    </Grid>
  )
}

export default ScopeChip
