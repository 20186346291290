"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlags = exports.ProcessingFeeTemplates = exports.CallbackEventJobs = exports.TargetSettings = void 0;
var TargetSettings;
(function (TargetSettings) {
    TargetSettings["CREDIT_PARAMS"] = "CREDIT_PARAMS";
    TargetSettings["CALLBACK_URL"] = "CALLBACK_URL";
    TargetSettings["FRAUD_CHECK_URL"] = "FRAUD_CHECK_URL";
    TargetSettings["SUPPLIER_USDC_WARNING_AMOUNT"] = "SUPPLIER_USDC_WARNING_AMOUNT";
    TargetSettings["PAYBACK_STRATEGIES"] = "PAYBACK_STRATEGIES";
    TargetSettings["LOANS_CHECK"] = "LOANS_CHECK";
    TargetSettings["BALANCE_MONITORING_THRESHOLDS"] = "BALANCE_MONITORING_THRESHOLDS";
    TargetSettings["DEPOSIT_PARAMETERS"] = "DEPOSIT_PARAMETERS";
    TargetSettings["PROCESSING_FEE_STRATEGIES"] = "PROCESSING_FEE_STRATEGIES";
    TargetSettings["AUTOMATE_WITHDRAWAL"] = "AUTOMATE_WITHDRAWAL";
    TargetSettings["AUTOMATE_SUPPLIER_INVOICE_APPROVAL"] = "AUTOMATE_SUPPLIER_INVOICE_APPROVAL";
    TargetSettings["FEATURE_FLAGS"] = "FEATURE_FLAGS";
    TargetSettings["STATEMENT_REQUEST_URL"] = "STATEMENT_REQUEST_URL";
})(TargetSettings = exports.TargetSettings || (exports.TargetSettings = {}));
// Callback url
var CallbackEventJobs;
(function (CallbackEventJobs) {
    CallbackEventJobs["ACCOUNT_INIT"] = "ACCOUNT_INIT";
    CallbackEventJobs["INVOICES_CHECK"] = "INVOICES_CHECK";
    CallbackEventJobs["INVOICES_EVENT"] = "INVOICES_EVENT";
    CallbackEventJobs["LOANS_EVENT"] = "LOANS_EVENT";
    CallbackEventJobs["LOANS_CHECK"] = "LOANS_CHECK";
    CallbackEventJobs["ACCOUNT_CREDIT_PARAMS_UPDATE"] = "ACCOUNT_CREDIT_PARAMS_UPDATE";
    CallbackEventJobs["SUPPLIER_VERIFICATION"] = "SUPPLIER_VERIFICATION";
})(CallbackEventJobs = exports.CallbackEventJobs || (exports.CallbackEventJobs = {}));
// Processing fees strategy
var ProcessingFeeTemplates;
(function (ProcessingFeeTemplates) {
    ProcessingFeeTemplates["THRESHOLD"] = "THRESHOLD";
    ProcessingFeeTemplates["FLAT_FEE"] = "FLAT_FEE";
})(ProcessingFeeTemplates = exports.ProcessingFeeTemplates || (exports.ProcessingFeeTemplates = {}));
// Feature flags
var FeatureFlags;
(function (FeatureFlags) {
    FeatureFlags["DEPOSIT"] = "DEPOSIT";
    FeatureFlags["WITHDRAWAL"] = "WITHDRAWAL";
})(FeatureFlags = exports.FeatureFlags || (exports.FeatureFlags = {}));
