import React, { FC, ReactNode } from 'react'
import { Link, Skeleton, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { UnderlinedText } from '@/components'

export type DetailsItemValueProps = {
  value?: string | number | ReactNode
  isLoading?: boolean
  variant?: 'default' | 'link' | 'bold' | 'custom'
  href?: string
  opensInNewTab?: boolean
}

const DetailsItemValue: FC<DetailsItemValueProps> = ({ isLoading, value, variant, href, opensInNewTab = false }) => {
  const valueToRender = isLoading ? <Skeleton/> : (value || '-')

  switch (variant) {
    case 'link':
      return href ?
        <Link
          component={RouterLink}
          to={href}
          sx={{ textDecoration: 'underline', textUnderlinePosition: 'under' }}
          {...(opensInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
        >{valueToRender}</Link> :
        <UnderlinedText>{valueToRender}</UnderlinedText>
    case 'bold':
      return <Typography overflow="hidden" textOverflow="ellipsis" variant="body2">{valueToRender}</Typography>
    case 'custom':
      return <>{value}</>
    default:
      return <Typography overflow="hidden" textOverflow="ellipsis">{valueToRender}</Typography>
  }
}

export default DetailsItemValue
