import { queryParamsToUrl } from '@keo/frontend/utils/url'
import { ApiPagination, ChartData, RangeFilter, TransactionStatus } from '@keo/shared-types'
import {
  GetTransactionsRequestParams,
  GetTransactionsResponse,
  OnFilterTransactionsParams
} from '@/types'
import Api from '@/utils/api'
import { parseDateExcludingTime } from '@/utils/l10n'
import { removeUndefinedAndEmptyValues } from '@/utils/parsers'

const PATH_PREFIX = '/transactions'

type GetTransactionsParams = {
  pagination: ApiPagination
  filters: OnFilterTransactionsParams
  sorting?: string
}

export async function getTransactions(params: GetTransactionsParams) {
  const { pagination, filters, sorting } = params
  const { accountId, amountFrom, amountTo, createdDateFrom, createdDateTo, direction, loanId, status, transactionId, type, walletAddress } = removeUndefinedAndEmptyValues(filters)

  const queryParams: GetTransactionsRequestParams = {
    ...pagination,
    // Spreading the following fields to avoid sending empty strings to the API
    ...accountId && { accountId },
    ...amountFrom && { amountFrom },
    ...amountTo && { amountTo },
    ...createdDateFrom && { createdDateFrom: parseDateExcludingTime(createdDateFrom) },
    ...createdDateTo && { createdDateTo: parseDateExcludingTime(createdDateTo) },
    ...direction && { direction },
    ...loanId && { loanId },
    ...transactionId && { transactionId },
    ...type && { type },
    ...walletAddress && { walletAddress },
    ...status?.length && { status: (status as TransactionStatus[]).join(',') },
    ...sorting && { sorting },
  }

  const url = queryParamsToUrl(`${PATH_PREFIX}`, queryParams)
  const res = await Api.get<GetTransactionsResponse>(url)

  return res.data
}

export async function getTransactionTotalAmount() {
  const url = `${PATH_PREFIX}/totalAmount`
  const res = await Api.get<number>(url)

  return res.data
}

export async function getTransactionTotalFees() {
  const url = `${PATH_PREFIX}/totalFees`
  const res = await Api.get<number>(url)

  return res.data
}

export async function getTransactionHistory(range: RangeFilter) {
  const url = `${PATH_PREFIX}/transactionAmountHistory?range=${range}`
  const res = await Api.get<ChartData>(url)

  return res.data
}

export async function getROIHistory(range: RangeFilter) {
  const url = `${PATH_PREFIX}/ROIHistory?range=${range}`
  const res = await Api.get<ChartData>(url)

  return res.data
}
