"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DelegationRevokedByEnum = exports.TriggerType = exports.DelegationType = void 0;
var DelegationType;
(function (DelegationType) {
    DelegationType["INVOICE_SUPPLIER_APPROVAL"] = "INVOICE_SUPPLIER_APPROVAL";
    DelegationType["SUPPLIER_WITHDRAWAL"] = "SUPPLIER_WITHDRAWAL";
})(DelegationType = exports.DelegationType || (exports.DelegationType = {}));
var TriggerType;
(function (TriggerType) {
    TriggerType["MANUAL"] = "MANUAL";
    TriggerType["AUTOMATIC"] = "AUTOMATIC";
})(TriggerType = exports.TriggerType || (exports.TriggerType = {}));
var DelegationRevokedByEnum;
(function (DelegationRevokedByEnum) {
    DelegationRevokedByEnum["USER"] = "USER";
    DelegationRevokedByEnum["CRON"] = "CRON";
    DelegationRevokedByEnum["ADMIN"] = "ADMIN";
})(DelegationRevokedByEnum = exports.DelegationRevokedByEnum || (exports.DelegationRevokedByEnum = {}));
