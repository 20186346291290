import { useQuery } from 'react-query'
import { OnFilterInvoicesParams } from '@/types'
import { getInvoices } from '@/api/invoices'
import { ApiQueryKeys } from '@/api/hooks/queries'

type UseInvoicesParams = {
  page: number
  perPage: number
  filters: OnFilterInvoicesParams
  sorting?: string
}

export const useInvoices = ({ page, perPage, filters, sorting }: UseInvoicesParams) => {
  return useQuery(
    [ ApiQueryKeys.INVOICES, filters, sorting, perPage, page ],
    () => getInvoices({
      pagination: {
        page,
        perPage
      },
      filters,
      sorting
    }),
    { keepPreviousData: true },
  )
}
