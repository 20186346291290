"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTemplate = void 0;
// Emails
var EmailTemplate;
(function (EmailTemplate) {
    EmailTemplate["WELCOME"] = "WELCOME";
    // ACCOUNT
    EmailTemplate["ACCOUNT_LINKED"] = "ACCOUNT_LINKED";
    EmailTemplate["LINK_ACCOUNT"] = "LINK_ACCOUNT";
    EmailTemplate["VERIFY_ACCOUNT"] = "VERIFY_ACCOUNT";
    EmailTemplate["OTP_TOKEN_ACCOUNT"] = "OTP_TOKEN_ACCOUNT";
    EmailTemplate["SUPPLIER_ACCOUNT_STATEMENT"] = "SUPPLIER_ACCOUNT_STATEMENT";
    // ADMIN
    EmailTemplate["ADMIN_RESET_PASSWORD"] = "ADMIN_RESET_PASSWORD";
    EmailTemplate["ADMIN_SET_PASSWORD"] = "ADMIN_SET_PASSWORD";
    // INVOICES
    EmailTemplate["INVOICE_CREATED_BUYER"] = "INVOICE_CREATED_BUYER";
    EmailTemplate["INVOICE_CREATED_SUPPLIER"] = "INVOICE_CREATED_SUPPLIER";
    EmailTemplate["PENDING_APPROVAL_INVOICE"] = "PENDING_APPROVAL_INVOICE";
    EmailTemplate["INVOICES_LOCKED_REPORT"] = "INVOICES_LOCKED_REPORT";
    EmailTemplate["INVOICE_PROBLEM_REPORTED"] = "INVOICE_PROBLEM_REPORTED";
    // DEPOSITS
    EmailTemplate["DEPOSIT_RECEIVED"] = "DEPOSIT_RECEIVED";
    EmailTemplate["DEPOSIT_PROCESSED"] = "DEPOSIT_PROCESSED";
    EmailTemplate["DEPOSIT_REQUESTED"] = "DEPOSIT_REQUESTED";
    // WITHDRAWALS
    EmailTemplate["WITHDRAWAL_PROCESSED"] = "WITHDRAWAL_PROCESSED";
    EmailTemplate["WITHDRAWAL_COMPLETED"] = "WITHDRAWAL_COMPLETED";
    EmailTemplate["ADMIN_WITHDRAWAL_FAILED"] = "ADMIN_WITHDRAWAL_FAILED";
    // WARNINGS
    EmailTemplate["ADMIN_USDC_WARNING"] = "ADMIN_USDC_WARNING";
    EmailTemplate["SUPPLIER_USDC_WARNING"] = "SUPPLIER_USDC_WARNING";
    // BALANCE MONITORING
    EmailTemplate["BALANCE_MONITORING"] = "BALANCE_MONITORING";
    // LOANS OOS
    EmailTemplate["LOANS_OOS_TOO_LONG"] = "LOANS_OOS_TOO_LONG";
})(EmailTemplate = exports.EmailTemplate || (exports.EmailTemplate = {}));
