import { FC, ReactNode } from 'react'
import { Stack, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { Info } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

type SettingsHeaderProps = {
  description?: {
    details: string
    reason?: string
    impact?: string
  }
  title: string
  meta?: ReactNode
}

const SettingsHeader: FC<SettingsHeaderProps> = ({ description, meta, title }) => {
  const { t } = useTranslation('settings')

  return (
    <Stack direction="row" sx={{ margin: 1, marginBottom: 2 }} justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        <Typography variant="h6">
          {title}
        </Typography>
        {
          description &&
          <Tooltip
            title={(
              <Stack spacing={2} padding={1}>
                <Typography variant="body3">{description.details}</Typography>
                {
                  description.reason &&
                  <Stack>
                    <Typography variant="body4">
                      {t('reason')}
                    </Typography>
                    <Typography variant="body3">
                      {description.reason}
                    </Typography>
                  </Stack>
                }
                {
                  description.impact &&
                  <Stack>
                    <Typography variant="body4">
                      {t('impact')}
                    </Typography>
                    <Typography variant="body3">
                      {description.impact}
                    </Typography>
                  </Stack>
                }
              </Stack>
            )}
            enterDelay={0}
            enterNextDelay={0}
            leaveDelay={0}
            placement="bottom-start"
            sx={{
              [ `& .${tooltipClasses.tooltip}` ]: {
                maxWidth: 500,
              },
            }}
          >
            <Info
              color="disabled"
              fontSize="small"
              sx={{ '&:hover': { color: 'primary.main' } }}
            />
          </Tooltip>
        }
      </Stack>
      {meta && meta}
    </Stack>
  )
}

export default SettingsHeader