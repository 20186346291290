"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceMonitoringCheckType = exports.BalanceMonitoringTargets = void 0;
var BalanceMonitoringTargets;
(function (BalanceMonitoringTargets) {
    BalanceMonitoringTargets["LENDING_POOL_CURRENCY"] = "LENDING_POOL_CURRENCY";
    BalanceMonitoringTargets["OPERATOR_MIN_CURRENT_ALGOS_DIFF"] = "OPERATOR_MIN_CURRENT_ALGOS_DIFF";
})(BalanceMonitoringTargets = exports.BalanceMonitoringTargets || (exports.BalanceMonitoringTargets = {}));
var BalanceMonitoringCheckType;
(function (BalanceMonitoringCheckType) {
    BalanceMonitoringCheckType["SCHEDULED"] = "SCHEDULED";
    BalanceMonitoringCheckType["MANUAL"] = "MANUAL";
})(BalanceMonitoringCheckType = exports.BalanceMonitoringCheckType || (exports.BalanceMonitoringCheckType = {}));
