import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import loadable from '@loadable/component'
import { Loan, LoanPayBackStatus } from '@keo/shared-types'
import { useDataGridOptions } from '@/components/tables/hooks/useDataGridOptions'
import { OnFilterLoansParams } from '@/types'
import { yupLocale } from '@/utils/yupLocale'
import { rangeDateOrEmpty, rangePositiveNumberOrEmpty } from '@/utils/schemas'
import { CSVExporter, CurrencyCell, DataGrid, DateCell, DueDateCell, Toolbar } from '@/components'
import { Filters } from '@/components/LoansTable/components'
import { stringifySortItem } from '@/utils/parsers'
import { useLoans } from '@/api/hooks/loans'
import { getDefaultCreatedAtSorting } from '@/utils/sort'
import LoanStatus from '../LoansTable/components/LoanStatus'
import useExport from '../hooks/useExport'
import { Entity } from '../modals/CSVExporter'
const LoanDetails = loadable(() => import('@/components/modals/LenderLoanDetails'))

/**
 * Need to call this in the same file as the schema is defined
 * Tried to call this in App.tsx, but it didn't work
 */
yup.setLocale(yupLocale)

// Refine the schema once it's defined on the backend
const FilterFormSchema = yup.object({
  searchTerm: yup.string().trim().notRequired(),
  invoiceAmountFrom: rangePositiveNumberOrEmpty({ lessThanRef: yup.ref('invoiceAmountTo') }),
  invoiceAmountTo: rangePositiveNumberOrEmpty({ moreThanRef: yup.ref('invoiceAmountFrom') }),
  createdDateFrom: rangeDateOrEmpty({ lessThanRef: yup.ref('createdDateTo') }),
  createdDateTo: rangeDateOrEmpty({ moreThanRef: yup.ref('createdDateFrom') }),
  dueDateFrom: rangeDateOrEmpty({ lessThanRef: yup.ref('dueDateTo') }),
  dueDateTo: rangeDateOrEmpty({ moreThanRef: yup.ref('dueDateFrom') })
})

const FilterFormDefaultValues: OnFilterLoansParams = {
  searchTerm: '',
  invoiceAmountFrom: '',
  invoiceAmountTo: '',
  createdDateFrom: '',
  createdDateTo: '',
  dueDateFrom: '',
  dueDateTo: '',
  loanPayBackStatuses: [],
}

type LoansTableProps = {
  accountId?: string
}

const LoansTable: FC<LoansTableProps> = ({ accountId }) => {
  const { t } = useTranslation('common')
  const {
    page,
    perPage,
    filters,
    setPage,
    setPerPage,
    setFilters,
    handleSort,
    sorting,
  } = useDataGridOptions({
    initialFilterValues: FilterFormDefaultValues,
    shouldUpdateUrl: true,
    initialSort: getDefaultCreatedAtSorting('loan'),
  })

  const [ loanDetails, setLoanDetails ] = useState<Loan | null>(null)

  const handleCloseLoanDetails = useCallback(() => {
    setLoanDetails(null)
  }, [])

  const {
    isExporting,
    handleExport,
    handleCancelExport
  } = useExport()

  const { data, isLoading } = useLoans({
    pagination: {
      page,
      perPage
    },
    filters: {
      ...filters,
      accountId
    },
    sorting: stringifySortItem(sorting),
  })

  const handleRowClick = useCallback((row: Loan) => {
    setLoanDetails(row)
  }, [])

  const methods = useForm<OnFilterLoansParams>({
    resolver: yupResolver(FilterFormSchema),
    defaultValues: { ...FilterFormDefaultValues, ...filters },
    mode: 'all'
  })

  const onReset = useCallback(() => {
    methods.reset(FilterFormDefaultValues)
    setFilters(FilterFormDefaultValues)
  }, [])

  // Need to specifically set the account.<field> due to ambiguous column names in BE
  const columns: GridColDef<Loan>[] = useMemo(() => ([
    {
      field: 'loan.externalId',
      valueGetter: ({ row }) => row.externalId,
      headerName: t('id'),
      flex: 0.5,
    },
    {
      field: 'loan.createdAt',
      headerName: t('createdDate'),
      flex: 1,
      renderCell: ({ row }) => <DateCell date={row.createdAt}/>
    },
    {
      field: 'loan.dueDate',
      headerName: t('dueDate'),
      flex: 1,
      renderCell: ({ row }) => <DueDateCell
        dueDate={row.dueDate as unknown as string}
        hideDaysPastDueDate={row.payBackStatus === LoanPayBackStatus.PAID}
      />
    },
    {
      field: 'invoice.supplierAccount',
      headerName: t('supplier'),
      flex: 1.5,
      valueGetter: ({ row }) => row.invoice.supplier?.account.name
    },
    {
      field: 'invoice.buyerAccount',
      headerName: t('buyer'),
      flex: 1.5,
      valueGetter: ({ row }) => row.invoice.buyer?.account.name
    },
    {
      field: 'invoice.amount',
      headerName: t('invoiceAmount'),
      align: 'right',
      headerAlign: 'right',
      flex: 0.8,
      renderCell: ({ row }) => {
        return <CurrencyCell value={row.invoice.amount}/>
      }
    },
    {
      field: 'dueAmount',
      headerName: t('dueAmount'),
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      flex: 0.8,
      renderCell: ({ row }) => {
        const total = Number(row.fees) + Number(row.interest) + Number(row.principal)
        return <CurrencyCell value={total}/>
      }
    },
    {
      sortable: false,
      field: 'paybackStatus',
      headerName: '',
      align: 'right',
      headerAlign: 'right',
      width: 200,
      renderCell: ({ row }) => {
        if (row.payBackStatus === LoanPayBackStatus.PAID) return null
        return <LoanStatus status={row.payBackStatus}/>
      }
    }
  ]), [])

  const handleFilter = (params: OnFilterLoansParams) => {
    setPage(1)
    /**
     * We need to send always a new object to setFilters
     * React hook form mutates internally its fields state object and sends that reference as a param instead of aa new cloned object
     * */
    setFilters({ ...params })
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFilter)}>
              <Toolbar
                currentFilters={filters}
                hideSearch
                onExport={handleExport}
                onReset={onReset}
                searchPlaceholder={t('searchBySupplierName')}
                searchField="searchTerm"
              >
                <Filters/>
              </Toolbar>
            </form>
          </FormProvider>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            columns={columns}
            rows={data?.results || []}
            rowCount={data?.total || 0}
            page={page - 1}
            pageSize={perPage}
            onRowClick={({ row }) => handleRowClick(row as Loan)}
            onPageChange={(currentPage) => setPage(currentPage + 1)}
            onPageSizeChange={(newPerPage) => setPerPage(newPerPage)}
            sorting={sorting}
            handleSort={handleSort}
          />
        </Grid>
      </Grid>
      {!!loanDetails && (
        <LoanDetails loan={loanDetails} onClose={handleCloseLoanDetails}/>
      )}
      {isExporting && (
        <CSVExporter
          onClose={handleCancelExport}
          entity={Entity.LOANS}
        />
      )}
    </>
  )
}

export default LoansTable
