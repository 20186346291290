import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Search } from '@mui/icons-material'
import { Box, Grid, Typography, useTheme } from '@mui/material'

type NoResultsProps = {
  showIcon?: boolean
}

const NoResults: FC<NoResultsProps> = ({ showIcon }) => {
  const { t } = useTranslation('common')
  const theme = useTheme()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}>
      <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
        {
          showIcon && (
            <Grid item>
              <Search sx={{ height: theme.spacing(3), width: theme.spacing(3) }}/>
            </Grid>
          )
        }
        <Grid item>
          <Typography variant="body4">{t('noResults')}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NoResults
