import React, { FC } from 'react'
import { Add } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'

type AddButtonProps = ButtonProps & {
  label: string
  onClick: () => void
}

const AddButton: FC<AddButtonProps> = ({ label, onClick , ...rest }) => {
  return (
    <Button
      variant="contained"
      size="small"
      startIcon={<Add/>}
      onClick={onClick}
      {...rest}
    >
      {label}
    </Button>
  )
}

export default AddButton
