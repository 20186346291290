"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJwt = exports.useSession = exports.SessionProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_query_1 = require("react-query");
var SessionCtx = (0, react_1.createContext)(null);
var SessionProvider = function (_a) {
    var children = _a.children, fetchSession = _a.fetchSession, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var _c = (0, react_1.useState)(), session = _c[0], setSession = _c[1];
    var isLoading = (0, react_query_1.useQuery)('getSession', fetchSession, {
        onError: function () { return setSession(undefined); },
        onSuccess: function (data) {
            try {
                var parsedData = parseJwt(data);
                setSession(parsedData);
            }
            catch (err) {
                console.error('Error parsing JWT');
            }
        },
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: !disabled,
    }).isLoading;
    var deleteSession = (0, react_1.useCallback)(function () {
        setSession(undefined);
    }, []);
    (0, react_1.useEffect)(function () {
        window.addEventListener('session:remove', deleteSession);
        return function () {
            window.removeEventListener('session:remove', deleteSession);
        };
    }, []);
    var sessionDetails = {
        invalidateSession: deleteSession,
        isLoading: isLoading,
        session: session,
        setSession: setSession,
    };
    var memoizedChildren = (0, react_1.useMemo)(function () { return children; }, [children]);
    return ((0, jsx_runtime_1.jsx)(SessionCtx.Provider, __assign({ value: sessionDetails }, { children: memoizedChildren })));
};
exports.SessionProvider = SessionProvider;
function useSession() {
    return (0, react_1.useContext)(SessionCtx);
}
exports.useSession = useSession;
function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}
exports.parseJwt = parseJwt;
