import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Wallet } from '@keo/shared-types'
import { updateCreditParams } from '@/api/accounts'
import { banWallet, reactivateWallet, suspendWallet } from '@/api/wallets'
import { WalletModalAction } from '@/types'

enum WalletAction {
  BAN = 'BAN',
  SUSPEND = 'SUSPEND',
  REACTIVATE = 'REACTIVATE',
  UPDATE_CREDIT = 'UPDATE_CREDIT',
}

type UseWalletActionsParams = {
  wallet: Wallet
  onActionFinish: () => void
}

type UseWalletActionsReturn = {
  errorText: string | null
  isBanDisabled: boolean
  isBanLoading: boolean
  isReactivateLoading: boolean
  isSuspendDisabled: boolean
  isSuspendLoading: boolean
  isUpdateCreditLineDisabled: boolean
  isUpdateCreditLineLoading: boolean
  modalAction: WalletModalAction | null
  onBan: () => void
  onSuspend: (newStatus: boolean) => void
  onUpdateCredit: (creditLine: number) => void
  setModalAction: (action: WalletModalAction | null) => void
}

function getSuccessMessage(walletAction: WalletAction): string {
  const msgs = {
    [ WalletAction.BAN ]: 'modal.confirm.success_ban',
    [ WalletAction.REACTIVATE ]: 'modal.confirm.success_unsuspend',
    [ WalletAction.SUSPEND ]: 'modal.confirm.success_suspend',
    [ WalletAction.UPDATE_CREDIT ]: 'modal.updateCreditLine.success',
  }
  return msgs[ walletAction ]
}

export const useWalletActions = ({
  wallet,
  onActionFinish,
}: UseWalletActionsParams): UseWalletActionsReturn => {
  const { t } = useTranslation([ 'common', 'wallets' ])
  const { enqueueSnackbar } = useSnackbar()
  const [ modalAction, setModalAction ] = useState<WalletModalAction | null>(null)
  const [ errorType, setErrorType ] = useState<WalletAction | null>(null)

  const closeActionModal = useCallback(() => setModalAction(null), [])

  const getMutationConfig = useCallback(
    (actionType: WalletAction) => ({
      onMutate: () => {
        setErrorType(null)
        closeActionModal()
      },
      onSuccess: () => {
        enqueueSnackbar(t(getSuccessMessage(actionType), { ns: 'wallets' }), {
          variant: 'success',
        })
        onActionFinish()
      },
      onError: () => setErrorType(actionType),
    }),
    [ onActionFinish ],
  )

  const { mutate: banWalletMutation, isLoading: isBanLoading } = useMutation(
    banWallet,
    getMutationConfig(WalletAction.BAN),
  )

  const { mutate: suspendWalletMutation, isLoading: isSuspendLoading } =
    useMutation(suspendWallet, getMutationConfig(WalletAction.SUSPEND))

  const { mutate: reactivateWalletMutation, isLoading: isReactivateLoading } =
    useMutation(reactivateWallet, getMutationConfig(WalletAction.REACTIVATE))

  const { mutate: updateCreditMutation, isLoading: isUpdateCreditLineLoading } =
    useMutation(
      (creditLine: number) =>
        updateCreditParams({
          id: wallet.account!.id,
          creditLineAmount: creditLine,
          rating: wallet.account!.rating,
        }),
      getMutationConfig(WalletAction.UPDATE_CREDIT),
    )

  const isBanDisabled = useMemo(
    () => isSuspendLoading || isReactivateLoading,
    [ isSuspendLoading, isReactivateLoading ],
  )
  const isSuspendDisabled = useMemo(() => isBanLoading, [ isBanLoading ])
  const isUpdateCreditLineDisabled = useMemo(
    () =>
      isBanLoading ||
      isSuspendLoading ||
      isReactivateLoading ||
      isUpdateCreditLineLoading,
    [ isBanLoading, isSuspendLoading, isReactivateLoading ],
  )

  const errorText = useMemo(() => {
    if (!errorType) {
      return null
    }

    switch (errorType) {
      case WalletAction.BAN:
        return t('errors.banWallet', { ns: 'wallets' })
      case WalletAction.SUSPEND:
        return t('errors.suspendWallet', { ns: 'wallets' })
      case WalletAction.REACTIVATE:
        return t('errors.reactivateWallet', { ns: 'wallets' })
      case WalletAction.UPDATE_CREDIT:
        return t('errors.updateCreditLine', { ns: 'wallets' })
      default:
        return null
    }
  }, [ errorType ])

  const onBan = useCallback(() => {
    banWalletMutation(wallet.id)
  }, [ wallet.id ])

  const onSuspend = useCallback(
    (newStatus: boolean) => {
      if (newStatus) {
        suspendWalletMutation(wallet.id)
      } else {
        reactivateWalletMutation(wallet.id)
      }
    },
    [ wallet.id ],
  )

  const onUpdateCredit = useCallback(
    (creditLine: number) => {
      updateCreditMutation(creditLine)
    },
    [ wallet.id ],
  )

  return {
    errorText,
    isBanDisabled,
    isBanLoading,
    isReactivateLoading,
    isSuspendDisabled,
    isSuspendLoading,
    isUpdateCreditLineDisabled,
    isUpdateCreditLineLoading,
    modalAction,
    onBan,
    onSuspend,
    onUpdateCredit,
    setModalAction,
  }
}
