import React, { FC } from 'react'
import { AccessTimeFilled, Cancel, CheckCircle, Lock } from '@mui/icons-material'
import { InvoicesApprovalStatus } from '@keo/shared-types'

type InvoiceStatusIconProps = {
  status: InvoicesApprovalStatus
}

const InvoiceStatusIcon: FC<InvoiceStatusIconProps> = ({ status }) => {
  return {
    [ InvoicesApprovalStatus.CREATED ]: <AccessTimeFilled color="info" fontSize="small" />,
    [ InvoicesApprovalStatus.LOCKED ]: <Lock color="warning" fontSize="small" />,
    [ InvoicesApprovalStatus.REJECTED ]: <Cancel color="error" fontSize="small" />,
    [ InvoicesApprovalStatus.PENDING ]: <AccessTimeFilled color="info" fontSize="small" />,
    [ InvoicesApprovalStatus.APPROVED ]: <CheckCircle color="success" fontSize="small" />,
    [ InvoicesApprovalStatus.DENIED ]: <Cancel color="error" fontSize="small" />,
  }[ status ]
}

export default InvoiceStatusIcon
