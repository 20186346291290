import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Tooltip } from '@mui/material'
import { Lock } from '@mui/icons-material'
import { GridColDef } from '@mui/x-data-grid'
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import loadable from '@loadable/component'
import { Loan, LoanPayBackStatus } from '@keo/shared-types'
import { useDataGridOptions } from '@/components/tables/hooks/useDataGridOptions'
import { OnFilterLoansParams } from '@/types'
import { yupLocale } from '@/utils/yupLocale'
import { rangeDateOrEmpty, rangePositiveNumberOrEmpty } from '@/utils/schemas'
import { CurrencyCell, DataGrid, DueDateCell, Toolbar } from '@/components'
import { useLocale } from '@/utils/l10n'
import { Filters } from './components/'
import LoanStatus from '@/components/LoansTable/components/LoanStatus'
import { useLoans } from '@/api/hooks'
import { stringifySortItem } from '@/utils/parsers'
const LoanDetails = loadable(() => import('@/components/modals/LoanDetails'))

/**
 * Need to call this in the same file as the schema is defined
 * Tried to call this in App.tsx, but it didn't work
 */
yup.setLocale(yupLocale)

// Refine the schema once it's defined on the backend
const FilterFormSchema = yup.object({
  searchTerm: yup.string().trim().notRequired(),
  invoiceAmountFrom: rangePositiveNumberOrEmpty({ lessThanRef: yup.ref('invoiceAmountTo') }),
  invoiceAmountTo: rangePositiveNumberOrEmpty({ moreThanRef: yup.ref('invoiceAmountFrom') }),
  createdDateFrom: rangeDateOrEmpty({ lessThanRef: yup.ref('createdDateTo') }),
  createdDateTo: rangeDateOrEmpty({ moreThanRef: yup.ref('createdDateFrom') }),
  dueDateFrom: rangeDateOrEmpty({ lessThanRef: yup.ref('dueDateTo') }),
  dueDateTo: rangeDateOrEmpty({ moreThanRef: yup.ref('dueDateFrom') })
})

const FilterFormDefaultValues: OnFilterLoansParams = {
  searchTerm: '',
  invoiceAmountFrom: '',
  invoiceAmountTo: '',
  createdDateFrom: '',
  createdDateTo: '',
  dueDateFrom: '',
  dueDateTo: ''
}

type LoansTableProps = {
  accountId?: string
}

const LoansTable: FC<LoansTableProps> = ({ accountId }) => {
  const { t } = useTranslation('common')
  const { parseDate } = useLocale()
  const {
    page,
    perPage,
    filters,
    setPage,
    setPerPage,
    setFilters,
    sorting,
    handleSort,
  } = useDataGridOptions({
    initialFilterValues: FilterFormDefaultValues,
    shouldUpdateUrl: false, // this should be true only on lists page (not on details page)
    initialSort: { field: 'loan.createdAt', sort: 'desc' },
  })

  const [ loanDetails, setLoanDetails ] = useState<Loan | null>(null)

  const handleCloseLoanDetails = useCallback(() => {
    setLoanDetails(null)
  }, [])

  const { data, isLoading } = useLoans({
    pagination: { page, perPage },
    filters: { ...filters, accountId },
    sorting: stringifySortItem(sorting)
  })

  const handleRowClick = useCallback((row: Loan) => {
    setLoanDetails(row)
  }, [])

  const methods = useForm<OnFilterLoansParams>({
    resolver: yupResolver(FilterFormSchema),
    defaultValues: { ...FilterFormDefaultValues, ...filters },
    mode: 'all'
  })

  const onReset = useCallback(() => {
    methods.reset(FilterFormDefaultValues)
    setFilters(FilterFormDefaultValues)
  }, [])

  // TODO: add truncation feature to some columns if needed
  // Need to specifically set the account.<field> due to ambiguous column names in BE
  const columns: GridColDef<Loan>[] = useMemo(() => ([
    {
      field: 'loan.externalId',
      headerName: t('id'),
      flex: 1,
      valueGetter: ({ row }) => row.externalId
    },
    {
      field: 'loan.createdAt',
      headerName: t('createdDate'),
      flex: 1,
      valueGetter: ({ row }) => parseDate(row.createdAt)
    },
    {
      field: 'loan.dueDate',
      headerName: t('dueDate'),
      flex: 1,
      renderCell: ({ row }) => {
        if (!row.dueDate) return '-'
        return (
          <DueDateCell
            dueDate={row.dueDate}
            hideDaysPastDueDate={row.payBackStatus === LoanPayBackStatus.PAID}
          />
        )
      }
    },
    {
      field: 'invoice.supplierAccount',
      headerName: t('supplier'),
      flex: 1.5,
      valueGetter: ({ row }) => row.invoice.supplier?.account.name
    },
    {
      field: 'invoice.amount',
      headerName: t('invoiceAmount'),
      flex: 0.8,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row }) => {
        return <CurrencyCell value={row.invoice.amount}/>
      }
    },
    {
      sortable: false,
      field: 'dueAmount',
      headerName: t('dueAmount'),
      flex: 0.8,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row }) => {
        // Only show due amount if the loan is active or paid
        const shouldShowDueAmount = row.payBackStatus === LoanPayBackStatus.PAID || row.payBackStatus === LoanPayBackStatus.ACTIVE
        if(!shouldShowDueAmount) {
          return '-'
        }

        const total =  Number(row.fees) + Number(row.interest) + Number(row.principal)
        return <CurrencyCell value={total}/>
      }
    },
    {
      sortable: false,
      field: 'paybackStatus',
      headerName: t('status'),
      headerAlign: 'right',
      minWidth: 200,
      flex: 0.5,
      align: 'right',
      renderCell: ({ row }) => <LoanStatus status={row.payBackStatus} />,
    },
    {
      sortable: false,
      field: 'isLocked',
      headerName: '',
      flex: 0.5,
      renderCell: ({ row }) => row.isLocked && !(row.payBackStatus === LoanPayBackStatus.PAID) && (
        <Tooltip title={t('loanLocked')}><Lock color="action" fontSize="small" /></Tooltip>
      ),
    },
  ]), [])

  const handleFilter = (params: OnFilterLoansParams) => {
    setPage(1)
    /**
     * We need to send always a new object to setFilters
     * React hook form mutates internally its fields state object and sends that reference as a param instead of aa new cloned object
     * */
    setFilters({ ...params })
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFilter)}>
              <Toolbar
                currentFilters={filters}
                hideSearch
                onReset={onReset}
                searchPlaceholder={t('searchBySupplierName')}
                searchField="searchTerm"
              >
                <Filters/>
              </Toolbar>
            </form>
          </FormProvider>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            columns={columns}
            rows={data?.results || []}
            rowCount={data?.total || 0}
            page={page - 1}
            pageSize={perPage}
            onRowClick={({ row }) => handleRowClick(row as Loan)}
            onPageChange={(currentPage) => setPage(currentPage + 1)}
            onPageSizeChange={(newPerPage) => setPerPage(newPerPage)}
            sorting={sorting}
            handleSort={handleSort}
          />
        </Grid>
      </Grid>
      {!!loanDetails && (
        <LoanDetails accountId={accountId} loan={loanDetails} onClose={handleCloseLoanDetails}/>
      )}
    </>
  )
}

export default LoansTable
