import { FC } from 'react'
import { Box, CircularProgress, useTheme } from '@mui/material'

const GlobalLoader: FC = () => {
  const theme = useTheme()
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      height: '100%',
      padding: theme.spacing(3),
    }}>
      <CircularProgress color="primary"/>
    </Box>
  )
}

export default GlobalLoader
