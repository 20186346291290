"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CirclePayoutStatusToTransactionStatus = exports.CirclePayoutStatus = void 0;
var transactions_1 = require("../../transactions");
var CirclePayoutStatus;
(function (CirclePayoutStatus) {
    CirclePayoutStatus["Pending"] = "pending";
    CirclePayoutStatus["Complete"] = "complete";
    CirclePayoutStatus["Failed"] = "failed";
})(CirclePayoutStatus = exports.CirclePayoutStatus || (exports.CirclePayoutStatus = {}));
exports.CirclePayoutStatusToTransactionStatus = (_a = {},
    _a[CirclePayoutStatus.Pending] = transactions_1.TransactionStatus.PENDING,
    _a[CirclePayoutStatus.Failed] = transactions_1.TransactionStatus.FAILED,
    _a[CirclePayoutStatus.Complete] = transactions_1.TransactionStatus.COMPLETED,
    _a);
