import React, { FC, PropsWithChildren } from 'react'
import { Grid, styled } from '@mui/material'

const StyledGrid = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2)
}))

const Section: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledGrid>
      {children}
    </StyledGrid>
  )
}

export default Section
