import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { AdminRoles } from '@keo/shared-types'
import { useRoleProtection } from '@/utils/routes'
import SettingsHeader from '../SettingsHeader'
import LendingPoolDetails from './LendingPoolDetails'

const ContractsManagement: FC = () => {
  useRoleProtection([ AdminRoles.PROCESSOR_ADMIN ])
  const { t } = useTranslation('settings', { keyPrefix: 'system.contractsManagement' })
  return (
    <Box>
      <SettingsHeader
        description={{
          details: t('description.details'),
          impact: t('description.impact'),
          reason: t('description.reason'),
        }}
        title={t('sectionTitle')}
      />
      <LendingPoolDetails/>
    </Box>
  )
}

export default ContractsManagement
