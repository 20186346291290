import { FieldError } from 'react-hook-form'
import { GridSortItem } from '@mui/x-data-grid'
import { parseExpression } from 'cron-parser'
import { ValidationError } from '@/types'

export function parseFormFieldError(fieldError: FieldError): ValidationError {
  let errorMessage: ValidationError = { key: '' }

  if (fieldError.message) {
    if (typeof fieldError.message === 'string') {
      errorMessage.key = fieldError.message
    } else {
      const errorObj: ValidationError = fieldError.message
      errorMessage.key = errorObj.key
      errorMessage.values = errorObj.values
    }
  }

  return errorMessage
}

export function stringifySortItem(sorting: GridSortItem | undefined): string | undefined {
  if (!sorting) {
    return undefined
  }
  const direction = sorting.sort === 'desc' ? '-' : ''
  return `${direction}${sorting.field}`
}

export function parseSortItem(sortingString: string | undefined): GridSortItem | undefined {
  if (!sortingString) {
    return undefined
  }
  const sort = sortingString.startsWith('-') ? 'desc' : 'asc'
  return {
    field: sort === 'asc' ? sortingString : sortingString.substring(1),
    sort
  }
}

export function removeUndefinedAndEmptyValues<T extends Record<string, unknown>>(obj: T, removeFalsy?: (keyof T)[] | boolean) {
  return Object.keys(obj).reduce((acc, key: keyof T) => {
    if(typeof removeFalsy === 'boolean' && removeFalsy && !obj[ key ]) {
      return acc
    }

    if(Array.isArray(removeFalsy) && removeFalsy.includes(key) && !obj[ key ]) {
      return acc
    }

    if (isUndefinedOrEmpty(obj[ key ])) {
      return acc
    }

    acc[ key ] = obj[ key ]
    return acc
  }, {} as Partial<T>)
}


export function isUndefinedOrEmpty(value: unknown) {
  return value === undefined || value === '' || value === null
}

/**
 * Finds intersection between two arrays
 * @param array1
 * @param array2
 * @returns array of common elements or undefined if there are no common elements
 */
export function getArrayIntersection<T>(array1: T[], array2: T[]): T[] | undefined {
  const intersection = array1.filter((value) => array2.includes(value))
  return intersection.length > 0 ? intersection : undefined
}

export function parseCronExpression(cronExpression: string) {
  return parseExpression(cronExpression).fields
}
