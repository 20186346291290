import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { RangeFilter } from '@keo/shared-types'

type DateRangeSelectorProps = {
  onChange: (range: RangeFilter) => void
  value: RangeFilter
}

const DateRangeSelector: FC<DateRangeSelectorProps> = ({ onChange, value }) => {
  const { t } = useTranslation('common')

  const handleChange = useCallback((evt: SelectChangeEvent) => {
    const newValue = evt.target.value as RangeFilter
    onChange(newValue)
  }, [])

  return (
    // Adding width to match Figma style
    <Select value={value} size="small" onChange={handleChange} sx={{ width: 130 }}>
      {
        Object.values(RangeFilter).map((range) => (
          <MenuItem key={range} value={range}>{t(range)}</MenuItem>
        ))
      }
    </Select>
  )
}

export default DateRangeSelector
