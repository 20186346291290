import ApiClient from '@/utils/api'
import { AxiosError } from 'axios'
import { HealthKeys, IHealthResponse } from '@keo/shared-types'
import { parseHealthCheckResponse } from '@/utils/health'

export type HealthCheckResult = {
  isAvailable: boolean
  failedServices: Array<HealthKeys>
  isOnlyDowntimeFlag: boolean
  services: IHealthResponse['details']
}

export async function getHealthCheck(): Promise<HealthCheckResult> {
  try {
    const res = await ApiClient.get<IHealthResponse>('/health')
    return parseHealthCheckResponse(res.data)
  } catch (error: unknown) {
    const axiosError = error as AxiosError<IHealthResponse>
    return parseHealthCheckResponse(axiosError.response!.data)
  }
}

export async function resetDowntimeFlag() {
  return ApiClient.post<void, void>('/monitoring/reset')
}
