import { FC, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import SelectFilter from '@/components/forms/SelectFilter'
import { getProcessingFeeStrategies } from '@/api/settings'
import { ProcessingFeeStrategySetting } from '@/types/settings'

type ProcessingFeeStrategyOption = {
  value: number
  label: string
}

type ProcessingFeeStrategySelectProps = {
  fieldName?: string
}

const ProcessingFeeStrategySelect: FC<ProcessingFeeStrategySelectProps> = ({ fieldName = 'processingFeeStrategy' }) => {
  const { setValue } = useFormContext()
  const { t } = useTranslation([ 'common', 'settings' ])
  const { data } = useQuery('processingFeeStrategies', () => getProcessingFeeStrategies({
    page: 1,
    perPage: 100,
  }))

  const mapProcessingFeeStrategiesToOptions = useCallback((processingFeeStrategies: ProcessingFeeStrategySetting[]): ProcessingFeeStrategyOption[] => {
    return processingFeeStrategies.map(processingFeeStrategy => ({
      value: processingFeeStrategy.id,
      label: processingFeeStrategy.value.name,
    }))
  }, [])

  const options = useMemo(() => mapProcessingFeeStrategiesToOptions(data?.results || []), [ data ])

  return (
    <SelectFilter
      onClear={() => setValue(fieldName, 0)}
      options={options}
      label={t('processingFeeStrategy')}
      fieldName={fieldName}
    />
  )
}

export default ProcessingFeeStrategySelect
