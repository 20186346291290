"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomHeaders = void 0;
var CustomHeaders;
(function (CustomHeaders) {
    CustomHeaders["ALGO_API_TOKEN"] = "x-algo-api-token";
    CustomHeaders["API_KEY"] = "x-api-key";
    CustomHeaders["API_ID"] = "x-api-id";
    CustomHeaders["APP_CONTEXT"] = "x-app-context-id";
    CustomHeaders["SNS_VALIDATION_KEY"] = "x-sns-validation-key";
    CustomHeaders["OTP_TOKEN"] = "x-otp-token";
    CustomHeaders["OTP_EMAIL"] = "x-otp-email";
})(CustomHeaders = exports.CustomHeaders || (exports.CustomHeaders = {}));
