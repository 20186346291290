import { FC } from 'react'
import { IAudit, LoansCheckFields, TargetAccountsSettings, TargetSettings } from '@keo/shared-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { ErrorBoundary } from '@sentry/react'
import { useLocale } from '@/utils/l10n'
import { parseCronExpression } from '@/utils/parsers'

type EventMessageProps = {
  event: IAudit
}

enum AuditEventMap {
  FEE_CHANGE = 'FEE_CHANGE',
  SETTINGS = 'SETTINGS',
  AUTOMATIONS = 'AUTOMATIONS',
}

const endpoints: Record<string, AuditEventMap> = {
  '/api/lending-pool/updateLendingPoolState': AuditEventMap.FEE_CHANGE,
  '/api/settings': AuditEventMap.SETTINGS,
  '/api/settings/automations': AuditEventMap.AUTOMATIONS,
}

function endpointToAuditEvent(url: string): AuditEventMap | undefined {
  return endpoints[ url ]
}

function getPaybackStrategyMessage({ body, dataReturned, method }: IAudit): string {
  const eventType = body?.type || dataReturned?.type
  if (eventType === TargetSettings.PAYBACK_STRATEGIES) {
    if (method === 'POST') {
      return 'createPaybackStrategy'
    }
    if (method === 'PUT') {
      // @ts-ignore
      if (body?.paybackStrategyStepsData?.isDefault) {
        return 'defaultPaybackStrategy'
      }
      return 'updatePaybackStrategy'
    }
  }

  if (eventType === TargetAccountsSettings.PAYBACK_STRATEGY_ID) {
    if (method === 'DELETE') {
      return 'deleteAccountPaybackStrategy'
    }

    return 'updateAccountPaybackStrategy'
  }

  return ''
}

function getProcessingFeeStrategyMessage({ body, dataReturned, method }: IAudit): string {
  const eventType = body?.type || dataReturned?.type
  if (eventType === TargetSettings.PROCESSING_FEE_STRATEGIES) {
    if (method === 'POST') {
      return 'createProcessingFeeStrategy'
    }
    if (method === 'PUT') {
      // @ts-ignore
      if (body?.processingFeeStrategyData?.isDefault) {
        return 'defaultProcessingFeeStrategy'
      }
      return 'updateProcessingFeeStrategy'
    }
  }

  if (eventType === TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID) {
    if (method === 'DELETE') {
      return 'deleteAccountProcessingFeeStrategy'
    }

    // @ts-ignore
    if (dataReturned?.isDefault) {
      return 'updateDefaultAccountProcessingFeeStrategy'
    }

    return 'updateAccountProcessingFeeStrategy'
  }

  return ''
}

const EventMessage: FC<EventMessageProps> = ({ event }) => {
  const { t } = useTranslation([ 'audit', 'settings', 'common' ])
  const { parseCurrencyAmount, parseTime } = useLocale()
  const eventType = endpointToAuditEvent(event.url)
  let message = ''

  if (eventType === AuditEventMap.FEE_CHANGE) {
    const fee = event.body?.payFee as number
    message = t('FEE_CHANGE', { fee })
  }

  if (event.url.startsWith('/api/settings')) {
    // we cannot distinguish between target settings since the endpoint is the same
    // and all are considered settings
    if (event.method === 'DELETE') {
      const settingId = event.params?.id
      message = t('deleteSetting', { settingId })
    }

    // payback strategy event
    if (event.body?.type === TargetSettings.PAYBACK_STRATEGIES) {
      // @ts-ignore
      const strategyName = event.body?.paybackStrategyStepsData.name
      const settingsEvent = getPaybackStrategyMessage(event)
      message = t(settingsEvent, { strategyName })
    }

    // processing fee strategy event
    if (event.body?.type === TargetSettings.PROCESSING_FEE_STRATEGIES) {
      // @ts-ignore
      const strategyName = event.body?.processingFeeStrategyData.name
      const settingsEvent = getProcessingFeeStrategyMessage(event)
      message = t(settingsEvent, { strategyName })
    }

    // callback url event
    if (event.body?.type === TargetSettings.CALLBACK_URL) {
      // @ts-ignore
      const callbackUrl = event.body.callbackUrlData.url
      // @ts-ignore
      const callbackJob = event.body.callbackUrlData.job
      message = t(TargetSettings.CALLBACK_URL, { job: callbackJob, url: callbackUrl })
    }

    // Loan event
    if (event.body?.type === TargetSettings.LOANS_CHECK) {
      const body = event.body.loansOOSData as LoansCheckFields
      const key = Object.keys(body)[ 0 ]
      message = t(`${TargetSettings.LOANS_CHECK}.${key}`, {
        interval: body.loanOOSIntervalInMinutes,
        crontimeMinutes: body.loanOOSMonitorCronTimeInMinutes,
        threshold : body.loanOOSTooLongThresholdInHours,
      })
    }

    // Supplier USDC warning amount
    if (event.body?.type === TargetSettings.SUPPLIER_USDC_WARNING_AMOUNT) {
      // @ts-ignore
      const amount = event.body.supplierUSDCWarningAmountData.amount
      message = t(TargetSettings.SUPPLIER_USDC_WARNING_AMOUNT, { amount: parseCurrencyAmount(amount) })
    }

    // Fraud check url
    if (event.body?.type === TargetSettings.FRAUD_CHECK_URL) {
      // @ts-ignore
      const url = event.body.fraudCheckUrlData.url
      message = t(TargetSettings.FRAUD_CHECK_URL, { url })
    }

    // Automate supplier invoice approval
    if(event.body?.type === TargetSettings.AUTOMATE_SUPPLIER_INVOICE_APPROVAL) {
      // @ts-ignore
      const delayInMinutes = event.body.automateSupplierInvoiceApprovalData.delayInMinutes
      message = t(TargetSettings.AUTOMATE_SUPPLIER_INVOICE_APPROVAL, { delayInMinutes })
    }

    // Automate withdrawal
    if(event.body?.type === TargetSettings.AUTOMATE_WITHDRAWAL) {
      // @ts-ignore
      const cronExpr = event.body.automateWithdrawalData.cronExpr
      const { hour: [ hour ], minute: [ minute ] } = parseCronExpression(cronExpr)
      const time = parseTime(new Date(0, 0, 0, hour, minute))
      // TODO: change this message to use the cron expression
      message = t(TargetSettings.AUTOMATE_WITHDRAWAL, { time })
    }

    // Deposit parameters
    if(event.body?.type === TargetSettings.DEPOSIT_PARAMETERS) {
      // @ts-ignore
      const depositLimit = event.body.depositParametersData.depositLimit
      message = t(TargetSettings.DEPOSIT_PARAMETERS, { depositLimit })
    }

    // Feature flags
    if(event.body?.type === TargetSettings.FEATURE_FLAGS) {
      // @ts-ignore
      const flag = Object.keys(event.body.featureFlagsData)[ 0 ]
      // @ts-ignore
      const state = event.body.featureFlagsData[ flag ]
      message = t(TargetSettings.FEATURE_FLAGS, {
        state: t(`common:${state ? 'enabled' : 'disabled'}`),
        feature: t(`settings:system.featureManagement.features.${flag}.label`),
      })
    }
  }

  if (event.url.startsWith('/api/account/settings')) {
    const auditEventType = event.body?.type || event.dataReturned?.type
    // account payback strategy
    if (auditEventType === TargetAccountsSettings.PAYBACK_STRATEGY_ID) {
      // @ts-ignore
      const strategyId = event.body?.paybackStrategyIdData.paybackStrategyId || event.dataReturned?.value.paybackStrategyId
      const buyerId = event.body?.accountId || event.dataReturned?.accountId
      const settingsEvent = getPaybackStrategyMessage(event)
      message = t(settingsEvent, { strategyId, buyer: buyerId })
    }

    // account processing fee strategy
    if (auditEventType === TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID) {
      // @ts-ignore
      const strategyId = event.body?.processingFeeStrategyIdData.processingFeeStrategyId || event.dataReturned?.value.processingFeeStrategyId
      const buyerId = event.body?.appliedToId || event.dataReturned?.appliedToId
      const supplierId = event.body?.accountId || event.dataReturned?.accountId

      const settingsEvent = getProcessingFeeStrategyMessage(event)
      message = t(settingsEvent, { strategyId, buyer: buyerId, supplier: supplierId })
    }
  }

  if (event.url === '/api/monitoring/reset') {
    message = t('monitoringReset')
  }

  if (event.url === '/api/settings/automations') {
    message = t(`automations${event.body?.enabled ? 'Enabled' : 'Disabled'}`)
  }

  if (event.url.startsWith('/api/auth/apiKey')) {
    if (event.method === 'DELETE' && event.params?.id) {
      message = t('apiKeyDeleted', { apiKeyId: event.params.id })
    }

    if (event.method === 'DELETE' && !event.params?.id) {
      message = t('apiKeyDeletedAll')
    }

    if (event.method === 'POST') {
      message = t('apiKeyCreated', { apiKeyId: event.dataReturned?.id })
    }

    if (event.method === 'PATCH') {
      message = t('apiKeyUpdated', { apiKeyId: event.params?.id, name: event.dataReturned?.label })
    }
  }

  return (
    <Typography variant="body3">{message}</Typography>
  )
}

const wrappedEventMessage: FC<EventMessageProps> = ({ event }) => {
  const { t } = useTranslation('audit')
  return (
    <ErrorBoundary fallback={<>{t('fallback')}</>}>
      <EventMessage event={event} />
    </ErrorBoundary>
  )
}

export default wrappedEventMessage
