import { parseDateExcludingTime } from '@/utils/l10n'
import { DATE_FORMAT_DISPLAY } from '@/utils/constants'

export const yupLocale = {
  number: {
    min: (params: any) => ({ key: 'numberMin', values: { min: Math.max(params.min, 0) } }),
    max: (params: any) => {
      // returned empty object to hide the error if the max value is invalid (negative)
      if (params.value >= 0 && params.max < 0) return {}
      return ({ key: 'numberMax', values: { max: Math.max(params.max, 0) } })
    },
    positive: () => ({ key: 'numberPositive' })
  },
  date: {
    min: (params: any) => ({
      key: 'dateMin',
      values: { min: parseDateExcludingTime(params.min, DATE_FORMAT_DISPLAY) }
    }),
    max: (params: any) => ({ key: 'dateMax', values: { max: parseDateExcludingTime(params.max, DATE_FORMAT_DISPLAY) } })
  },
  string: {
    email: () => ({ key: 'emailInvalid' }),
    matches: (params: any) => ({ key: params.message }),
    min: (params: any) => {
      return ({ key: 'stringMin', values: { min: params.min } })
    }
  },
  mixed: {
    oneOf: () => ({ key: 'oneOf' }),
    required: (params: any) => ({ key: params.message || 'required' })
  }
}
