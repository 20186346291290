import { FC, ReactNode } from 'react'
import { Grid, Typography } from '@mui/material'

type InvoiceDetailsItemProps = {
  children: ReactNode
  label?: string
}

const InvoiceDetailsItem: FC<InvoiceDetailsItemProps> = ({ children, label }) => {
  return (
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={4} xl={3}>
        <Typography color="text.secondary">{label}</Typography>
      </Grid>
      <Grid item xs={8} xl={9}>
        <Typography textAlign="right" sx={{ wordBreak: 'break-all' }}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default InvoiceDetailsItem