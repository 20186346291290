import { FC } from 'react'
import { Grid, GridProps, IconButton, Typography } from '@mui/material'
import DetailsItemValue, { DetailsItemValueProps } from './DetailsItemValue'
import { Edit } from '@mui/icons-material'
import { CopyButton } from '..'

type DetailsItemProps = DetailsItemValueProps & {
  canCopy?: boolean
  onEdit?: () => void
  label: string
  valueContainerProps?: GridProps
}

const DetailsItem: FC<DetailsItemProps> = ({
  canCopy,
  label,
  value,
  isLoading = false,
  onEdit,
  variant = 'default',
  href,
  opensInNewTab,
  valueContainerProps,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <Typography color="text.secondary">{label}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={9}
        spacing={2}
        flexWrap="nowrap"
        alignItems="center"
        sx={{ overflowWrap: 'anywhere' }}
        {...valueContainerProps}
      >
        <Grid item position="relative">
          <DetailsItemValue isLoading={isLoading} value={value} variant={variant} href={href} opensInNewTab={opensInNewTab}/>
        </Grid>
        {canCopy && variant !== 'custom' && (
          <Grid item>
            <CopyButton value={(value as string | number) || ''}/>
          </Grid>
        )}
        {onEdit &&
        <Grid item>
          <IconButton size="small" onClick={onEdit}>
            <Edit fontSize="small" />
          </IconButton>
        </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default DetailsItem
