import { FC, ReactNode } from 'react'
import { MaterialDesignContent, SnackbarProvider as Provider } from 'notistack'
import { styled } from '@mui/material'

type SnackbarProviderProps = {
  children: ReactNode
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error,
  },
}))

const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
  return (
    <Provider
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
    >
      {children}
    </Provider>
  )
}

export default SnackbarProvider
