import { FC, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import {
  Account,
  AccountInitialisedFilter,
  AccountType, KYCStatus, TargetAccountsSettings,
} from '@keo/shared-types'
import { OnFilterAccountsParams } from '@/types/forms'
import { CSVExporter, DataGrid, DateCell, Toolbar, UnderlinedText } from '@/components'
import { enumOrEmpty } from '@/utils/schemas'
import { getAccountUrl } from '@/utils/routes'
import { stringifySortItem } from '@/utils/parsers'
import { useDataGridOptions } from '@/components/tables/hooks/useDataGridOptions'
import { Entity } from '@/components/modals/CSVExporter'
import useExport from '@/components/hooks/useExport'
import { getStrategyName } from '@/utils/strategy'
import Filters from './components/Filters'
import AccountStatusChip from './components/AccountStatusChip'
import { useAccounts } from '@/api/hooks/accounts'
import { getDefaultCreatedAtSorting } from '@/utils/sort'

// Refine the schema once it's defined on the backend
const FilterFormSchema = yup.object({
  searchTerm: yup.string().trim().notRequired(),
  kycStatus: yup.array()
    .of(yup.string().oneOf(Object.values(KYCStatus), 'invalid'))
    .notRequired(),
  initialised: enumOrEmpty(AccountInitialisedFilter),
})

const FilterFormDefaultValues: OnFilterAccountsParams = {
  searchTerm: '',
  kycStatus: [],
  initialised: '',
  paybackStrategy: 0,
  processingFeeStrategy: 0,
  statuses: []
}

type AccountsTableProps = {
  accountType: AccountType
}

const AccountsTable: FC<AccountsTableProps> = ({ accountType }) => {
  const { t } = useTranslation([ 'common', 'accounts' ])
  const navigate = useNavigate()
  const {
    page,
    perPage,
    filters,
    setPage,
    setPerPage,
    setFilters,
    sorting,
    handleSort,
  } = useDataGridOptions<OnFilterAccountsParams>({
    initialFilterValues: {
      ...FilterFormDefaultValues,
      accountType
    },
    arrayFilters: [ 'kycStatus', 'statuses' ],
    removeFalsy: [ 'processingFeeStrategy', 'paybackStrategy' ],
    initialSort: getDefaultCreatedAtSorting('account'),
  })

  const {
    isExporting,
    handleExport,
    handleCancelExport
  } = useExport()

  const { data, isLoading } = useAccounts({
    filters,
    pagination: { page, perPage },
    sorting: stringifySortItem(sorting)
  })

  const handleRowClick = useCallback((row: Account) => {
    navigate(getAccountUrl(row.id))
  }, [ navigate ])

  const methods = useForm<OnFilterAccountsParams>({
    resolver: yupResolver(FilterFormSchema, {}, { rawValues: true }),
    defaultValues: FilterFormDefaultValues,
    mode: 'all'
  })

  const onReset = useCallback(() => {
    methods.reset(FilterFormDefaultValues)
    setFilters({ ...FilterFormDefaultValues, accountType })
  }, [])

  const columns: GridColDef<Account>[] = useMemo(() => [
    {
      // Need to specifically set the account.id field due to ambiguous column names in BE
      field: 'account.id',
      headerName: t('accountId'),
      flex: 0.5,
      minWidth: 120,
      renderCell: ({ row }) => <UnderlinedText>{row.id}</UnderlinedText>,
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'email',
      headerName: t('email'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'statuses',
      headerName: t('status'),
      flex: 1.5,
      renderCell: ({ row }) => row.statuses?.sort((a, b) => a.localeCompare(b)).map((status) => (
        <AccountStatusChip key={status} status={status} />
      )),
      sortable: false,
    },
    {
      field: 'account.createdAt',
      headerName: t('created'),
      flex: 0.5,
      minWidth: 120,
      renderCell: ({ row }) => <DateCell date={row.createdAt}/>,
    },
    {
      field: 'paybackStrategy',
      sortable: false,
      headerName: t('paybackStrategy'),
      minWidth: 150,
      flex: 0.7,
      renderCell: ({ row }) => getStrategyName(
        row,
        TargetAccountsSettings.PAYBACK_STRATEGY_ID,
        t
      )
    },
    {
      field: 'processingFeeStrategy',
      sortable: false,
      headerName: t('processingFeeStrategy'),
      minWidth: 150,
      flex: 0.7,
      renderCell: ({ row }) => getStrategyName(
        row,
        TargetAccountsSettings.PROCESSING_FEE_STRATEGY_ID,
        t
      )
    },
  ], [])

  const handleFilter = (params: OnFilterAccountsParams) => {
    setPage(1)
    /**
     * We need to send always a new object to setFilters
     * React hook form mutates internally its fields state object and sends that reference as a param instead of aa new cloned object
     * */
    setFilters({ ...params })
  }

  useEffect(() => {
    methods.reset(filters)
  }, [ filters, methods ])

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFilter)}>
              <Toolbar
                currentFilters={filters}
                filtersToWatch={[ 'initialised', 'kycStatus', 'paybackStrategy', 'statuses' ]}
                onReset={onReset}
                searchPlaceholder={t('table.toolbar.searchTermPlaceholder', { ns: 'accounts' })}
                searchField="searchTerm"
                onExport={handleExport}
              >
                <Filters accountType={accountType}/>
              </Toolbar>
            </form>
          </FormProvider>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            columns={columns}
            columnVisibilityModel={{
              paybackStrategy: accountType === AccountType.BUYER,
              processingFeeStrategy: accountType === AccountType.SUPPLIER
            }}
            rows={data?.results || []}
            rowCount={data?.total || 0}
            page={page - 1}
            pageSize={perPage}
            getRowId={(row: Account) => row.id}
            onRowClick={({ row }) => handleRowClick(row as Account)}
            onPageChange={(currentPage) => setPage(currentPage + 1)}
            onPageSizeChange={(newPerPage) => setPerPage(newPerPage)}
            sorting={sorting}
            handleSort={handleSort}
          />
        </Grid>
      </Grid>
      {isExporting && (
        <CSVExporter onClose={handleCancelExport} entity={Entity.ACCOUNTS} />
      )}
    </>
  )
}

export default AccountsTable
