"use strict";
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_INVOICE_AMOUNT = exports.MIN_INVOICE_AMOUNT = exports.InvoiceRejectionReason = exports.InvoiceCreator = exports.LoanPayBackStatus = exports.InvoicesFraudCheckStatus = exports.InvoicesValidationStatusToAPIErrorMap = exports.InvoicesValidationStatus = exports.InvoicesSupplierApprovalActionToApprovalStatusMap = exports.InvoicesBuyerApprovalActionToApprovalStatusMap = exports.InvoicesApprovalAction = exports.InvoicesApprovalStatus = void 0;
var api_errors_1 = require("../api-errors");
var InvoicesApprovalStatus;
(function (InvoicesApprovalStatus) {
    InvoicesApprovalStatus["CREATED"] = "CREATED";
    InvoicesApprovalStatus["REJECTED"] = "REJECTED";
    InvoicesApprovalStatus["LOCKED"] = "LOCKED";
    InvoicesApprovalStatus["PENDING"] = "PENDING";
    InvoicesApprovalStatus["DENIED"] = "DENIED";
    InvoicesApprovalStatus["APPROVED"] = "APPROVED";
})(InvoicesApprovalStatus = exports.InvoicesApprovalStatus || (exports.InvoicesApprovalStatus = {}));
var InvoicesApprovalAction;
(function (InvoicesApprovalAction) {
    InvoicesApprovalAction["DENY"] = "DENY";
    InvoicesApprovalAction["APPROVE"] = "APPROVE";
})(InvoicesApprovalAction = exports.InvoicesApprovalAction || (exports.InvoicesApprovalAction = {}));
exports.InvoicesBuyerApprovalActionToApprovalStatusMap = (_a = {},
    _a[InvoicesApprovalAction.DENY] = InvoicesApprovalStatus.DENIED,
    _a[InvoicesApprovalAction.APPROVE] = InvoicesApprovalStatus.APPROVED,
    _a);
exports.InvoicesSupplierApprovalActionToApprovalStatusMap = (_b = {},
    _b[InvoicesApprovalAction.DENY] = InvoicesApprovalStatus.REJECTED,
    _b[InvoicesApprovalAction.APPROVE] = InvoicesApprovalStatus.LOCKED,
    _b);
var InvoicesValidationStatus;
(function (InvoicesValidationStatus) {
    InvoicesValidationStatus["CREATED"] = "CREATED";
    InvoicesValidationStatus["BUYER_ELIGIBLE"] = "BUYER_ELIGIBLE";
    InvoicesValidationStatus["SUPPLIER_ELIGIBLE"] = "SUPPLIER_ELIGIBLE";
    InvoicesValidationStatus["SUPPLIER_WHITELIST"] = "SUPPLIER_WHITELIST";
    InvoicesValidationStatus["FRAUD_VALIDATION_FAILED_ON_CALL"] = "FRAUD_VALIDATION_FAILED_ON_CALL";
    InvoicesValidationStatus["FRAUD_VALIDATION_IN_PROGRESS"] = "FRAUD_VALIDATION_IN_PROGRESS";
    InvoicesValidationStatus["FRAUD_VALIDATION_DETECTED"] = "FRAUD_VALIDATION_DETECTED";
    InvoicesValidationStatus["VALID"] = "VALID";
})(InvoicesValidationStatus = exports.InvoicesValidationStatus || (exports.InvoicesValidationStatus = {}));
exports.InvoicesValidationStatusToAPIErrorMap = (_c = {},
    _c[InvoicesValidationStatus.BUYER_ELIGIBLE] = api_errors_1.ApiErrorCode.INVOICES_VALIDATION_BUYER_ACCOUNT_NOT_ELIGIBLE,
    _c[InvoicesValidationStatus.SUPPLIER_ELIGIBLE] = api_errors_1.ApiErrorCode.INVOICES_VALIDATION_SUPPLIER_ACCOUNT_NOT_ELIGIBLE,
    _c[InvoicesValidationStatus.SUPPLIER_WHITELIST] = api_errors_1.ApiErrorCode.INVOICES_VALIDATION_SUPPLIER_ACCOUNT_IS_NOT_WHITELISTED,
    _c[InvoicesValidationStatus.FRAUD_VALIDATION_FAILED_ON_CALL] = api_errors_1.ApiErrorCode.INVOICES_VALIDATION_FRAUD_CHECK_FAILED_ON_CALL,
    _c[InvoicesValidationStatus.FRAUD_VALIDATION_DETECTED] = api_errors_1.ApiErrorCode.INVOICES_VALIDATION_FRAUD_DETECTED,
    _c);
var InvoicesFraudCheckStatus;
(function (InvoicesFraudCheckStatus) {
    InvoicesFraudCheckStatus["DETECTED"] = "DETECTED";
    InvoicesFraudCheckStatus["PASSED"] = "PASSED";
})(InvoicesFraudCheckStatus = exports.InvoicesFraudCheckStatus || (exports.InvoicesFraudCheckStatus = {}));
var LoanPayBackStatus;
(function (LoanPayBackStatus) {
    LoanPayBackStatus["PENDING"] = "PENDING";
    LoanPayBackStatus["PAID"] = "PAID";
    LoanPayBackStatus["ACTIVE"] = "ACTIVE";
    LoanPayBackStatus["REJECTED"] = "REJECTED";
    LoanPayBackStatus["DIRECT_PAID"] = "DIRECT_PAID";
})(LoanPayBackStatus = exports.LoanPayBackStatus || (exports.LoanPayBackStatus = {}));
var InvoiceCreator;
(function (InvoiceCreator) {
    InvoiceCreator["SUPPLIER"] = "SUPPLIER";
    InvoiceCreator["OPERATOR"] = "OPERATOR";
})(InvoiceCreator = exports.InvoiceCreator || (exports.InvoiceCreator = {}));
var InvoiceRejectionReason;
(function (InvoiceRejectionReason) {
    InvoiceRejectionReason["INCORRECT_AMOUNT"] = "INCORRECT_AMOUNT";
    InvoiceRejectionReason["OTHER"] = "OTHER";
})(InvoiceRejectionReason = exports.InvoiceRejectionReason || (exports.InvoiceRejectionReason = {}));
exports.MIN_INVOICE_AMOUNT = 0.01;
exports.MAX_INVOICE_AMOUNT = 99999999.99;
