import { FC } from 'react'
import { Stack, Tooltip, Typography } from '@mui/material'
import { AccountWallet } from '@keo/shared-types'

type AccountCellProps = {
  account?: AccountWallet
}

const AccountCell: FC<AccountCellProps> = ({ account }) => {
  if (!account) return null
  
  return (
    <Tooltip title={account.address} enterTouchDelay={0}>
      <Stack spacing={0.5}>
        <Typography variant="body3">
          {account.account.email}
        </Typography>
      </Stack>
    </Tooltip>
  )
}

export default AccountCell