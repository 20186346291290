import { useQuery } from 'react-query'
import { ApiQueryKeys } from '@/api/hooks/queries'
import { getWallets } from '@/api/wallets'
import { GetWalletsParams } from '@/types'

export const useWallets = (params: GetWalletsParams) => {
  const { pagination, filters, sorting } = params
  const { page, perPage } = pagination

  return useQuery(
    [ ApiQueryKeys.WALLETS, filters, sorting, perPage, page ],
    () => getWallets({
      pagination: {
        page,
        perPage
      },
      filters,
      sorting,
    }),
    { keepPreviousData: true }
  )
}
