"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProviderErrorsList = exports.ApiErrorCode = void 0;
var ApiErrorCode;
(function (ApiErrorCode) {
    ApiErrorCode["TOO_MANY_REQUESTS"] = "TOO_MANY_REQUESTS";
    ApiErrorCode["NOT_IMPLEMENTED"] = "NOT_IMPLEMENTED";
    ApiErrorCode["UNKNOWN"] = "UNKNOWN";
    ApiErrorCode["BAD_REQUEST"] = "BAD_REQUEST";
    ApiErrorCode["UPSTREAM_ERROR"] = "UPSTREAM_ERROR";
    // Monitoring
    ApiErrorCode["MONITORING_FORCED_DOWN"] = "MONITORING_FORCED_DOWN";
    // Accounts
    ApiErrorCode["ACCOUNTS_UNKNOWN_ERROR"] = "ACCOUNTS_UNKNOWN_ERROR";
    ApiErrorCode["ACCOUNTS_NOT_FOUND"] = "ACCOUNTS_NOT_FOUND";
    ApiErrorCode["ACCOUNTS_ALREADY_EXISTS"] = "ACCOUNTS_ALREADY_EXISTS";
    ApiErrorCode["ACCOUNTS_ALREADY_VERIFIED"] = "ACCOUNTS_ALREADY_VERIFIED";
    ApiErrorCode["ACCOUNTS_VERIFY_TXNS_NOT_FOUND"] = "ACCOUNTS_VERIFY_TXNS_NOT_FOUND";
    ApiErrorCode["ACCOUNTS_VERIFY_TXNS_NOT_SENT"] = "ACCOUNTS_VERIFY_TXNS_NOT_SENT";
    ApiErrorCode["ACCOUNTS_EXPORT_FILE_NOT_FOUND"] = "ACCOUNTS_EXPORT_FILE_NOT_FOUND";
    ApiErrorCode["ACCOUNTS_FORBIDDEN"] = "ACCOUNTS_FORBIDDEN";
    ApiErrorCode["ACCOUNTS_OTP_TOKEN_INVALID"] = "ACCOUNTS_OTP_TOKEN_INVALID";
    ApiErrorCode["ACCOUNTS_OTP_NOT_CONFIGURED"] = "ACCOUNTS_OTP_NOT_CONFIGURED";
    ApiErrorCode["ACCOUNTS_OPERATION_NOT_ALLOWED"] = "ACCOUNTS_OPERATION_NOT_ALLOWED";
    ApiErrorCode["ACCOUNTS_NOT_READY"] = "ACCOUNTS_NOT_READY";
    ApiErrorCode["ACCOUNTS_BANK_ACCOUNT_UNKNOWN_ERROR"] = "ACCOUNTS_BANK_ACCOUNT_UNKNOWN_ERROR";
    ApiErrorCode["ACCOUNTS_BANK_ACCOUNT_NOT_FOUND"] = "ACCOUNTS_BANK_ACCOUNT_NOT_FOUND";
    ApiErrorCode["ACCOUNTS_BANK_ACCOUNT_ALREADY_EXISTS"] = "ACCOUNTS_BANK_ACCOUNT_ALREADY_EXISTS";
    // Accounts Settings
    ApiErrorCode["ACCOUNTS_SETTINGS_UNKNOWN_ERROR"] = "ACCOUNTS_SETTINGS_UNKNOWN_ERROR";
    ApiErrorCode["ACCOUNTS_SETTINGS_FORBIDDEN"] = "ACCOUNTS_SETTINGS_FORBIDDEN";
    ApiErrorCode["ACCOUNTS_SETTINGS_NOT_FOUND"] = "ACCOUNTS_SETTINGS_NOT_FOUND";
    ApiErrorCode["ACCOUNTS_SETTINGS_OPERATION_NOT_ALLOWED"] = "ACCOUNTS_SETTINGS_OPERATION_NOT_ALLOWED";
    ApiErrorCode["ACCOUNTS_SETTINGS_ALREADY_EXISTS"] = "ACCOUNTS_SETTINGS_ALREADY_EXISTS";
    ApiErrorCode["ACCOUNTS_SETTINGS_NOT_ALLOWED_REFERENCE"] = "ACCOUNTS_SETTINGS_NOT_ALLOWED_REFERENCE";
    ApiErrorCode["ACCOUNTS_SETTINGS_EXPORT_FILE_NOT_FOUND"] = "ACCOUNTS_SETTINGS_EXPORT_FILE_NOT_FOUND";
    // Auth
    ApiErrorCode["AUTH_UNKNOWN_ERROR"] = "AUTH_UNKNOWN_ERROR";
    ApiErrorCode["AUTH_ACCESS_TOKEN_INVALID"] = "AUTH_ACCESS_TOKEN_INVALID";
    ApiErrorCode["AUTH_OPERATION_NOT_FOUND"] = "AUTH_OPERATION_NOT_FOUND";
    ApiErrorCode["AUTH_REFRESH_TOKEN_INVALID"] = "AUTH_REFRESH_TOKEN_INVALID";
    ApiErrorCode["AUTH_INVALID_CODE"] = "AUTH_INVALID_CODE";
    ApiErrorCode["AUTH_CHALLENGE_NOT_FOUND"] = "AUTH_CHALLENGE_NOT_FOUND";
    ApiErrorCode["AUTH_INVALID_CHALLENGE"] = "AUTH_INVALID_CHALLENGE";
    ApiErrorCode["AUTH_API_KEY_NOT_FOUND"] = "AUTH_API_KEY_NOT_FOUND";
    ApiErrorCode["AUTH_IS_BANNED"] = "AUTH_IS_BANNED";
    ApiErrorCode["AUTH_IS_SUSPENDED"] = "AUTH_IS_SUSPENDED";
    ApiErrorCode["AUTH_CONTEXT_NOT_ALLOWED"] = "AUTH_CONTEXT_NOT_ALLOWED";
    // Wallets
    ApiErrorCode["WALLETS_UNKNOWN_ERROR"] = "WALLETS_UNKNOWN_ERROR";
    ApiErrorCode["WALLETS_NOT_INITIALIZED"] = "WALLETS_NOT_INITIALIZED";
    ApiErrorCode["WALLETS_NOT_FOUND"] = "WALLETS_NOT_FOUND";
    ApiErrorCode["WALLETS_ADDRESS_IS_NOT_VALID"] = "WALLETS_ADDRESS_IS_NOT_VALID";
    ApiErrorCode["WALLETS_ALREADY_EXISTS"] = "WALLETS_ALREADY_EXISTS";
    ApiErrorCode["WALLETS_TXNS_NOT_FOUND"] = "WALLETS_TXNS_NOT_FOUND";
    ApiErrorCode["WALLETS_TXNS_NOT_SENT"] = "WALLETS_TXNS_NOT_SENT";
    ApiErrorCode["WALLETS_ALREADY_SUSPENDED"] = "WALLETS_ALREADY_SUSPENDED";
    ApiErrorCode["WALLETS_NOT_SUSPENDED"] = "WALLETS_NOT_SUSPENDED";
    ApiErrorCode["WALLETS_ALREADY_BANNED"] = "WALLETS_ALREADY_BANNED";
    ApiErrorCode["WALLETS_CIRCLE_ADDRESS_NOT_SET"] = "WALLETS_CIRCLE_ADDRESS_NOT_SET";
    ApiErrorCode["WALLETS_EXPORT_FILE_NOT_FOUND"] = "WALLETS_EXPORT_FILE_NOT_FOUND";
    ApiErrorCode["WALLETS_BACKUP_WALLET_NOT_FOUND"] = "WALLETS_BACKUP_WALLET_NOT_FOUND";
    ApiErrorCode["WALLETS_FORBIDDEN"] = "WALLETS_FORBIDDEN";
    // Invoices
    ApiErrorCode["INVOICES_UNKNOWN_ERROR"] = "INVOICES_UNKNOWN_ERROR";
    ApiErrorCode["INVOICES_NOT_FOUND"] = "INVOICES_NOT_FOUND";
    ApiErrorCode["INVOICES_INVALID"] = "INVOICES_INVALID";
    ApiErrorCode["INVOICES_ALREADY_APPROVED"] = "INVOICES_ALREADY_APPROVED";
    ApiErrorCode["INVOICES_ALREADY_DENIED"] = "INVOICES_ALREADY_DENIED";
    ApiErrorCode["INVOICES_LOCKED"] = "INVOICES_LOCKED";
    ApiErrorCode["INVOICES_ALREADY_EXISTS"] = "INVOICES_ALREADY_EXISTS";
    ApiErrorCode["INVOICES_INVALID_STATUS"] = "INVOICES_INVALID_STATUS";
    ApiErrorCode["INVOICES_VALIDATION_BUYER_ACCOUNT_NOT_ELIGIBLE"] = "INVOICES_VALIDATION_BUYER_ACCOUNT_NOT_ELIGIBLE";
    ApiErrorCode["INVOICES_VALIDATION_BUYER_ACCOUNT_HAS_NO_CREDIT"] = "INVOICES_VALIDATION_BUYER_ACCOUNT_HAS_NO_CREDIT";
    ApiErrorCode["INVOICES_VALIDATION_SUPPLIER_ACCOUNT_NOT_ELIGIBLE"] = "INVOICES_VALIDATION_SUPPLIER_ACCOUNT_NOT_ELIGIBLE";
    ApiErrorCode["INVOICES_VALIDATION_SUPPLIER_ACCOUNT_IS_NOT_WHITELISTED"] = "INVOICES_VALIDATION_SUPPLIER_ACCOUNT_IS_NOT_WHITELISTED";
    ApiErrorCode["INVOICES_VALIDATION_FRAUD_CHECK_FAILED_ON_CALL"] = "INVOICES_VALIDATION_FRAUD_CHECK_FAILED_ON_CALL";
    ApiErrorCode["INVOICES_VALIDATION_FRAUD_CHECK_INVALID"] = "INVOICES_VALIDATION_FRAUD_CHECK_INVALID";
    ApiErrorCode["INVOICES_VALIDATION_FRAUD_DETECTED"] = "INVOICES_VALIDATION_FRAUD_DETECTED";
    ApiErrorCode["INVOICES_TXNS_NOT_FOUND"] = "INVOICES_TXNS_NOT_FOUND";
    ApiErrorCode["INVOICES_TXNS_NOT_SENT"] = "INVOICES_TXNS_NOT_SENT";
    ApiErrorCode["INVOICES_CHAIN_ERROR"] = "INVOICES_CHAIN_ERROR";
    ApiErrorCode["INVOICES_FORBIDDEN"] = "INVOICES_FORBIDDEN";
    ApiErrorCode["INVOICES_INVALID_PAY_AMOUNT"] = "INVOICES_INVALID_PAY_AMOUNT";
    // Loans
    ApiErrorCode["LOANS_UNKNOWN_ERROR"] = "LOANS_UNKNOWN_ERROR";
    ApiErrorCode["LOANS_NOT_FOUND"] = "LOANS_NOT_FOUND";
    ApiErrorCode["LOANS_CHAIN_ERROR"] = "LOANS_CHAIN_ERROR";
    ApiErrorCode["LOANS_TXNS_NOT_FOUND"] = "LOANS_TXNS_NOT_FOUND";
    ApiErrorCode["LOANS_TXNS_NOT_SENT"] = "LOANS_TXNS_NOT_SENT";
    ApiErrorCode["LOANS_BULK_UPDATE_ERROR"] = "LOANS_BULK_UPDATE_ERROR";
    ApiErrorCode["LOANS_EXPORT_FILE_NOT_FOUND"] = "LOANS_EXPORT_FILE_NOT_FOUND";
    ApiErrorCode["LOANS_INVALID_STATUS"] = "LOANS_INVALID_STATUS";
    ApiErrorCode["LOANS_INVALID_PRINCIPAL"] = "LOANS_INVALID_PRINCIPAL";
    ApiErrorCode["LOANS_LOCKED"] = "LOANS_LOCKED";
    ApiErrorCode["LOANS_CLOSED"] = "LOANS_CLOSED";
    ApiErrorCode["LOANS_NOT_PAID"] = "LOANS_NOT_PAID";
    ApiErrorCode["LOANS_INVALID"] = "LOANS_INVALID";
    ApiErrorCode["LOANS_FORBIDDEN"] = "LOANS_FORBIDDEN";
    // Signer
    ApiErrorCode["SIGNER_UNKNOWN_ERROR"] = "SIGNER_UNKNOWN_ERROR";
    ApiErrorCode["SIGNER_INVALID_ACTION"] = "SIGNER_INVALID_ACTION";
    ApiErrorCode["SIGNER_TXNS_NOT_SIGNED"] = "SIGNER_TXNS_NOT_SIGNED";
    // Admin Auth
    ApiErrorCode["ADMIN_AUTH_UNKNOWN_ERROR"] = "ADMIN_AUTH_UNKNOWN_ERROR";
    ApiErrorCode["ADMIN_AUTH_ACCESS_TOKEN_INVALID"] = "ADMIN_AUTH_ACCESS_TOKEN_INVALID";
    ApiErrorCode["ADMIN_AUTH_REFRESH_TOKEN_INVALID"] = "ADMIN_AUTH_REFRESH_TOKEN_INVALID";
    ApiErrorCode["ADMIN_AUTH_WRONG_CREDENTIALS"] = "ADMIN_AUTH_WRONG_CREDENTIALS";
    ApiErrorCode["ADMIN_AUTH_RESET_PASSWORD_TOKEN_INVALID"] = "ADMIN_AUTH_RESET_PASSWORD_TOKEN_INVALID";
    // Admin Users
    ApiErrorCode["ADMIN_USERS_UNKNOWN_ERROR"] = "ADMIN_USERS_UNKNOWN_ERROR";
    ApiErrorCode["ADMIN_USERS_NOT_FOUND"] = "ADMIN_USERS_NOT_FOUND";
    ApiErrorCode["ADMIN_USERS_ALREADY_EXISTS"] = "ADMIN_USERS_ALREADY_EXISTS";
    ApiErrorCode["ADMIN_USERS_FORBIDDEN"] = "ADMIN_USERS_FORBIDDEN";
    // Users Settings
    ApiErrorCode["USERS_SETTINGS_UNKNOWN_ERROR"] = "USERS_SETTINGS_UNKNOWN_ERROR";
    ApiErrorCode["USERS_SETTINGS_FORBIDDEN"] = "USERS_SETTINGS_FORBIDDEN";
    ApiErrorCode["USERS_SETTINGS_NOT_FOUND"] = "USERS_SETTINGS_NOT_FOUND";
    ApiErrorCode["USERS_SETTINGS_NOT_ALLOWED_TO_UPDATE"] = "USERS_SETTINGS_NOT_ALLOWED_TO_UPDATE";
    ApiErrorCode["USERS_SETTINGS_NOT_ALLOWED_TO_DELETE"] = "USERS_SETTINGS_NOT_ALLOWED_TO_DELETE";
    ApiErrorCode["USERS_SETTINGS_ALREADY_EXISTS"] = "USERS_SETTINGS_ALREADY_EXISTS";
    ApiErrorCode["USERS_SETTINGS_NOT_ALLOWED_TO_CREATE"] = "USERS_SETTINGS_NOT_ALLOWED_TO_CREATE";
    // Settings
    ApiErrorCode["SETTINGS_UNKNOWN_ERROR"] = "SETTINGS_UNKNOWN_ERROR";
    ApiErrorCode["SETTINGS_NOT_FOUND"] = "SETTINGS_NOT_FOUND";
    ApiErrorCode["SETTINGS_NOT_ALLOWED_TO_CREATE_OR_DELETE"] = "SETTINGS_NOT_ALLOWED_TO_CREATE_OR_DELETE";
    ApiErrorCode["SETTINGS_NOT_ALLOWED_TO_FILTER_DEFAULT"] = "SETTINGS_NOT_ALLOWED_TO_FILTER_DEFAULT";
    ApiErrorCode["SETTINGS_STRATEGY_ALREADY_EXISTS"] = "SETTINGS_STRATEGY_ALREADY_EXISTS";
    // Circle
    ApiErrorCode["CIRCLE_NOT_FOUND"] = "CIRCLE_NOT_FOUND";
    ApiErrorCode["CIRCLE_BAD_REQUEST"] = "CIRCLE_BAD_REQUEST";
    ApiErrorCode["CIRCLE_NOT_AUTHORIZED"] = "CIRCLE_NOT_AUTHORIZED";
    ApiErrorCode["CIRCLE_DUPLICATE_ENTITY"] = "CIRCLE_DUPLICATE_ENTITY";
    ApiErrorCode["CIRCLE_FORBIDDEN"] = "CIRCLE_FORBIDDEN";
    // Grapes
    ApiErrorCode["GRAPES_NOT_FOUND"] = "GRAPES_NOT_FOUND";
    ApiErrorCode["GRAPES_BAD_REQUEST"] = "GRAPES_BAD_REQUEST";
    ApiErrorCode["GRAPES_NOT_AUTHORIZED"] = "GRAPES_NOT_AUTHORIZED";
    ApiErrorCode["GRAPES_DUPLICATE_ENTITY"] = "GRAPES_DUPLICATE_ENTITY";
    ApiErrorCode["GRAPES_FORBIDDEN"] = "GRAPES_FORBIDDEN";
    ApiErrorCode["GRAPES_WALLET_CREATION_FAILED"] = "GRAPES_WALLET_CREATION_FAILED";
    // Transactions
    ApiErrorCode["TRANSACTIONS_NOT_FOUND"] = "TRANSACTIONS_NOT_FOUND";
    ApiErrorCode["TRANSACTIONS_FORBIDDEN"] = "TRANSACTIONS_FORBIDDEN";
    ApiErrorCode["TRANSACTIONS_EXPORT_FILE_NOT_FOUND"] = "TRANSACTIONS_EXPORT_FILE_NOT_FOUND";
    // Withdrawals
    ApiErrorCode["WITHDRAWALS_UNKNOWN_ERROR"] = "WITHDRAWALS_UNKNOWN_ERROR";
    ApiErrorCode["WITHDRAWALS_NOT_FOUND"] = "WITHDRAWALS_NOT_FOUND";
    ApiErrorCode["WITHDRAWALS_BANK_ACCOUNT_NOT_FOUND"] = "WITHDRAWALS_BANK_ACCOUNT_NOT_FOUND";
    ApiErrorCode["WITHDRAWALS_WALLET_NOT_FOUND"] = "WITHDRAWALS_WALLET_NOT_FOUND";
    ApiErrorCode["WITHDRAWALS_WITHDRAWAL_ACCOUNT_NOT_CONFIGURED"] = "WITHDRAWALS_WITHDRAWAL_ACCOUNT_NOT_CONFIGURED";
    ApiErrorCode["WITHDRAWALS_TXNS_NOT_FOUND"] = "WITHDRAWALS_TXNS_NOT_FOUND";
    ApiErrorCode["WITHDRAWALS_TXNS_NOT_SENT"] = "WITHDRAWALS_TXNS_NOT_SENT";
    ApiErrorCode["WITHDRAWALS_BLOCKCHAIN_ERROR"] = "WITHDRAWALS_BLOCKCHAIN_ERROR";
    ApiErrorCode["WITHDRAWALS_INVALID_AMOUNT"] = "WITHDRAWALS_INVALID_AMOUNT";
    ApiErrorCode["WITHDRAWALS_INSUFFICIENT_BALANCE"] = "WITHDRAWALS_INSUFFICIENT_BALANCE";
    ApiErrorCode["WITHDRAWALS_DISABLED"] = "WITHDRAWALS_DISABLED";
    // Lending Pool
    ApiErrorCode["LENDING_POOL_UPDATE_ERROR"] = "LENDING_POOL_UPDATE_ERROR";
    ApiErrorCode["LENDING_POOL_CASH_OUT_ERROR"] = "LENDING_POOL_CASH_OUT_ERROR";
    ApiErrorCode["LENDING_POOL_GET_BALANCE_ERROR"] = "LENDING_POOL_GET_BALANCE_ERROR";
    // Financial
    ApiErrorCode["FINANCIAL_PROVIDER_NOT_SUPPORTED"] = "FINANCIAL_PROVIDER_NOT_SUPPORTED";
    // Deposits
    ApiErrorCode["DEPOSITS_NOT_FOUND"] = "DEPOSITS_NOT_FOUND";
    ApiErrorCode["DEPOSITS_AMOUNT_EXCEEDS_LIMIT"] = "DEPOSITS_AMOUNT_EXCEEDS_LIMIT";
    ApiErrorCode["DEPOSITS_DISABLED"] = "DEPOSITS_DISABLED";
    // Context
    ApiErrorCode["CONTEXT_BAD_IMPLEMENTATION"] = "CONTEXT_BAD_IMPLEMENTATION";
    ApiErrorCode["CONTEXT_HEADER_NOT_FOUND"] = "CONTEXT_HEADER_NOT_FOUND";
    ApiErrorCode["CONTEXT_NOT_FOUND"] = "CONTEXT_NOT_FOUND";
    // Delegation
    ApiErrorCode["DELEGATIONS_FORBIDDEN"] = "DELEGATIONS_FORBIDDEN";
    ApiErrorCode["DELEGATIONS_ALREADY_EXISTS"] = "DELEGATIONS_ALREADY_EXISTS";
    ApiErrorCode["DELEGATIONS_INVALID_WALLET_ADDRESS"] = "DELEGATIONS_INVALID_WALLET_ADDRESS";
    ApiErrorCode["DELEGATIONS_NOT_FOUND"] = "DELEGATIONS_NOT_FOUND";
    ApiErrorCode["DELEGATIONS_INVALID_SIGNATURE"] = "DELEGATIONS_INVALID_SIGNATURE";
    ApiErrorCode["DELEGATIONS_ON_CHAIN_ERROR"] = "DELEGATIONS_ON_CHAIN_ERROR";
    ApiErrorCode["DELEGATIONS_DB_ERROR"] = "DELEGATIONS_DB_ERROR";
    ApiErrorCode["DELEGATIONS_ALREADY_REVOKED"] = "DELEGATIONS_ALREADY_REVOKED";
})(ApiErrorCode = exports.ApiErrorCode || (exports.ApiErrorCode = {}));
exports.ProviderErrorsList = [
    ApiErrorCode.GRAPES_NOT_FOUND,
    ApiErrorCode.GRAPES_BAD_REQUEST,
    ApiErrorCode.GRAPES_NOT_AUTHORIZED,
    ApiErrorCode.GRAPES_DUPLICATE_ENTITY,
    ApiErrorCode.CIRCLE_NOT_FOUND,
    ApiErrorCode.CIRCLE_BAD_REQUEST,
    ApiErrorCode.CIRCLE_NOT_AUTHORIZED,
    ApiErrorCode.CIRCLE_DUPLICATE_ENTITY,
    ApiErrorCode.UPSTREAM_ERROR,
];
