import React, { FC } from 'react'
import { Typography } from '@mui/material'

type AuthPageTitleProps = {
  title: string
}

const AuthPageTitle: FC<AuthPageTitleProps> = ({ title }) => {
  return (
    <Typography variant="h1" textAlign="center" color="primary" fontSize={40}>
      {title}
    </Typography>
  )
}

export default AuthPageTitle
