import React, { FC } from 'react'
import { Chip, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoanPayBackStatus } from '@keo/shared-types'
import LoanStatusIcon from '@/components/LoansTable/components/LoanStatusIcon'

function useStatusColor(status: LoanPayBackStatus): string {
  const theme = useTheme()
  switch (status) {
    case LoanPayBackStatus.PENDING:
    case LoanPayBackStatus.ACTIVE:
      return theme.palette.info.main
    case LoanPayBackStatus.REJECTED:
      return theme.palette.error.main
    case LoanPayBackStatus.DIRECT_PAID:
    case LoanPayBackStatus.PAID:
      return theme.palette.success.main
  }
}

type LoanStatusProps = {
  status: LoanPayBackStatus
}

const LoanStatus: FC<LoanStatusProps> = ({ status }) => {
  const { t } = useTranslation('loans')
  const color = useStatusColor(status)

  return (
    <Chip
      icon={<LoanStatusIcon status={status} />}
      label={t(`paybackStatus.${status}`)}
      size="small"
      sx={{ borderColor: color, color }}
      variant="outlined"
    />
  )
}

export default LoanStatus
