"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatusToWithdrawalStatus = exports.OrderStatusToTransactionStatus = exports.OrderType = exports.OrderPaymentMethodType = exports.OrderStatus = void 0;
var transactions_1 = require("../../transactions");
var payment_1 = require("../../payment");
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["NEW"] = "NEW";
    OrderStatus["INITIATED"] = "INITIATED";
    OrderStatus["PROCESSED"] = "PROCESSED";
    OrderStatus["CANCELLED"] = "CANCELLED";
    OrderStatus["QUEUED"] = "QUEUED";
    OrderStatus["EXPIRED"] = "EXPIRED";
    OrderStatus["DECLINED"] = "DECLINED";
    OrderStatus["FAILED"] = "FAILED";
    OrderStatus["INCORRECT_RECIPIENT"] = "INCORRECT_RECIPIENT";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
var OrderPaymentMethodType;
(function (OrderPaymentMethodType) {
    OrderPaymentMethodType["EFT"] = "EFT";
})(OrderPaymentMethodType = exports.OrderPaymentMethodType || (exports.OrderPaymentMethodType = {}));
var OrderType;
(function (OrderType) {
    OrderType["PURCHASE"] = "PURCHASE";
    OrderType["REDEEM"] = "REDEEM";
})(OrderType = exports.OrderType || (exports.OrderType = {}));
exports.OrderStatusToTransactionStatus = (_a = {},
    _a[OrderStatus.NEW] = transactions_1.TransactionStatus.PENDING,
    _a[OrderStatus.INITIATED] = transactions_1.TransactionStatus.PENDING,
    _a[OrderStatus.PROCESSED] = transactions_1.TransactionStatus.COMPLETED,
    _a[OrderStatus.CANCELLED] = transactions_1.TransactionStatus.FAILED,
    _a[OrderStatus.QUEUED] = transactions_1.TransactionStatus.PENDING,
    _a[OrderStatus.EXPIRED] = transactions_1.TransactionStatus.FAILED,
    _a[OrderStatus.DECLINED] = transactions_1.TransactionStatus.FAILED,
    _a[OrderStatus.FAILED] = transactions_1.TransactionStatus.FAILED,
    _a[OrderStatus.INCORRECT_RECIPIENT] = transactions_1.TransactionStatus.FAILED,
    _a);
exports.OrderStatusToWithdrawalStatus = (_b = {},
    _b[OrderStatus.NEW] = payment_1.WithdrawalStatus.PROCESSING,
    _b[OrderStatus.INITIATED] = payment_1.WithdrawalStatus.PROCESSING,
    _b[OrderStatus.PROCESSED] = payment_1.WithdrawalStatus.COMPLETED,
    _b[OrderStatus.CANCELLED] = payment_1.WithdrawalStatus.FAILED,
    _b[OrderStatus.QUEUED] = payment_1.WithdrawalStatus.PROCESSING,
    _b[OrderStatus.EXPIRED] = payment_1.WithdrawalStatus.FAILED,
    _b[OrderStatus.DECLINED] = payment_1.WithdrawalStatus.FAILED,
    _b[OrderStatus.FAILED] = payment_1.WithdrawalStatus.FAILED,
    _b[OrderStatus.INCORRECT_RECIPIENT] = payment_1.WithdrawalStatus.FAILED,
    _b);
