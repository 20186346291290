import { FC, useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import SelectFilter from '@/components/forms/SelectFilter'
import { useTranslation } from 'react-i18next'
import { AccountStatus, AccountType, KYCStatus, OptionalBooleanFilter } from '@keo/shared-types'
import PaybackStrategySelect from '@/components/PaybackStrategySelect'
import ProcessingFeeStrategySelect from '@/components/ProcessingFeeStrategySelect'

type FiltersProps = {
  accountType: AccountType
}

const Filters: FC<FiltersProps> = ({ accountType }) => {
  const { t } = useTranslation([ 'common', 'accounts' ])
  const [ isMounted, setIsMounted ] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const booleanOptions = useMemo(() => [
    { value: OptionalBooleanFilter.YES, label: t('yes') },
    { value: OptionalBooleanFilter.NO, label: t('no') }
  ], [ t ])

  const accountStatuses = useMemo(() => Object.values(AccountStatus).map(status => ({
    value: status,
    label: t(`accounts:status.${status}`)
  })).sort((a, b) => a.value > b.value ? 1 : -1), [ t ])

  const kycStatuses = useMemo(() => Object.values(KYCStatus).map(status => ({
    value: status,
    label: t(`accounts:kycStatus.${status}`)
  })).sort((a, b) => a.value > b.value ? 1 : -1), [ t ])

  // Temporary fix for animating issue
  if (!isMounted) {
    return null
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <SelectFilter
          label={t('table.toolbar.initialisedLabel', { ns: 'accounts' })}
          fieldName="initialised"
          options={booleanOptions}
          allOptionText={t('all')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <SelectFilter
          label={t('KYCstatus')}
          fieldName="kycStatus"
          multiple
          options={kycStatuses}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <SelectFilter
          label={t('status')}
          fieldName="statuses"
          options={accountStatuses}
          multiple
        />
      </Grid>
      {
        accountType === AccountType.BUYER &&
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <PaybackStrategySelect />
        </Grid>
      }
      {
        accountType === AccountType.SUPPLIER &&
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ProcessingFeeStrategySelect />
        </Grid>
      }
    </Grid>
  )
}

export default Filters
